// VideoCallContext.js

/**
 * VideoCallContext.js:
 * 
 * Purpose:
 * - This file manages the context for the video call functionality, enabling state management for video 
 *   call room information across the application. It provides an easy way for components to access and update 
 *   the current video call state (roomID).
 * 
 * Key Features:
 * - **Context Provider**: The `VideoCallProvider` component encapsulates the context and provides the `roomID` 
 *   and `setRoomID` functions to any child components that require access to or modification of the room ID.
 * - **Persistent Video Call**: When a `roomID` is set, the `PersistentVideoCall` component is rendered, 
 *   managing the actual video call experience by using the `roomID` to join the video call room.
 * - **State Management**: The `useState` hook is used to manage the state of the `roomID`, which is shared 
 *   globally across components via context. Any component that subscribes to this context can access and modify the room ID.
 * 
 * Additional Notes:
 * - **Reusability**: This context allows for easy reuse and management of video call states throughout the 
 *   application without needing to pass props manually between components.
 * - **Video Call Integration**: The `PersistentVideoCall` component integrates with the room ID to initiate and 
 *   manage the video call session based on the state provided by this context.
 */
import React, { createContext, useState, useContext } from 'react';
import PersistentVideoCall from './PersistentVideoCall';
const VideoCallContext = createContext();
export const VideoCallProvider = ({ children }) => {
  const [roomID, setRoomID] = useState(null);
  return (
    <VideoCallContext.Provider value={{ roomID, setRoomID }}>
      {children}
      {roomID && <PersistentVideoCall roomID={roomID} />}
    </VideoCallContext.Provider>
  );
};
export const useVideoCall = () => useContext(VideoCallContext);
