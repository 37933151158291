/**
 * Purpose:
 * This component is responsible for displaying the clinic history for the user. It allows the user to search for past appointments 
 * based on a date range and view relevant appointment details, including patient and doctor information. 
 * The component provides an option to update health records and displays the results in a table format with pagination.
 * 
 * Key Features:
 * - **Date Range Picker**: Allows the user to select a date range (From and To dates) to filter the clinic history.
 * - **Search Functionality**: Provides search functionality based on the selected date range, displaying matching results.
 * - **Appointment History Table**: Displays a list of past appointments, including case number, patient name, doctor name, and date/time of the appointment.
 * - **Action Buttons**: Includes buttons for editing the appointment and reviewing the consult.
 * - **Update Health Record**: A button to navigate to the health record update page.
 * - **Responsive Design**: The layout adapts to different screen sizes (using media queries) to ensure proper display on both small and large screens.
 * - **Styled Components**: Utilizes inline styles for custom design, including color schemes, padding, margins, and table styling.
 * 
 * Additional Notes:
 * - **Pagination**: The number of visible entries in the table can be adjusted via a dropdown selection (e.g., showing 1-2 or 3-4 entries).
 * - **Table Layout**: The table has alternating row colors to improve readability and features buttons for editing and reviewing appointments.
 * - **Date Picker**: Custom input component is used for the date picker to enhance UI/UX with a calendar icon.
 * - **Footer and Header**: The component includes a footer and a header to maintain consistent layout across pages.
 */

//======================================clinichistory=========================================================
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { FaCalendarAlt, FaEdit, FaRegEye } from 'react-icons/fa';
import Footer from '../../Footer';
import Header from "../../Hoheader";
const styles = {
  label: {
    marginRight: '20px',
  },
  appointmentHistoryStyle: {
    textAlign: 'center',
    color: '#324E6A',
    fontSize: '50px',
    textShadow: '0px 0px 32.1px #BB1111',
  },
  tableContainerStyle: {
    maxWidth: '1200px',
    margin: '0 auto',
    padding: '20px',
    border: '2px solid #104E8D',
    borderBottom: '2px solid #104E8D',
    overflowY: 'auto',
    maxHeight: '520px',
  },
  tableStyle: {
    width: '100%',
    borderCollapse: 'separate',
    border: '2px solid #D9D9D9',
  },
  thStyle: {
    backgroundColor: '#0F60B3',
    color: 'white',
    padding: '12px 8px',
    fontWeight: 'bold',
    textAlign: 'left',
    borderBottom: '1px solid #fff',
    borderRight: '2px solid #fff',
  },
  tdStyle: {
    padding: '8px',
    textAlign: 'left',
    borderRight: '2px solid #ddd',
  },
  lastColumnStyle: {
    borderRight: '2px solid #fff',
  },
  button: {
    padding: '8px 16px',
    cursor: 'pointer',
    borderRadius: '5px',
    marginRight: '40px',
    background: '#0B539C',
    color: 'white'
  },
  todayPatientListStyle: {
    textAlign: 'center',
    color: '#324E6A',
    fontSize: '50px',
    textShadow: '0px 0px 32.1px #BB1111',
  },
  '@media (max-width: 990px)': {
    button: {
      padding: '5px 10px',
      width: 'auto',
    },
  }
};
const SearchComponent = () => {
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [range, setRange] = useState('1-2');
  const [totalDataRows] = useState(5);
  const [totalBlankRows] = useState(5);
  const navigate = useNavigate();
  const isSmallScreen = window.matchMedia('(max-width: 990px)').matches;
  const rowHeight = 45;
  const headerHeight = 100;
  const containerHeight = headerHeight + rowHeight * (totalDataRows + totalBlankRows);
  const handleSearch = () => {
    console.log('Search from:', fromDate);
    console.log('Search to:', toDate);
  };
  const handleUpdateHealthRecord = () => {
    navigate('/updatehealth');
  };
  const handleRangeChange = (event) => {
    setRange(event.target.value);
  };
  return (
    <div style={{ background: 'linear-gradient(to bottom right, #7CB2D0, #D0E0E5)' }}>
      <Header />
      <div style={{ maxWidth: '1250px', margin: '0 auto', padding: '20px' }}>
        <div style={styles.todayPatientListStyle}>
          Clinic History
        </div>
        <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '20px', border: '2px solid rgba(204, 204, 204, 0.5)', borderRadius: '5px', overflow: 'visible', paddingBottom: '10px' }}>
  <div style={{ position: 'relative', flex: '1', marginRight: '10px', marginTop: '10px', marginLeft: '40px' }}>
    <label style={{ marginRight: '10px' }}>From:</label>
    <DatePicker
      selected={fromDate}
      onChange={(date) => setFromDate(date)}
      dateFormat="yyyy-MM-dd"
      className="date-picker"
      customInput={<CustomInput icon={<FaCalendarAlt />} />}
    />
  </div>
  <div style={{ position: 'relative', flex: '1', marginLeft: '10px', marginTop: '10px', marginRight: '10px' }}>
    <label style={{ marginRight: '10px' }}>To:</label>
    <DatePicker
      selected={toDate}
      onChange={(date) => setToDate(date)}
      dateFormat="yyyy-MM-dd"
      className="date-picker"
      customInput={<CustomInput icon={<FaCalendarAlt />} />}
    />
  </div>
  <button
    style={{
      ...styles.button,
      marginTop: '10px',
      width: isSmallScreen ? '100%' : 'auto',  // Adjust width for small screens
      marginRight: isSmallScreen ? '10px' : '0px', // Optional: Adjust margin for small screens
    }}
    onClick={handleSearch}
  >
    Search
  </button>
  <button
    style={{
      ...styles.button,
      marginTop: '10px',
      width: isSmallScreen ? '100%' : 'auto',  // Adjust width for small screens
    }}
    onClick={handleUpdateHealthRecord}
  >
    Update Health Record
  </button>
</div>
        <div style={{ ...styles.tableContainerStyle, height: containerHeight }}>
          <div style={{ display: 'flex', alignItems: 'center', marginBottom: '20px' }}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <span style={{ marginLeft: '23px', marginRight: '10px', color: '#324E6A' }}>Show </span>
              <select value={range} onChange={handleRangeChange} style={{ backgroundColor: '#D9D9D9', border: 'none', outline: 'none' }}>
                <option value="1-2">1-2</option>
                <option value="3-4">3-4</option>
              </select>
              <span style={{ marginLeft: '15px', marginRight: '15px', color: '#324E6A' }}>entries </span>
            </div>
            <span style={{ marginLeft: 'auto', marginRight: '23px', color: '#324E6A' }}>Search: </span>
            <input type="text" style={{ backgroundColor: '#D9D9D9', border: 'none', outline: 'none', borderRadius: '5px', padding: '5px' }} />
          </div>
          <table style={styles.tableStyle}>
            <thead>
              <tr>
                <th style={styles.thStyle}>Case No.</th>
                <th style={styles.thStyle}>Patient Name</th>
                <th style={styles.thStyle}>Doctor Name</th>
                <th style={styles.thStyle}>Date/Time</th>
                <th style={styles.thStyle}>Edit</th>
                <th style={styles.thStyle}>Review Consult</th>
              </tr>
            </thead>
            <tbody>
              {Array.from({ length: totalDataRows }, (_, index) => (
                <tr key={index} style={{ height: `${rowHeight}px`, backgroundColor: index % 2 === 0 ? '#D9D9D9' : '#E6E6E6' }}>
                  <td style={styles.tdStyle}>{index + 1}</td>
                  <td style={styles.tdStyle}>John Doe</td>
                  <td style={styles.tdStyle}>Dr. Smith</td>
                  <td style={styles.tdStyle}>2024-05-15 10:00 AM</td>
                  <td style={{ ...styles.tdStyle, cursor: 'pointer', textAlign: 'center' }} onClick={() => console.log(`Edit clicked for row ${index}`)}>
                    <div style={{ backgroundColor: '#0B539C', color: 'white', margin: '5px', borderRadius: '5px' }}>
                      <FaEdit size={20} style={{ color: 'white' }} />
                    </div>
                  </td>
                  <td style={styles.tdStyle}>Review</td>
                </tr>
              ))}
              {Array.from({ length: totalBlankRows }, (_, index) => (
                <tr key={`blank-${index}`} style={{ height: `${rowHeight}px`, backgroundColor: index % 2 === 0 ? '#D9D9D9' : '#E6E6E6' }}>
                  <td style={styles.tdStyle}></td>
                  <td style={styles.tdStyle}></td>
                  <td style={styles.tdStyle}></td>
                  <td style={styles.tdStyle}></td>
                  <td style={styles.tdStyle}></td>
                  <td style={styles.tdStyle}></td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      <Footer />
    </div>
  );
};
const CustomInput = ({ value, onClick, icon }) => (
  <div style={{ position: 'relative' }}>
    <input
      type="text"
      value={value}
      onClick={onClick}
      readOnly
      style={{ width: '100%', backgroundColor: '#fff', border: '1px solid #ccc', borderRadius: '5px', padding: '5px 30px 5px 10px' }}
    />
    {icon && <div style={{ position: 'absolute', right: '10px', top: '50%', transform: 'translateY(-50%)' }}>{icon}</div>}
  </div>
);
export default SearchComponent;
