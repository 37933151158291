
//==================================vitals===========================================
/**

 * - Displays patient vital signs such as blood group, pulse rate, respiration rate, and more.
 * - Fetches and displays the most recent vitals data from an API.
 * - Allows the user to select a date range using a date picker.
 * - Shows vital signs in a grid layout with corresponding icons and values.
 * - Contains a button for navigating to a graph page with more detailed data visualization.
 */

import React, { useState, useEffect, forwardRef } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom"; // Import useNavigate hook for navigation
import Patientheader from "../../Patientheader";
import Footer from "../../Footer";
import Header from "../Header/Header";
import HeightIcon from "../../../Assets/Height.png";
import PulseRateIcon from "../../../Assets/PulseRateIcon.png";
import RespirationIcon from "../../../Assets/Respiration.png";
import WeightIcon from "../../../Assets/WeightIcon.png";
import Spo2Icon from "../../../Assets/Spo2Icon.png";
import TempIcon from "../../../Assets/TempIcon.png";
import DiastolicBPIcon from "../../../Assets/DiastolicBPIcon.png";
import SystolicBPIcon from "../../../Assets/DiastolicBPIcon.png"; // Ensure this path is correct
import HeartRateIcon from "../../../Assets/HeartRateIcon.png";
import Graph from "../../../Assets/Graph.png";
import Calendar from "../../../Assets/Calendar.png";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import BASE_URL from '../../config/apiConfig';
import Redirect from "../../../Components/Redirect";
function AppointmentHistory() {
  const [startDate, setStartDate] = useState(null);
  const [vitalsData, setVitalsData] = useState([]); // State to hold fetched vitals data
  const navigate = useNavigate(); // Get navigate function from useNavigate hook

  useEffect(() => {
    // Function to fetch the latest patient vitals
    const fetchVitalsData = async () => {
      try {
        const jwtToken = localStorage.getItem('jwtToken');
        const response = await axios.get(`${BASE_URL}/api/patients/get-latest-patient-vitals`, {
          headers: {
            'Content-Type': 'application/json',
            'Auth': `Bearer ${jwtToken}`
            // 'Auth': 'Bearer eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiI4NjUwMTQ4MjMzIiwiaWF0IjoxNzIyNjYyMTE0LCJleHAiOjE3MjMyNjY5MTR9.GamlEEKGhzQEG3GkShNXETg1LzCXFcyzhtn0Mlqm43yKx8pUYh33MZxjsUYD2NpR3i2mdfKvR7qwgBdVdxlyeA'
          }
        });
        const data = response.data;
        const vitalsArray = [
          { name: "BloodGroup", value: data.bloodGroup, icon: HeightIcon },
          { name: "Pulse Rate(/min)", value: data.pulseRate, icon: PulseRateIcon },
          { name: "Respiration", value: data.respirationRate, icon: RespirationIcon },
          { name: "Hemoglobin", value: data.hemoglobin, icon: WeightIcon },
          { name: "Spo2(%)", value: data.spO2, icon: Spo2Icon },
          { name: "Temp(°F)", value: data.temperature, icon: TempIcon },
          { name: "Diastolic BP", value: data.diastolicBP, icon: DiastolicBPIcon },
          { name: "Systolic BP", value: data.systolicBP, icon: SystolicBPIcon },
          { name: "Heart Rate(bpm)", value: data.heartRate, icon: HeartRateIcon },
        ];
        setVitalsData(vitalsArray);
      } catch (error) {
        console.error('Error fetching vitals data:', error);
      }
    };
    fetchVitalsData();
  }, []); // Empty dependency array to run only once on component mount
  const appointmentHistoryStyle = {
    textAlign: 'center',
    color: '#324E6A',
    fontSize: '50px',
    textShadow: '0px 0px 32.1px #BB1111',
  };
  const vitalsContainerStyle = {
    maxWidth: '80%',
    margin: '0 auto',
    padding: '20px',
    backgroundColor: '#DCE7EB',
    border: '2px solid #104E8D',
    borderBottom: '2px solid #104E8D',
    overflowY: 'auto',
    marginBottom: '10px',
  };
  const vitalsGridStyle = {
    display: 'grid',
    gridTemplateColumns: 'repeat(3, 1fr)',
    gap: '10px',
    marginTop: '20px', // Add margin top for spacing
  };
  const vitalsItemStyle = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '10px',
    backgroundColor: '#FFF',
    border: '2px solid #DDD',
    borderRadius: '5px',
  };
  const vitalsIconStyle = {
    width: '40px', // Adjusted width for both icons
    height: '40px', // Adjusted height for both icons
    marginBottom: '10px',
    cursor: 'pointer', // Ensure cursor changes to pointer on hover
  };
  const vitalsNameStyle = {
    fontWeight: 'bold',
    marginBottom: '5px',
  };
  const vitalsValueStyle = {
    color: '#324E6A',
  };
  const handleGraphClick = () => {
    navigate("/graph"); // Navigate to "/graph-page" when graph icon is clicked
  };
  const CustomInput = forwardRef(({ value, onClick }, ref) => (
    <div onClick={onClick} ref={ref} style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
      <img src={Graph} alt="Graph" style={{ ...vitalsIconStyle, marginRight: '10px' }} onClick={handleGraphClick} />
      <img src={Calendar} alt="Calendar" style={{ ...vitalsIconStyle, marginRight: '10px' }} />
      <input
        type="text"
        value={value}
        readOnly
        style={{ backgroundColor: '#D9D9D9', border: 'none', outline: 'none', borderRadius: '5px', padding: '5px', marginBottom: '5px' }}
      />
    </div>
  ));
  const lineStyle = {
    borderTop: '1px solid #494949',
    // margin: '20px 0', // Adjust margin as needed
  };
  const pageStyle = {
    background: 'linear-gradient(to bottom, #7CB2D0, #D0E0E5)',
    minHeight: '100vh',
  };
  return (
    <div style={pageStyle}>
        <Redirect />
      <Patientheader />
      <div style={appointmentHistoryStyle}>
        User Dashboard
      </div>
      <div style={vitalsContainerStyle}>
        <Header />
        <div style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: '0px' }}>
          <DatePicker
            selected={startDate}
            onChange={(date) => setStartDate(date)}
            customInput={<CustomInput />}
          />
        </div>
        <div style={lineStyle}></div> {/* Line divider */}
        <div style={vitalsGridStyle}>
          {vitalsData.map((vital, index) => (
            <div key={index} style={vitalsItemStyle}>
              <img src={vital.icon} alt={`${vital.name} icon`} style={vitalsIconStyle} />
              <span style={vitalsNameStyle}>{vital.name}</span>
              <span style={vitalsValueStyle}>{vital.value}</span>
            </div>
          ))}
        </div>
      </div>
      <Footer />
    </div>
  );
}
export default AppointmentHistory;
// import React, { useState, useEffect, forwardRef } from "react";
// import axios from "axios";
// import { useNavigate } from "react-router-dom";
// import Patientheader from "../../Patientheader";
// import Footer from "../../Footer";
// import Header from "../Header/Header";
// import HeightIcon from "../../../Assets/Height.png";
// import PulseRateIcon from "../../../Assets/PulseRateIcon.png";
// import RespirationIcon from "../../../Assets/Respiration.png";
// import WeightIcon from "../../../Assets/WeightIcon.png";
// import Spo2Icon from "../../../Assets/Spo2Icon.png";
// import TempIcon from "../../../Assets/TempIcon.png";
// import DiastolicBPIcon from "../../../Assets/DiastolicBPIcon.png";
// import SystolicBPIcon from "../../../Assets/DiastolicBPIcon.png";
// import HeartRateIcon from "../../../Assets/HeartRateIcon.png";
// import Graph from "../../../Assets/Graph.png";
// import Calendar from "../../../Assets/Calendar.png";
// import DatePicker from "react-datepicker";
// import "react-datepicker/dist/react-datepicker.css";
// import BASE_URL from '../../config/apiConfig';

// function AppointmentHistory() {
//   const [startDate, setStartDate] = useState(null);
//   const [vitalsData, setVitalsData] = useState([
//     { name: "BloodGroup", value: 'N/A', icon: HeightIcon },
//     { name: "Pulse Rate(/min)", value: '0', icon: PulseRateIcon },
//     { name: "Respiration", value: '0', icon: RespirationIcon },
//     { name: "Hemoglobin", value: '0', icon: WeightIcon },
//     { name: "Spo2(%)", value: '0', icon: Spo2Icon },
//     { name: "Temp(°F)", value: '0', icon: TempIcon },
//     { name: "Diastolic BP", value: '0', icon: DiastolicBPIcon },
//     { name: "Systolic BP", value: '0', icon: SystolicBPIcon },
//     { name: "Heart Rate(bpm)", value: '0', icon: HeartRateIcon },
//   ]); // Initialize with default values
//   const navigate = useNavigate(); // Get navigate function from useNavigate hook

//   useEffect(() => {
//     // Function to fetch the latest patient vitals
//     const fetchVitalsData = async () => {
//       try {
//         const jwtToken = localStorage.getItem('jwtToken');
//         const response = await axios.get(`${BASE_URL}/api/patients/get-latest-patient-vitals`, {
//           headers: {
//             'Content-Type': 'application/json',
//             'Auth': `Bearer ${jwtToken}`
//           }
//         });
//         const data = response.data;

//         // Update state with fetched data, maintaining default values if data is missing
//         setVitalsData(prevVitalsData => prevVitalsData.map(vital => ({
//           ...vital,
//           value: data[vital.name.replace(/\s+/g, '').toLowerCase()] || vital.value
//         })));
//       } catch (error) {
//         console.error('Error fetching vitals data:', error);
//       }
//     };

//     fetchVitalsData();
//   }, []); // Empty dependency array to run only once on component mount

//   const appointmentHistoryStyle = {
//     textAlign: 'center',
//     color: '#324E6A',
//     fontSize: '50px',
//     textShadow: '0px 0px 32.1px #BB1111',
//   };

//   const vitalsContainerStyle = {
//     maxWidth: '80%',
//     margin: '0 auto',
//     padding: '20px',
//     backgroundColor: '#DCE7EB',
//     border: '2px solid #104E8D',
//     borderBottom: '2px solid #104E8D',
//     overflowY: 'auto',
//     marginBottom: '10px',
//   };

//   const vitalsGridStyle = {
//     display: 'grid',
//     gridTemplateColumns: 'repeat(3, 1fr)',
//     gap: '10px',
//     marginTop: '20px',
//   };

//   const vitalsItemStyle = {
//     display: 'flex',
//     flexDirection: 'column',
//     alignItems: 'center',
//     padding: '10px',
//     backgroundColor: '#FFF',
//     border: '2px solid #DDD',
//     borderRadius: '5px',
//   };

//   const vitalsIconStyle = {
//     width: '40px',
//     height: '40px',
//     marginBottom: '10px',
//     cursor: 'pointer',
//   };

//   const vitalsNameStyle = {
//     fontWeight: 'bold',
//     marginBottom: '5px',
//   };

//   const vitalsValueStyle = {
//     color: '#324E6A',
//   };

//   const handleGraphClick = () => {
//     navigate("/graph");
//   };

//   const CustomInput = forwardRef(({ value, onClick }, ref) => (
//     <div onClick={onClick} ref={ref} style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
//       <img src={Graph} alt="Graph" style={{ ...vitalsIconStyle, marginRight: '10px' }} onClick={handleGraphClick} />
//       <img src={Calendar} alt="Calendar" style={{ ...vitalsIconStyle, marginRight: '10px' }} />
//       <input
//         type="text"
//         value={value}
//         readOnly
//         style={{ backgroundColor: '#D9D9D9', border: 'none', outline: 'none', borderRadius: '5px', padding: '5px', marginBottom: '5px' }}
//       />
//     </div>
//   ));

//   const lineStyle = {
//     borderTop: '1px solid #494949',
//   };

//   const pageStyle = {
//     background: 'linear-gradient(to bottom, #7CB2D0, #D0E0E5)',
//     minHeight: '100vh',
//   };

//   return (
//     <div style={pageStyle}>
//       <Patientheader />
//       <div style={appointmentHistoryStyle}>
//         User Dashboard
//       </div>
//       <div style={vitalsContainerStyle}>
//         <Header />
//         <div style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: '0px' }}>
//           <DatePicker
//             selected={startDate}
//             onChange={(date) => setStartDate(date)}
//             customInput={<CustomInput />}
//           />
//         </div>
//         <div style={lineStyle}></div> {/* Line divider */}
//         <div style={vitalsGridStyle}>
//           {vitalsData.map((vital, index) => (
//             <div key={index} style={vitalsItemStyle}>
//               <img src={vital.icon} alt={`${vital.name} icon`} style={vitalsIconStyle} />
//               <span style={vitalsNameStyle}>{vital.name}</span>
//               <span style={vitalsValueStyle}>{vital.value}</span>
//             </div>
//           ))}
//         </div>
//       </div>
//       <Footer />
//     </div>
//   );
// }

// export default AppointmentHistory;
