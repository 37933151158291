/**
 * Purpose:
 * This component is responsible for displaying a list of doctors.
 * It fetches the doctor data from the backend API using JWT authentication.
 * The list displays important details of each doctor, such as their name, email, mobile number, and specialty.
 * If there are fewer than 9 doctors, it generates empty rows to maintain a consistent table layout.
 * The component also includes a loading state and error handling to provide feedback to the user during data fetching.
 *
 * Key Features:
 * - **JWT Authentication**: Uses the JWT token stored in sessionStorage to authenticate the request.
 * - **Fetching Doctors**: Fetches the list of doctors from the backend API and displays them in a table.
 * - **Loading State**: Displays a loading message while the data is being fetched.
 * - **Error Handling**: Catches and displays an error message if fetching the doctor data fails.
 * - **Responsive Table**: Displays doctor data in a table format with scrollable content if there are more than 9 rows.
 * - **Empty Rows**: Adds empty rows to the table if there are fewer than 9 doctors to maintain the table's layout.
 * - **Styling**: Utilizes `styled-components` for responsive and customizable styling.
 * - **Navigation**: Integrates the `Sidebar`, `Header`, and `Redirect` components for a complete page layout.
 */
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Sidebar from './Sidebar';
import styled from 'styled-components';
import { BASE_URL } from '../config/apiConfig';
import Header from './Header'; 
import Redirect from "../../Components/Redirect";
// Styled Components
// Sidebar style: Fixed on the left, full height, and with scrolling enabled
const SidebarStyled = styled.div`
  position: fixed; 
  top: 0;
  left: 0;
  height: 100vh; 
  width: 250px; 
  background-color: #f4f4f4;
  overflow-y: auto; 
  z-index: 1000; 
`;
// Main content style: Has padding, background, and a left margin for Sidebar
const MainContent = styled.div`
  margin-left: 250px; 
  padding: 20px;
  font-family: Arial, sans-serif;
  background-color: #f8f9fa; /* Optional: background color for the main content */
  min-height: 100vh; /* Ensures the content takes the full height */
`;
// Table container style: Enables vertical scrolling if more than 9 rows
const TableContainer = styled.div`
  max-height: ${({ rowCount }) => (rowCount > 9 ? '400px' : 'auto')}; /* Set height based on row count */
  overflow-y: auto; /* Enable vertical scrolling */
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin-top: 20px; /* Margin to separate from heading */
`;
// Table styling for table, th (table header), td (table data)
const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
`;
const Th = styled.th`
  padding: 10px;
  border: 1px solid #ddd;
  text-align: left;
  background-color: #287b9c; /* Primary color for header */
  color: white; /* Text color for header */
`;
const Td = styled.td`
  padding: 10px;
  border: 1px solid #ddd;
  text-align: left;
`;
const Tr = styled.tr`
  &:nth-child(even) {
    background-color: #f9f9f9; /* Light grey for even rows */
  }
  &:nth-child(odd) {
    background-color: white; /* White for odd rows */
  }
`;
// Empty row styling when there are less than 9 doctors
const EmptyRow = styled(Tr)`
  height: 50px; /* Set height to match filled rows */
  background-color: #f9f9f9; /* Light grey background for empty rows */
`;
const Loading = styled.div`
  text-align: center;
  padding: 20px;
  font-size: 18px;
`;
const PageHeading = styled.h1`
  text-align: center;
  color: #324e6a;
  font-size: 36px;
  font-weight: bold;
  margin-bottom: 30px;
`;
const DoctorsList = () => {
  // State variables for doctors, loading status, error handling
  const [doctors, setDoctors] = useState([]);
  const [loading, setLoading] = useState(true); 
  const [error, setError] = useState(null); 
  const jwtToken = sessionStorage.getItem('jwtToken');
  // useEffect hook to fetch doctors data on component mount
  useEffect(() => {
    const fetchDoctors = async () => {
      if (!jwtToken) {
        setError('No JWT token available for authentication');
        setLoading(false);
        return;
      }
      try {
            // Make API request to fetch doctors' data
        const response = await axios.get(`${BASE_URL}/user/doctors`, {
          headers: {
            'Auth': `Bearer ${jwtToken}`
          }
        });
        setDoctors(response.data);
      } catch (error) {
        setError('Error fetching doctors');
      } finally {
        setLoading(false);
      }
    };
    fetchDoctors(); // Call function to fetch doctors data
  }, [jwtToken]);  // Run the effect whenever the jwtToken changes
  // Generate empty rows if doctors.length < 9
  const totalRows = 9;
  const emptyRows = totalRows - doctors.length;
  return (
    <div className="doctors-list">
      <Redirect />
      <Header />
      <SidebarStyled>
        <Sidebar />
      </SidebarStyled>
      <MainContent>
        <PageHeading>Doctors List</PageHeading>
        <TableContainer rowCount={doctors.length || emptyRows}>
          {loading ? (
            <Loading>Loading...</Loading>  // Show loading message only in the data area
          ) : error ? (
            <p>{error}</p>  // Show error message only in the data area
          ) : (
            <Table>
              <thead>
                <tr>
                  <Th>Name</Th>
                  <Th>Email</Th>
                  <Th>Mobile No</Th>
                  <Th>Specialist</Th>
                </tr>
              </thead>
              <tbody>
                {doctors.map((doctor, index) => (
                  <Tr key={index}>
                    <Td>{doctor.name}</Td>
                    <Td>{doctor.email}</Td>
                    <Td>{doctor.mobileNo}</Td>
                    <Td>{doctor.specialist}</Td>
                  </Tr>
                ))}
                {/* Add empty rows */}
                   {/* Add empty rows if there are fewer than 9 doctors */}
                {emptyRows > 0 && [...Array(emptyRows)].map((_, index) => (
                  <EmptyRow key={`empty-${index}`}>
                    <Td></Td>
                    <Td></Td>
                    <Td></Td>
                    <Td></Td>
                  </EmptyRow>
                ))}
              </tbody>
            </Table>
          )}
        </TableContainer>
      </MainContent>
    </div>
  );
};
export default DoctorsList;
