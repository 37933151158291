//===============================================Home Page============================
/**
 * HomePage.js:
 * 
 * Purpose:
 * - This component is the main interface for managing video calls between patients and doctors.
 * - It allows users to initiate a video call with a doctor, accept or decline incoming calls, and manage video token data.
 * - The video call functionality is integrated with a backend API for generating and managing video tokens.
 * 
 * Key Features:
 * - **Fetching Doctors**: The component fetches a list of available doctors from the server and allows the user to select one to initiate a video call.
 * - **Initiating a Video Call**: Patients can initiate a video call with a selected doctor, which generates a video token and joins the call.
 * - **Incoming Call Management**: Doctors can receive incoming calls, accept or decline them, and join the call if accepted.
 * - **Persistent Video Call**: Once a video call is active, the interface switches to a persistent video call view where the video interaction takes place.
 * 
 * Key Functions:
 * - `fetchVideoTokenData`: Retrieves the video token data for the doctor, checking if there is an incoming call.
 * - `handleVideoCallClick`: Handles the video call initiation process and saves the video token for the call.
 * - `handleJoinMeeting`: Joins the video call based on the video token data and navigates to the meeting page.
 * - `handleDeclineCall`: Declines an incoming video call and cleans up any associated video token data.
 * - `renderVideoCall`: Renders the `PersistentVideoCall` component when a video call is active.
 * 
 * Additional Notes:
 * - **JWT Token**: The JWT token is required for authentication and is stored in sessionStorage. It is used for making API requests.
 * - **User Role**: The component distinguishes between the user roles (patient or doctor) and adjusts the behavior accordingly.
 * - **Polling**: The component polls every 10 seconds for incoming video calls when the user is a doctor and no video call is active.
 * - **Video Token Management**: Video token data is fetched and saved through API calls, allowing the system to track and manage video call sessions.
 * 
 * Dependencies:
 * - React (useState, useEffect, useLocation, useNavigate)
 * - Axios for API requests
 * - `VideoCallContext` for managing room ID
 * - `PersistentVideoCall` for rendering the video call interface
 * - MUI for icons and styling
 */

import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import './Homecontact.css'; 
import Footer from './Visitorfooter.js'; 
import homeBackground from "../../Assets/home.png";
import logo from "../../Assets/O2I_LOGO.png"; // Import your image here
import { FaHospital, FaUser, FaUserMd, FaStethoscope, FaFileAlt, FaHeartbeat } from 'react-icons/fa';

function Homepage() {
    const registeredHospitals = 1;
    const registeredPatients = 10;
    const registeredDoctors = 1;

    useEffect(() => {
        const cards = document.querySelectorAll(".card");
        cards.forEach((card, index) => {
            setTimeout(() => {
                card.style.transform = "translateY(0)";
                card.style.opacity = "1";
            }, index * 100);
        });
    }, []);

    useEffect(() => {
        const processCards = document.querySelectorAll(".process-card");
        const observer = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    entry.target.style.transform = "translateX(0)";
                    entry.target.style.opacity = "1";
                    observer.unobserve(entry.target);
                }
            });
        }, {
            threshold: 0.1,
        });

        processCards.forEach((card) => {
            observer.observe(card);
        });

        return () => {
            processCards.forEach((card) => {
                observer.unobserve(card);
            });
        };
    }, []);

    return (
        <div className="homepage">
            <div className="header">
            <div className="header-text">
                    <img src={logo} alt="Oxygen 2 Innovation" className="logo-image" />
                </div>
                <div className="buttons-container">
                    <Link to="/registerhospital" className="login-btn">Login/Register</Link>
                    
                </div>
            </div>
            <div className="background-container">
                <div className="text-overlay">
                    <h1>Oxygen 2 Innovation Telemedicine Platform</h1>
                </div>
                <img src={homeBackground} alt="Home Background" className="home-background"/>
            </div>

            <div className="card-container">
                <div className="card">
                    <div className="icon-container">
                        <FaHospital className="card-icon" />
                    </div>
                    <div className="card-content">
                        <h2>Registered Hospitals</h2>
                        <p>{registeredHospitals}</p>
                    </div>
                </div>
                <div className="card">
                    <div className="icon-container">
                        <FaUser className="card-icon" />
                    </div>
                    <div className="card-content">
                        <h2>Registered Patients</h2>
                        <p>{registeredPatients}</p>
                    </div>
                </div>
                <div className="card">
                    <div className="icon-container">
                        <FaUserMd className="card-icon" />
                    </div>
                    <div className="card-content">
                        <h2>Registered Doctors</h2>
                        <p>{registeredDoctors}</p>
                    </div>
                </div>
            </div>

            <div className="process-heading">
                <h2>Let's See How We Process</h2>
            </div>
            <div className="process-container">
    <div className="process-card">
        <div className="number-container">1</div>
        <div className="icon-container">
            <FaStethoscope className="process-icon" />
        </div>
        <h2>Checkup</h2>
        <p>Perform health checkups on registered patients.</p>
    </div>
    <div className="process-card">
        <div className="number-container">2</div>
        <div className="icon-container">
            <FaFileAlt className="process-icon" />
        </div>
        <h2>Get Results</h2>
        <p>Retrieve and review patient health results.</p>
    </div>
    <div className="process-card">
        <div className="number-container">3</div>
        <div className="icon-container">
            <FaHeartbeat className="process-icon" />
        </div>
        <h2>Ongoing Care</h2>
        <p>Provide continuous care and monitoring.</p>
    </div>
    <div className="process-card">
        <div className="number-container">4</div>
        <div className="icon-container">
            <FaHeartbeat className="process-icon" />
        </div>
        <h2>Follow-up</h2>
        <p>Provide post-care follow-up for patients.</p>
    </div>
</div>


            {/* <div className="process-container">
                <div className="process-card">
                    <div className="number-container">1</div>
                    <div className="icon-container">
                        <FaUser className="process-icon" />
                    </div>
                    <h2>Patient Registration</h2>
                    <p>Register new patients into the system.</p>
                </div>
                <div className="process-card">
                    <div className="number-container">2</div>
                    <div className="icon-container">
                        <FaStethoscope className="process-icon" />
                    </div>
                    <h2>Checkup</h2>
                    <p>Perform health checkups on registered patients.</p>
                </div>
                <div className="process-card">
                    <div className="number-container">3</div>
                    <div className="icon-container">
                        <FaFileAlt className="process-icon" />
                    </div>
                    <h2>Get Results</h2>
                    <p>Retrieve and review patient health results.</p>
                </div>
                <div className="process-card">
                    <div className="number-container">4</div>
                    <div className="icon-container">
                        <FaHeartbeat className="process-icon" />
                    </div>
                    <h2>Ongoing Care</h2>
                    <p>Provide continuous care and monitoring.</p>
                </div>
            </div> */}

            <div className="content-container">
                <div className="content-left">
                    <h1>Welcome to Our Telemedicine Platform</h1>
                    <p>Telemedicine connects patients with doctors virtually through health officers. Health officers gather patient details, including vitals and medical history, and assist in virtual consultations. Doctors provide expert medical advice, diagnose conditions, and prescribe treatments, ensuring seamless, remote healthcare access without the need for in-person visits.</p>
                </div>
                <div className="content-right">
                    <div className="query-section">
                        <div className="query-box">
                            <h3>Drop Us a Message For Any Query</h3>
                            <form>
                                <input type="text" placeholder="Name" />
                                <input type="email" placeholder="Email" />
                                <input type="tel" placeholder="Mobile Number" />
                                <textarea placeholder="Your message"></textarea>
                                <button type="submit">Submit</button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
}

export default Homepage;


















