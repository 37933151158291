//==============================================videocall==========================================================
/**
 * VideoCall Component:
 * 
 * Purpose:
 * - This component facilitates the video call functionality for users, allowing them to initiate and participate 
 *   in video calls using a unique `callID`. It is designed for Health Officers and Doctors, ensuring seamless 
 *   communication between the two roles during one-on-one calls.
 * 
 * Key Features:
 * - **Call Initialization**: Initializes the video call by generating a unique `callID`, and connects both the 
 *   Health Officer and Doctor to the video room, enabling video and audio for communication.
 * - **Token Generation**: Generates a secure token for users (Health Officer and Doctor) to join the room, ensuring 
 *   proper authentication and authorization before accessing the call.
 * - **Real-Time Communication**: Uses WebRTC for real-time communication, managing the video and audio streams 
 *   for both participants, ensuring smooth video calling.
 * - **Call ID Sharing**: Facilitates sharing the unique `callID` with the doctor or health officer, allowing them 
 *   to connect to the video call through the ID.
 * - **Call Termination**: Provides functionality for both users to end the video call when the conversation is complete, 
 *   with appropriate cleanup of resources and user session data.
 * 
 * Additional Notes:
 * - **WebRTC Integration**: The component relies on WebRTC technology to handle the video call streams and connection.
 * - **Session Management**: It uses `sessionStorage` to store temporary session data such as `callID`, ensuring that 
 *   the user remains connected across page reloads.
 * - **User Interaction**: The component enables dynamic interaction, allowing the Health Officer to initiate the call 
 *   and the Doctor to join based on the shared `callID`.
 * - **UI Flexibility**: The UI is designed to adapt based on whether the user is actively participating in the call or 
 *   if they need to minimize or end the call.
 */
import React, { useState, useEffect } from 'react';
import { FaMicrophone, FaVideo, FaPhoneSlash } from 'react-icons/fa';
import 'bootstrap/dist/css/bootstrap.min.css';
import WebRTCClient from "./WebRTCClient";
import { useLocation } from 'react-router-dom';
import { FaPlus, FaMinus } from 'react-icons/fa';
import { BASE_URL } from '../config/apiConfig';
function VideoConferenceInterface() {
    const [activeTab, setActiveTab] = useState('Vitals');
    const location = useLocation();
    const [vitals, setVitals] = useState(null);
    const [testResults, setTestResults] = useState([]);
    const [prescriptions, setPrescriptions] = useState([{ id: 1 }]);
    const [prescriptionData, setPrescriptionData] = useState({});
    const handleAddFields = () => {
        const newPrescription = {
            id: prescriptions.length + 1
        };
        setPrescriptions([...prescriptions, newPrescription]);
    };
    const handleRemoveFields = (id) => {
        const updatedPrescriptions = prescriptions.filter(item => item.id !== id);
        setPrescriptions(updatedPrescriptions);
    };
    const handleTabClick = (tab) => {
        setActiveTab(tab);
    };
    // useEffect(() => {
    //     if (location.state && location.state.patientId) {
    //         const { patientId } = location.state;
    //         fetchPatientData(patientId);
    //     } else {
    //         console.error('No patient ID provided in location state');
    //     }
    // }, [location.state]);
    useEffect(() => {
        const { patientId } = location.state || {};

        if (patientId) {
            sessionStorage.setItem('patientId', patientId); // Save patientId to sessionStorage
            fetchPatientData(patientId);
        } else {
            const storedPatientId = sessionStorage.getItem('patientId'); // Get from sessionStorage
            if (storedPatientId) {
                fetchPatientData(storedPatientId);
            } else {
                console.error('No patient ID available');
            }
        }
    }, [location]);
    const fetchPatientData = async (patientId) => {
        const key = sessionStorage.getItem("jwtToken");

        if (!key) {
            console.error("No JWT token found in sessionStorage");
            return;
        }

        try {
            // Fetch patient vitals
            const vitalsResponse = await fetch(`${BASE_URL}/registerDocHs/get-patient-vitals?patientId=${patientId}`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Auth': `Bearer ${key}`
                }
            });

            if (!vitalsResponse.ok) {

                const errorMessage = await vitalsResponse.text();
                throw new Error(`HTTP error! Status: ${vitalsResponse.status}, ${errorMessage}`);
            }


            const vitalsData = await vitalsResponse.json();
            console.log('Fetched patient vitals:', vitalsData);
            setVitals(vitalsData);

            // Fetch test results using patientHealthMetricsId from vitals
            if (vitalsData.id) {
                const testResultsResponse = await fetch(`${BASE_URL}/registerDocHs/patient-documents?patientHealthMetricsId=${vitalsData.id}`, {
                    headers: {
                        'Content-Type': 'application/json',
                        'Auth': `Bearer ${key}`
                    }
                });

                if (!testResultsResponse.ok) {
                    throw new Error('Failed to fetch test results');
                }

                const testResultsData = await testResultsResponse.json();
                console.log('Fetched test results:', testResultsData);
                setTestResults(testResultsData);
            } else {
                console.error('No valid patient vitals ID found');
            }

            // Fetch prescription using patient ID
            const prescriptionResponse = await fetch(`${BASE_URL}/registerDocHs/get-medicine?patientId=${patientId}`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Auth': `Bearer ${key}`
                }
            });

            if (!prescriptionResponse.ok) {
                const errorMessage = await prescriptionResponse.text();
                throw new Error(`HTTP error! Status: ${prescriptionResponse.status}, ${errorMessage}`);
            }

            const prescriptionData = await prescriptionResponse.json();
            console.log('Fetched prescription:', prescriptionData);
            setPrescriptionData(prescriptionData);

        } catch (error) {
            console.error('Error fetching patient data:', error);
        }
    };
    useEffect(() => {
        console.log('Prescription data:', prescriptionData);
    }, [prescriptionData]);
    const handleDocumentDownload = (documentUrl) => {

        const anchor = document.createElement('a');
        anchor.href = `${BASE_URL}${documentUrl}`;
        anchor.target = '_blank';
        anchor.setAttribute('download', '');
        anchor.click();
    };
    return (
        <div style={styles.container}>
            {/* Main content */}
            <div className="row" style={{ height: '100%' }}>
                <div className="col-md-6" style={{ overflowY: 'auto' }}>
                    <WebRTCClient />
                </div>
                <div className="col-md-6" style={{ ...styles.vitalDetails, background: 'transparent' }}>
                    <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '10px' }}>
                        <button
                            style={{
                                ...styles.tabButton,
                                backgroundColor: activeTab === 'Vitals' ? '#007bff' : '#BABABA',
                            }}
                            onClick={() => handleTabClick('Vitals')}
                        >
                            Vitals
                        </button>
                        <button
                            style={{
                                ...styles.tabButton,
                                backgroundColor: activeTab === 'TestResults' ? '#007bff' : '#BABABA',
                            }}
                            onClick={() => handleTabClick('TestResults')}
                        >
                            Test Results
                        </button>
                        <button
                            style={{
                                ...styles.tabButton,
                                backgroundColor: activeTab === 'Prescription' ? '#007bff' : '#BABABA',
                            }}
                            onClick={() => handleTabClick('Prescription')}
                        >
                            Prescription
                        </button>
                    </div>
                    {activeTab === 'Vitals' && (
                        <>
                            <div style={styles.vitalDataContainer}>
                                {/* Blood Group */}
                                <div style={styles.vitalDataItem}>
                                    <p style={styles.vitalLabel}>Blood Group</p>
                                    <p style={styles.vitalValue}>{vitals ? vitals.bloodGroup : '-'}</p>
                                </div>
                                {/* SpO2 */}
                                <div style={styles.vitalDataItem}>
                                    <p style={styles.vitalLabel}>SpO2 (%)</p>
                                    <p style={styles.vitalValue}>{vitals ? vitals.spO2 : '-'}</p>
                                </div>
                                {/* Respiration Rate */}
                                <div style={styles.vitalDataItem}>
                                    <p style={styles.vitalLabel}>Respiration Rate</p>
                                    <p style={styles.vitalValue}>{vitals ? vitals.respirationRate : '-'}</p>
                                </div>
                                {/* Heart Rate */}
                                <div style={styles.vitalDataItem}>
                                    <p style={styles.vitalLabel}>Heart Rate</p>
                                    <p style={styles.vitalValue}>{vitals ? vitals.heartRate : '-'}</p>
                                </div>
                                {/* Diastolic BP */}
                                <div style={styles.vitalDataItem}>
                                    <p style={styles.vitalLabel}>Diastolic BP (mmHg)</p>
                                    <p style={styles.vitalValue}>{vitals ? vitals.diastolicBP : '-'}</p>
                                </div>
                                {/* Systolic BP */}
                                <div style={styles.vitalDataItem}>
                                    <p style={styles.vitalLabel}>Systolic BP (mmHg)</p>
                                    <p style={styles.vitalValue}>{vitals ? vitals.systolicBP : '-'}</p>
                                </div>
                                {/* Pulse Rate */}
                                <div style={styles.vitalDataItem}>
                                    <p style={styles.vitalLabel}>Pulse Rate (/min)</p>
                                    <p style={styles.vitalValue}>{vitals ? vitals.pulseRate : '-'}</p>
                                </div>
                                {/* Temperature */}
                                <div style={styles.vitalDataItem}>
                                    <p style={styles.vitalLabel}>Temperature</p>
                                    <p style={styles.vitalValue}>{vitals ? vitals.temperature : '-'}</p>
                                </div>
                                {/* Hemoglobin */}
                                <div style={styles.vitalDataItem}>
                                    <p style={styles.vitalLabel}>Hemoglobin</p>
                                    <p style={styles.vitalValue}>{vitals ? vitals.hemoglobin : '-'}</p>
                                </div>
                            </div>
                        </>
                    )}
                    {activeTab === 'TestResults' && (
                        <div style={{ overflowX: 'auto' }}>
                            <table style={styles.table}>
                                <thead>
                                    <tr style={styles.tableHeaderRow}>
                                        <th style={styles.tableHeaderLeft}>Document Name</th>
                                        <th style={styles.tableHeaderCenter}>Document Type</th>
                                        <th style={styles.tableHeaderRight}>View Document</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {testResults.length > 0 ? (
                                        testResults.map((test, index) => (
                                            <tr key={index}>
                                                <td style={styles.tableDataLeft}>
                                                    <div style={styles.valueBox}>{test.documentName}</div>
                                                </td>
                                                <td style={styles.tableDataCenter}>
                                                    <div style={styles.valueBox}>{test.document}</div>
                                                </td>
                                                <td style={styles.tableDataRight}>
                                                    <div style={{ ...styles.valueBox, color: 'white' }}>
                                                        <p onClick={() => handleDocumentDownload(test.documentUrl)} style={styles.documentLink}>Download</p>
                                                    </div>
                                                </td>
                                            </tr>
                                        ))
                                    ) : (
                                        <tr>
                                            <td colSpan="3" style={{ textAlign: 'center' }}>No test results available</td>
                                        </tr>
                                    )}

                                </tbody>
                            </table>
                        </div>
                    )}
                    {activeTab === 'Prescription' && (
                        <>
                            <div style={{ marginBottom: '10px', display: 'flex', alignItems: 'center' }}>
                                <div style={{ marginRight: '10px' }}>
                                    <p style={{ marginBottom: '5px' }}>Chief Complaints:</p>
                                </div>
                                <div>
                                    <input
                                        type="text"
                                        placeholder="Enter chief complaints"
                                        value={prescriptionData.chiefComplaints || ''}
                                        readOnly
                                        style={{
                                            height: '30px',
                                            width: '150px', // Adjust the width as needed
                                            overflow: 'auto', // Enable scrolling if content overflows
                                            whiteSpace: 'nowrap' // Prevents text from wrapping
                                        }}
                                    />
                                </div>
                                <div style={{ marginLeft: '20px', marginRight: '10px' }}>
                                    <p style={{ marginBottom: '5px' }}>Symptoms:</p>
                                </div>
                                <div>
                                    <input
                                        type="text"
                                        placeholder="Enter symptoms"
                                        value={prescriptionData.symptoms || ''}
                                        readOnly
                                        style={{
                                            height: '30px',
                                            width: '100px', // Adjust the width as needed
                                            overflow: 'auto',
                                            whiteSpace: 'nowrap' // Prevents text from wrapping
                                        }}
                                    />
                                </div>
                            </div>
                            <div style={{ height: '360px', overflow: 'auto', background: '#0F60B3', marginBottom: '10px', padding: '10px' }}>
                                <div style={{ marginBottom: '10px', display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
                                    <div style={{ flex: '1', marginRight: '10px', marginBottom: '20px' }}>
                                        <p style={{ color: 'white', marginBottom: '5px' }}>Medication:</p>
                                        {prescriptionData.medications && prescriptionData.medications.map((medication, index) => (
                                            <textarea key={index} placeholder="Enter Medication" style={{ width: '100%', borderRadius: '5px', padding: '5px', height: '30px', overflow: 'hidden', marginBottom: '10px' }} value={medication.medication} readOnly />
                                        ))}
                                    </div>
                                    <div style={{ flex: '1', marginRight: '10px', marginBottom: '20px' }}>
                                        <p style={{ color: 'white', marginBottom: '5px' }}>Frequency:</p>
                                        {prescriptionData.medications && prescriptionData.medications.map((medication, index) => (
                                            <textarea key={index} placeholder="Enter Frequency" style={{ width: '100%', borderRadius: '5px', padding: '5px', height: '30px', overflow: 'hidden', marginBottom: '10px' }} value={medication.frequency} readOnly />
                                        ))}
                                    </div>
                                    <div style={{ flex: '1', marginRight: '10px', marginBottom: '20px' }}>
                                        <p style={{ color: 'white', marginBottom: '5px' }}>Dosage:</p>
                                        {prescriptionData.medications && prescriptionData.medications.map((medication, index) => (
                                            <textarea key={index} placeholder="Enter Dosage" style={{ width: '100%', borderRadius: '5px', padding: '5px', height: '30px', overflow: 'hidden', marginBottom: '10px' }} value={medication.dosage} readOnly />
                                        ))}
                                    </div>
                                    <div style={{ flex: '1', marginBottom: '20px' }}>
                                        <p style={{ color: 'white', marginBottom: '5px' }}>Days:</p>
                                        {prescriptionData.medications && prescriptionData.medications.map((medication, index) => (
                                            <textarea key={index} placeholder="Enter Days" style={{ width: '100%', borderRadius: '5px', padding: '5px', height: '30px', overflow: 'hidden', marginBottom: '10px' }} value={medication.days} readOnly />
                                        ))}
                                    </div>
                                </div>
                                <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                                    <div style={{ width: '50%', marginBottom: '10px', paddingRight: '10px' }}>
                                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                                            <div style={{ flex: '1' }}>
                                                <p style={{ color: 'white' }}>Advice:</p>
                                                <textarea placeholder="Enter advice" style={{ width: '100%', borderRadius: '5px', padding: '5px', resize: 'vertical', height: '60px' }} value={prescriptionData.advice || ''} readOnly />
                                            </div>
                                        </div>
                                    </div>
                                    <div style={{ width: '50%', marginBottom: '10px', paddingRight: '10px' }}>
                                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                                            <div style={{ flex: '1', marginRight: '10px' }}>
                                                <p style={{ color: 'white' }}>ProvisionalDiagnosis:</p>
                                                <textarea placeholder="Enter provisionalDiagnosis" style={{ width: '100%', borderRadius: '5px', padding: '5px', resize: 'vertical', height: '60px' }} value={prescriptionData.provisionalDiagnosis || ''} readOnly />
                                            </div>
                                        </div>
                                    </div>
                                    <div style={{ width: '50%', marginBottom: '10px', paddingRight: '10px' }}>
                                        <p style={{ color: 'white' }}>Comorbidity:</p>
                                        <textarea placeholder="Enter comorbidity" style={{ width: '100%', borderRadius: '5px', padding: '5px', height: '60px', resize: 'vertical' }} value={prescriptionData.comorbidity || ''} readOnly />
                                    </div>
                                    <div style={{ width: '50%', marginBottom: '10px', paddingRight: '10px' }}>
                                        <p style={{ color: 'white' }}>Complaints:</p>
                                        <textarea placeholder="Enter complaints" style={{ width: '100%', borderRadius: '5px', padding: '5px', height: '60px', resize: 'vertical' }} value={prescriptionData.complaints || ''} readOnly />
                                    </div>
                                    <div style={{ width: '50%', marginBottom: '10px', paddingRight: '10px' }}>
                                        <p style={{ color: 'white' }}>OtherIllnesses:</p>
                                        <textarea placeholder="Enter otherIllnesses" style={{ width: '100%', borderRadius: '5px', padding: '5px', height: '60px', resize: 'vertical' }} value={prescriptionData.otherIllnesses || ''} readOnly />
                                    </div>
                                    <div style={{ width: '50%', marginBottom: '10px', paddingRight: '10px' }}>
                                        <p style={{ color: 'white' }}>Allergy:</p>
                                        <textarea placeholder="Enter Allergies" style={{ width: '100%', borderRadius: '5px', padding: '5px', height: '60px', resize: 'vertical' }} value={prescriptionData.allergy || ''} readOnly />
                                    </div>
                                    <div style={{ width: '50%', marginBottom: '10px', paddingRight: '10px' }}>
                                        <p style={{ color: 'white' }}>Laboratory:</p>
                                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                                            {prescriptionData.laboratory && prescriptionData.laboratory.map((lab, index) => (
                                                <div key={index}>
                                                    <textarea placeholder="Enter Laboratory" style={{ width: '100%', borderRadius: '5px', padding: '5px', height: '30px', overflow: 'hidden', marginBottom: '5px' }} value={lab.laboratory} readOnly />
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                    <div style={{ width: '50%', marginBottom: '10px', paddingRight: '10px' }}>
                                        <p style={{ color: 'white' }}>Follow Up Date:</p>
                                        <textarea placeholder="Enter follow-up date" style={{ width: '100%', borderRadius: '5px', padding: '5px', resize: 'vertical', height: '60px' }} value={prescriptionData.followUpDate || ''} readOnly />
                                    </div>
                                </div>
                            </div>
                        </>
                    )}
                </div>
            </div>
        </div>
    );
}
export default VideoConferenceInterface;
const styles = {
    container: {
        padding: '10px',
        backgroundColor: 'transparent', // Set container background to transparent
        border: '2px solid #7E7E7E',
        boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)',
        marginTop: '20px',
        marginBottom: '10px', // Reduce bottom margin of the container
        // height: '500px', // Set the desired height of the container
    },
    doctorScreen: {
        // borderRadius: '8px',
        padding: '10px',
        backgroundColor: '#7A7A7A',
        height: '100%', // Set the height of the doctor's screen to 100%
    },
    patientScreen: {
        // borderRadius: '8px',
        padding: '10px',
        backgroundColor: '#7A7A7A',
        height: '100%', // Set the height of the patient's screen to 100%
    },
    videoFeed: {
        width: '100%',
        height: '70%', // Adjust the height of the video feed
        borderRadius: '8px',
        overflow: 'hidden',
        marginBottom: '10px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    videoFeedText: {
        color: '#fff',
    },
    controlsBottom: {
        display: 'flex',
        justifyContent: 'center',
        marginTop: '60px', // Adjusted bottom margin of the screen's controls
    },
    controlButton: {
        backgroundColor: 'transparent',
        border: 'none',
        cursor: 'pointer',
        fontSize: '24px',
        color: 'red', // Change icon color to red
        marginRight: '10px',
        transition: 'color 0.3s ease',
    },
    vitalDetails: {
        padding: '20px',
        borderRadius: '8px',
        boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)',
    },
    tabButton: {
        padding: '8px 16px',
        border: 'none',
        borderRadius: '8px',
        color: '#fff',
        cursor: 'pointer',
        transition: 'background-color 0.3s ease',
    },
    vitalDataContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-between',
    },
    vitalDataItem: {
        width: '30%', // Each item occupies one-third of the container's width
        padding: '10px',
        // border: '1px solid #ccc',
        borderRadius: '8px',
        marginBottom: '10px',
    },
    vitalLabel: {
        fontWeight: 'bold',
        marginBottom: '5px',
        color: '#8D8D8D',
        maxWidth: '100%', // Specify maximum width for the label
        wordWrap: 'break-word', // Allow words to break if they exceed the width
    },
    vitalValue: {
        fontSize: '16px',
        backgroundColor: '#f9f9f9',
        padding: '8px',
        borderRadius: '8px',
        width: '100%',
        boxSizing: 'border-box',
        minHeight: '40px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    //============================================css for result======================================================
    table: {
        width: '100%',
        borderCollapse: 'collapse',
        marginTop: '10px',
        overflowX: 'auto', // Add horizontal overflow handling
    },
    tableHeaderRow: {
        backgroundColor: '#0F60B3',
        color: 'white',
        borderRadius: '8px',
        overflow: 'hidden',
    },
    tableHeaderLeft: {
        padding: '8px',
        textAlign: 'left',
        minWidth: '150px', // Set minimum width for columns
        whiteSpace: 'nowrap', // Prevent wrapping in headers
    },
    tableHeaderCenter: {
        padding: '8px',
        textAlign: 'center',
        minWidth: '150px', // Set minimum width for columns
        whiteSpace: 'nowrap', // Prevent wrapping in headers
    },
    tableHeaderRight: {
        padding: '8px',
        textAlign: 'right',
        minWidth: '150px', // Set minimum width for columns
        whiteSpace: 'nowrap', // Prevent wrapping in headers
    },
    tableDataLeft: {
        padding: '8px',
        textAlign: 'left',
        minWidth: '150px', // Set minimum width for columns
        whiteSpace: 'nowrap', // Prevent wrapping in cells
    },
    tableDataCenter: {
        padding: '8px',
        textAlign: 'center',
        minWidth: '150px', // Set minimum width for columns
        whiteSpace: 'nowrap', // Prevent wrapping in cells
    },
    tableDataRight: {
        padding: '8px',
        textAlign: 'right',
        minWidth: '150px', // Set minimum width for columns
        whiteSpace: 'nowrap', // Prevent wrapping in cells
    },
    valueBox: {
        backgroundColor: '#5596D9',
        borderRadius: '4px',
        padding: '6px 10px',
        display: 'inline-block',
        color: 'white',
        width: '150px',
        textAlign: 'center',
        whiteSpace: 'nowrap', // Prevent wrapping in value boxes
    },
    documentLink: {
        color: 'white',
        textDecoration: 'none',
        wordWrap: 'break-word', // Allow long URLs to wrap
    },
    //====================================================================presvcription css====================
    mediaQueryStyles: `
        @media only screen and (max-width: 1205px) and (min-width: 775px) {
            .vitalLabel {
                font-size: 5px; // Decrease font size for smaller screens
            }
        }
    `,
};

