//=================================Videoheader============================================
/**
 * Purpose:
 * This component renders the header of the video consultation application, providing navigation between three main sections:
 * "Video Consultation", "Today's Patient List", and "Appointment History". It dynamically highlights the active tab based on 
 * the current page or tab selected by the user. The component uses the `useLocation` hook from `react-router-dom` to determine
 * the active path and update the active tab accordingly.
 * 
 * Key Features:
 * - **Dynamic Tab Highlighting**: The active tab is dynamically updated based on the current path in the browser, providing
 *   clear navigation cues for the user.
 * - **Navigation Links**: Uses `Link` components from `react-router-dom` to enable smooth client-side navigation between different
 *   sections of the application.
 * - **State Management**: The `useState` and `useEffect` hooks are used to manage and update the active tab as the user navigates.
 * - **Responsive Design**: The component is designed with a flexible layout, ensuring the navigation bar looks good on different screen sizes.

 * Additional Notes:
 * - **Tab Styling**: The tabs change color when active, providing a visual cue for the current section.
 * - **useLocation Hook**: The `useLocation` hook is used to access the current URL and determine which section/tab should be active.
 * - **Active Tab Logic**: The `getActiveTab` function checks the current path to return the correct active tab name.
 * - **Header Layout**: The header contains a horizontal layout of links, styled to provide a clean and user-friendly navigation experience.

 * Future Enhancements:
 * - **Accessibility**: Add proper ARIA attributes to improve accessibility for screen readers.
 * - **Hover Effects**: Implement hover effects on the navigation tabs for enhanced interactivity.
 * - **User Profile/Logout**: Consider adding a user profile icon or logout option to the header.
 */

import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';

function Header() {
    const location = useLocation();
    // Function: getActiveTab
    // Purpose: Determines the active tab label based on the current route (URL pathname).
    // It matches the `location.pathname` against predefined cases and returns the corresponding tab label.
    // If no match is found, it defaults to "Video Consultation".
    const getActiveTab = () => {
        switch (location.pathname) {
            case '/hovideo':
                return "Video Consultation";// Active tab for the Video Consultation page.
            case '/list':
                return "Today's Patient List";// Active tab for Today's Patient List page.
            case '/appointment':
                return "Appointment History";// Active tab for Appointment History pag
            case '/appointment':
                return "Today's Patient List";
            default:
                return "Video Consultation";// Default tab when no other path matches.
        }
    };

    const [activeTab, setActiveTab] = useState(getActiveTab);

    useEffect(() => {
        setActiveTab(getActiveTab);
    }, [location]);

    return (
        <div>
            <header style={styles.header1}>
                <Link
                    to="/hovideo"
                    style={activeTab === "Video Consultation" ? { ...styles.tab1, ...styles.active1 } : styles.tab1}
                    onClick={() => setActiveTab("Video Consultation")}
                >
                    Video Consultation
                </Link>
                <Link
                    to="/list"
                    style={activeTab === "Today's Patient List" ? { ...styles.tab1, ...styles.active1 } : styles.tab1}
                    onClick={() => setActiveTab("Today's Patient List")}
                >
                    Today's Patient List
                </Link>
                <Link
                    to="/appointment"
                    style={activeTab === "Appointment History" ? { ...styles.tab1, ...styles.active1 } : styles.tab1}
                    onClick={() => setActiveTab("Appointment History")}
                >
                    Appointment History
                </Link>
            </header>
        </div>
    );
}
const styles = {
    header1: {
        display: 'flex',
        justifyContent: 'space-around',
        alignItems: 'center',
        backgroundColor: '#C3C3C3',
        padding: '5px 0',
    },
    tab1: {
        backgroundColor: 'transparent',
        border: 'none',
        cursor: 'pointer',
        fontSize: '16px',
        padding: '10px 20px',
        transition: 'background-color 0.3s ease',
        color: 'white',
        textDecoration: 'none',
    },
    active1: {
        backgroundColor: '#007bff',
        borderRadius: '5px',
    },
};
export default Header;
// import React, { useState } from 'react';
// import VideoCall from '../../../Components/Video/Videocall';        // Import VideoCall component
// import TodayPatient from '../../Healthofficer/Todaylist/Todaylist';  // Import TodayPatient component
// import Appointment from '../../Healthofficer/Appointmenthistory/Appointmenthistory';    // Import Appointment component

// function Header() {
//     // State to manage the currently active tab
//     const [activeTab, setActiveTab] = useState("Video Consultation");

//     // Function to render content based on the active tab
//     const renderContent = () => {
//         switch (activeTab) {
//             case "Video Consultation":
//                 return <VideoCall />;  // Render VideoCall component
//             case "Today's Patient List":
//                 return <TodayPatient />;  // Render TodayPatient component
//             case "Appointment History":
//                 return <Appointment />;  // Render Appointment component
//             default:
//                 return <VideoCall />;  // Default to VideoCall component
//         }
//     };

//     return (
//         <div>
//             <header style={styles.header}>
//                 <button
//                     style={activeTab === "Video Consultation" ? {...styles.tab, ...styles.active} : styles.tab}
//                     onClick={() => setActiveTab("Video Consultation")}
//                 >
//                     Video Consultation
//                 </button>
//                 <button
//                     style={activeTab === "Today's Patient List" ? {...styles.tab, ...styles.active} : styles.tab}
//                     onClick={() => setActiveTab("Today's Patient List")}
//                 >
//                     Today's Patient List
//                 </button>
//                 <button
//                     style={activeTab === "Appointment History" ? {...styles.tab, ...styles.active} : styles.tab}
//                     onClick={() => setActiveTab("Appointment History")}
//                 >
//                     Appointment History
//                 </button>
//             </header>

//             {/* Render the content based on the active tab */}
//             <div style={styles.content}>
//                 {renderContent()}
//             </div>
//         </div>
//     );
// }

// const styles = {
//     header: {
//         display: 'flex',
//         justifyContent: 'space-around',
//         alignItems: 'center',
//         backgroundColor: '#C3C3C3',
//         padding: '5px 0',
//     },
//     tab: {
//         backgroundColor: 'transparent',
//         border: 'none',
//         cursor: 'pointer',
//         fontSize: '16px',
//         padding: '10px 20px',
//         transition: 'background-color 0.3s ease',
//         color: 'white',
//         textDecoration: 'none',
//     },
//     active: {
//         backgroundColor: '#007bff',
//         borderRadius: '5px',
//     },
//     content: {
//         padding: '20px',
//         backgroundColor: '#f4f4f4',
//     }
// };

// export default Header;

