

//=================================== PersistentvideoCall===================================================
/**
 * PersistentVideoCall Component:
 * 
 * Purpose:
 * - This component manages the persistent video call experience, allowing users to join a video call room
 *   using a specific `roomID`. It is designed for different user roles (e.g., Health Officer, Doctor) and ensures
 *   that the video call interface adapts based on the user's role and current page.
 * 
 * Key Features:
 * - **Video Call Initialization**: Initializes the video call using the ZegoUIKitPrebuilt library, generating a 
 *   token and joining the room with video and audio enabled. The room configuration is optimized for a one-on-one call.
 * - **Minimization Logic**: Based on the user's role and the current page, the video call interface may be minimized
 *   to save screen space when the user is not on the correct page for the call.
 * - **Role-Based Behavior**: The component adjusts its behavior based on the user role (Health Officer or Doctor).
 *   If the user navigates to an incorrect page (not intended for video calls), the video call is minimized.
 * - **Leaving the Room**: When the user leaves the room, session storage is cleared, and the user is redirected to
 *   their appropriate page (e.g., Health Officer to the list page, Doctor to the legal page).
 * 
 * Additional Notes:
 * - **ZegoUIKit Integration**: The component uses the ZegoUIKitPrebuilt SDK to manage the video call functionality,
 *   including token generation and room joining.
 * - **Session Management**: It uses `sessionStorage` to store information such as user role and room details, ensuring
 *   that the video call state persists between page reloads.
 * - **Page Redirection**: After leaving the video call room, the user is redirected based on their role (Health Officer or Doctor).
 * - **Dynamic UI**: The component adjusts its size and layout based on whether the call is minimized or in full-screen mode.
 */

import React, { useEffect, useRef, useState } from 'react';
import { ZegoUIKitPrebuilt } from '@zegocloud/zego-uikit-prebuilt';
import { useLocation } from 'react-router-dom';
import './Videocallcs.css';
import { BASE_URL } from '../config/apiConfig';
const PersistentVideoCall = ({ roomID }) => {
  const videoCallContainer = useRef(null);
  const [isMinimized, setIsMinimized] = useState(false);
  const [userRole, setUserRole] = useState('');
  const location = useLocation();
  useEffect(() => {
    const storedUserRole = sessionStorage.getItem('role');
    setUserRole(storedUserRole);
    if (!roomID) {
      console.error('No roomID provided');
      return;
    }
    //======old appid and serversecret==============================
    // const appID = 709268713;
    // const serverSecret = '5a24fd89403d07d27d7640c1c28be33d';
    //=======================new appid and serversecret====================================
    const appID = 323242698;
    const serverSecret = 'bfe82c433a50a6542953c67a8ae6a80a';
    const roomIDString = String(roomID);
    const Name = sessionStorage.getItem('Name') || 'Doctor';
    const kitToken = ZegoUIKitPrebuilt.generateKitTokenForTest(
      appID,
      serverSecret,
      roomIDString,
      Date.now().toString(),
      Name
    );
    const zp = ZegoUIKitPrebuilt.create(kitToken);
    zp.joinRoom({
      container: videoCallContainer.current,
      scenario: { mode: ZegoUIKitPrebuilt.OneONoneCall },
      turnOnMicrophoneWhenJoining: true,
      turnOnCameraWhenJoining: true,
      showMyCameraToggleButton: true,
      showMyMicrophoneToggleButton: true,
      showAudioVideoSettingsButton: false,
      showTextChat: true,
      showUserList: false,
      maxUsers: 2,
      layout: 'Auto',
      showLayoutButton: false,
      showPreJoinView: false,
      showScreenSharingButton: false,
      onLeaveRoom: () => {
        handleLeaveRoom();
      },
    });
    return () => {
      if (zp && zp.destroy) {
        zp.destroy();
      }
    };
  }, [roomID]);
  useEffect(() => {
    const handleMinimizeBasedOnPath = () => {
      const currentPath = location.pathname;
      console.log(`Current Path: ${currentPath}`); 
      console.log(`User Role: ${userRole}`);
      if (userRole === '[HEALTH_OFFICER]') {
        if (currentPath !== '/hovideo') {
          console.log('Minimizing for HEALTH_OFFICER on wrong page');
          setIsMinimized(true);
        } else {
          console.log('Not minimizing for HEALTH_OFFICER on /hovideo');
          setIsMinimized(false);
        }
      } else if (userRole === '[DOCTOR]') {
        if (currentPath !== '/legal') {
          console.log('Minimizing for DOCTOR on wrong page');
          setIsMinimized(true);
        } else {
          console.log('Not minimizing for DOCTOR on /legal');
          setIsMinimized(false);
        }
      } else {
        console.log('Unknown user role, not minimizing');
        setIsMinimized(false);
      }
    };
    handleMinimizeBasedOnPath();
    return () => {
      handleMinimizeBasedOnPath();
    };
  }, [location.pathname, userRole]);
  const handleLeaveRoom = () => {
    sessionStorage.removeItem('kitToken');
    sessionStorage.removeItem('roomID');
    sessionStorage.removeItem('z_appconfig_709268713_1');
    sessionStorage.removeItem('z_appconfig_etag_709268713_1');
    sessionStorage.removeItem('z_deviceID');
    sessionStorage.removeItem('z_net_access_servers_709268713_1');
    sessionStorage.removeItem('z_rtc_domains_709268713_1');
    sessionStorage.removeItem('z_s_c_709268713');
    sessionStorage.removeItem('z_store_keys');
    sessionStorage.removeItem('callDeclined');
    sessionStorage.removeItem('patientDetails_3');
    sessionStorage.removeItem('patientId');
    sessionStorage.removeItem('Name');
    sessionStorage.removeItem('patientName');
    const userRole1 = sessionStorage.getItem('role');
    console.log('Current userRole:', userRole1);
    if (userRole1 === '[HEALTH_OFFICER]') {
      setTimeout(() => {
        window.location.href = '/list';
        setTimeout(() => {
          window.location.reload();
        }, 100);
      }, 100);
    } else if (userRole1 === '[DOCTOR]') {
      sessionStorage.removeItem('userId');
      setTimeout(() => {
        window.location.href = '/legal';
        setTimeout(() => {
          window.location.reload();
        }, 100);
      }, 100);
    } else {
      console.error('Unknown user role:', userRole);
    }
  };
  return (
    <div
      ref={videoCallContainer}
      className={isMinimized ? 'minimized-video' : 'full-video'}
      style={{ width: isMinimized ? '200px' : '100%', height: isMinimized ? '100px' : '500px' }}
    >
      {isMinimized ? <p>Video Minimized</p> : <p>Active</p>}
    </div>
  );
  <div className='abc'></div>
};
export default PersistentVideoCall;



