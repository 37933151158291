/**
 * SearchComponent.js:
 * 
 * Purpose:
 * - This component represents a search interface for displaying a list of appointment history.
 * - It allows the doctor to filter appointment records by date range (from and to dates).
 * - The component fetches data and displays it in a table with multiple rows and columns (e.g., case number, patient name, doctor name, date/time, prescription details).
 * - The table is dynamic, adjusting the number of rows and blank rows depending on the data displayed.
 * 
 * Key Features:
 * - **Date Range Filters**: Users can select a date range using two DatePicker inputs (from and to dates) to filter the results.
 * - **Responsive Design**: The layout adjusts to different screen sizes, particularly with buttons adapting to smaller screens.
 * - **Dynamic Table**: The table dynamically adjusts based on the number of data rows and blank rows.
 * - **Search Functionality**: A search bar is included for further filtering the displayed appointment records.
 * - **Styling**: The component uses inline styling and a custom input for date selection, including icons for the calendar.
 * 
 * Key Functions:
 * - `handleSearch`: Triggered when the "Search" button is clicked. It logs the selected date range to the console and can be extended to fetch data from the backend.
 * - `handleRangeChange`: Handles changes to the range selection dropdown (e.g., 1-2 entries, 3-4 entries).
 * 
 * Dependencies:
 * - React for building the user interface.
 * - `react-datepicker` for date selection.
 * - `react-icons` for the calendar icon used in the date input fields.
 * - A table structure for displaying appointment records.
 * - CSS for styling the components.
 * 
 * Additional Notes:
 * - **Error Handling**: The component does not currently include error handling for the date fetch operation, but this can be added for a more robust user experience.
 * - **Table Layout**: The table layout is responsive, with alternate row colors for better visibility, and a custom input field is used for the date selection with a calendar icon.
 * - **Responsive Design**: The button styling dynamically adjusts for smaller screen sizes to maintain a good user experience on all devices.
 */

import React from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { FaCalendarAlt } from 'react-icons/fa';
import Redirect from "../Components/Redirect";
const styles = {
  label: {
    marginRight: '20px',
  },
 
  appointmentHistoryStyle: {
    textAlign: 'center',
    color: '#324E6A',
    fontSize: '50px',
    textShadow: '0px 0px 32.1px #BB1111',
  },
  tableContainerStyle: {
    maxWidth: '1200px',
    margin: '0 auto',
    padding: '20px',
    border: '2px solid #104E8D',
    borderBottom: '2px solid #104E8D',
    overflowY: 'auto',
    maxHeight: '520px', // Increased height
  },
  tableStyle: {
    width: '100%',
    borderCollapse: 'separate',
    border: '2px solid #D9D9D9',
  },
  thStyle: {
    backgroundColor: '#0F60B3',
    color: 'white',
    padding: '12px 8px',
    fontWeight: 'bold',
    textAlign: 'left',
    borderBottom: '1px solid #fff',
    borderRight: '2px solid #fff',
  },
  tdStyle: {
    padding: '8px',
    textAlign: 'left',
    borderRight: '2px solid #ddd',
  },
  lastColumnStyle: {
    borderRight: '2px solid #fff',
  },
  button: {
    padding: '8px 16px', // Adjust padding as needed
    cursor: 'pointer',
    borderRadius: '5px',
    marginRight: '40px',
    background: '#0B539C',
    color: 'white'
  },
  // Add media query to adjust button size on small screens
  '@media (max-width: 990px)': {
    button: {
      padding: '5px 10px', // Adjust padding for smaller screens
      width: 'auto', // Adjust width as needed
    },
  }
}
class SearchComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fromDate: null,
      toDate: null,
      range: '1-2',
      totalDataRows: 5, // Total number of data rows
      totalBlankRows: 5, // Total number of blank rows
    };
  }
  render() {
    const { range, fromDate, toDate, totalDataRows, totalBlankRows } = this.state;
    const isSmallScreen = window.matchMedia('(max-width: 990px)').matches;
    // Calculate container height dynamically based on the number of data rows and blank rows
    const rowHeight = 45; // Assuming each row height is 45px
    const headerHeight = 100; // Assuming the header height is 100px
    const containerHeight = headerHeight + rowHeight * (totalDataRows + totalBlankRows);
    return (
      <div style={{ maxWidth: '1250px', margin: '0 auto', padding: '20px' }}>
       <Redirect />
        {/* Date pickers */}
        <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '20px', border: '2px solid rgba(204, 204, 204, 0.5)', borderRadius: '5px', overflow: 'visible', paddingBottom: '10px' }}>
          <div style={{ position: 'relative', flex: '1', marginRight: '10px', marginTop: '10px', marginLeft: '40px' }}>
            <label style={{ marginRight: '10px' }}>From:</label>
            <DatePicker
              selected={fromDate}
              onChange={(date) => this.setState({ fromDate: date })}
              dateFormat="yyyy-MM-dd"
              className="date-picker"
              style={{ width: '100%' }}
              customInput={<CustomInput icon={<FaCalendarAlt />} />}
            />
          </div>
          <div style={{ position: 'relative', flex: '1', marginLeft: '10px', marginTop: '10px', marginRight: '150px' }}>
            <label style={{ marginRight: '10px' }}>To:</label>
            <DatePicker
              selected={toDate}
              onChange={(date) => this.setState({ toDate: date })}
              dateFormat="yyyy-MM-dd"
              className="date-picker"
              style={{ width: '100%' }}
              customInput={<CustomInput icon={<FaCalendarAlt />} />}
            />
          </div>
          <button
            style={{
              ...styles.button,
              marginTop: '10px',
              width: isSmallScreen ? 'auto' : 'auto', // Apply width of 50px on small screens
            }}
            onClick={this.handleSearch}
          >
            Search
          </button>
        </div>
        {/* Table */}
        <div style={{ ...styles.tableContainerStyle, height: containerHeight }}>
        <div style={{ display: 'flex', alignItems: 'center', marginBottom: '20px' }}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <span style={{ marginLeft: '23px', marginRight: '10px', color: '#324E6A' }}>Show </span>
            <select value={range} onChange={this.handleRangeChange} style={{ backgroundColor: '#D9D9D9', border: 'none', outline: 'none' }}>
              <option value="1-2">1-2</option>
              <option value="3-4">3-4</option>
              {/* Add more range options as needed */}
            </select>
            <span style={{ marginLeft: '15px', marginRight: '15px', color: '#324E6A' }}>entries </span>
          </div>
          {/* Search bar */}
          <span style={{ marginLeft: 'auto', marginRight: '23px', color: '#324E6A' }}>Search: </span>
          <input type="text" style={{ backgroundColor: '#D9D9D9', border: 'none', outline: 'none', borderRadius: '5px', padding: '5px' }} />
        </div>
          <table style={styles.tableStyle}>
            <thead>
              <tr>
                <th style={styles.thStyle}>Case No.</th>
                <th style={styles.thStyle}>Patient Name</th>
                <th style={styles.thStyle}>Doctor Name</th>
                <th style={styles.thStyle}>Date/Time</th>
                <th style={styles.thStyle}>Prescription</th>
                <th style={styles.thStyle}>Prescription Document</th>
              </tr>
            </thead>
            <tbody>
              {/* Render data rows */}
              {Array.from({ length: totalDataRows }, (_, index) => (
                <tr key={index} style={{ height: `${rowHeight}px`, backgroundColor: index % 2 === 0 ? '#D9D9D9' : '#E6E6E6' }}>
                  <td style={styles.tdStyle}>{index + 1}</td>
                  <td style={styles.tdStyle}>John Doe</td>
                  <td style={styles.tdStyle}>Dr. Smith</td>
                  <td style={styles.tdStyle}>2024-05-15 10:00 AM</td>
                  <td style={styles.tdStyle}>Lorem Ipsum</td>
                  <td style={styles.tdStyle}>Link to Document</td>
                </tr>
              ))}
              {/* Render blank rows */}
              {Array.from({ length: totalBlankRows }, (_, index) => (
                <tr key={`blank-${index}`} style={{ height: `${rowHeight}px`, backgroundColor: index % 2 === 0 ? '#D9D9D9' : '#E6E6E6' }}>
                  <td style={styles.tdStyle}></td>
                  <td style={styles.tdStyle}></td>
                  <td style={styles.tdStyle}></td>
                  <td style={styles.tdStyle}></td>
                  <td style={styles.tdStyle}></td>
                  <td style={styles.tdStyle}></td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    );
  }
  handleSearch = () => {
    const fromLocation = this.state.fromDate;
    const toLocation = this.state.toDate;
    console.log('Search from:', fromLocation);
    console.log('Search to:', toLocation);
  };
  handleRangeChange = (event) => {
    this.setState({ range: event.target.value });
  };
}
// Custom input component for date picker
const CustomInput = ({ value, onClick, icon }) => (
  <div style={{ position: 'relative' }}>
    <input
      type="text"
      value={value}
      onClick={onClick}
      readOnly
      style={{ width: '100%', backgroundColor: '#fff', border: '1px solid #ccc', borderRadius: '5px', padding: '5px 30px 5px 10px' }}
    />
    {icon && <div style={{ position: 'absolute', right: '10px', top: '50%', transform: 'translateY(-50%)' }}>{icon}</div>}
  </div>
);
export default SearchComponent;
