/**
 * Purpose:
 * This component renders the appointment history page for patients, displaying a list of their past appointments, 
 * along with the associated documents. It allows users to view and download documents linked to each appointment. 
 * The component also manages date selection, data fetching, modal handling for document viewing, and dynamic 
 * rendering of appointment records based on a user-selected date range.
 * 
 * Key Features:
 * - **Date Picker**: Allows users to select a date range for filtering appointments.
 * - **Appointment Data Fetching**: Fetches appointment data from an API using JWT authentication. The data includes 
 *   details about the appointment date, doctor, and related documents.
 * - **Modal for Viewing Documents**: When a user clicks on a "View" button, a modal opens displaying the associated 
 *   documents for the appointment. Users can either download or open the documents in a new tab.
 * - **Dynamic Table Rendering**: Renders a table of appointments with dynamic data, including serial number, date, doctor name, 
 *   and document links.
 * - **Responsive Design**: The page layout adapts well to various screen sizes with a modern UI design that includes a 
 *   calendar-style date picker, a table with clear rows, and a styled modal.
 * 
 * Additional Notes:
 * - **Error Handling**: The component handles potential API errors and logs them to the console.
 * - **Session-Based Authentication**: The component retrieves the JWT token from `sessionStorage` to authenticate 
 *   the user for data fetching.
 * - **Styling**: The component uses inline styling to create a professional and user-friendly interface with custom 
 *   button styles, table headers, and modal views.
 * - **Custom Input for Date Picker**: A custom calendar icon is integrated into the date picker input for a better UX.
 */

//==========================================Test--Result=======================================
import React, { useState, useEffect, forwardRef } from "react";
import axios from "axios";
import Calendar from "../../../Assets/Calendar.png";
import Patientheader from "../../Patientheader";
import Footer from "../../Footer";
import Header from "../Header/Header";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Modal from 'react-modal';
import BASE_URL from '../../config/apiConfig';
const appointmentHistoryStyle = {
  textAlign: 'center',
  color: '#324E6A',
  fontSize: '50px',
  textShadow: '0px 0px 32.1px #BB1111',
};
const tableContainerStyle = {
  maxWidth: '1200px',
  margin: '0 auto',
  padding: '20px',
  backgroundColor: '#DCE7EB',
  border: '2px solid #104E8D',
  borderBottom: '2px solid #104E8D',
  overflowY: 'auto',
  marginBottom: '10px',
};
const tableStyle = {
  width: '100%',
  borderCollapse: 'separate',
  border: '2px solid #D9D9D9',
};
const thStyle = {
  backgroundColor: '#0F60B3',
  color: 'white',
  padding: '12px 8px',
  fontWeight: 'bold',
  textAlign: 'left',
  borderBottom: '1px solid #fff',
  borderRight: '2px solid #fff',
};
const tdStyle = {
  padding: '8px',
  borderBottom: '1px solid #ddd',
  textAlign: 'left',
  borderRight: '2px solid #ddd',
};
const lastColumnStyle = {
  borderRight: '2px solid #fff',
};
const columnStyles = {
  serialNo: { maxWidth: '100px' },
  date: { maxWidth: '100px' },
  doctorName: { maxWidth: '100px' },
  document: { maxWidth: '100px' },
};
// Custom input component for DatePicker
const CustomInput = forwardRef(({ value, onClick }, ref) => (
  <div onClick={onClick} ref={ref} style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
    <img src={Calendar} alt="Calendar" style={{ width: '40px', height: '40px', marginRight: '10px' }} />
    <input
      type="text"
      value={value}
      readOnly
      style={{ backgroundColor: '#D9D9D9', border: 'none', outline: 'none', borderRadius: '5px', padding: '5px', marginBottom: '5px' }}
    />
  </div>
));
function AppointmentHistory() {
  const [range, setRange] = useState("1-2");
  const [startDate, setStartDate] = useState(null);
  const [appointments, setAppointments] = useState([]);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [currentDocuments, setCurrentDocuments] = useState([]);
  const handleRangeChange = (event) => {
    setRange(event.target.value);
  };
  // fetch appointment data from api 
  const fetchAppointments = async () => {
    const jwtToken = sessionStorage.getItem('jwtToken');
    try {
      const response = await axios.get(`${BASE_URL}/api/patients/patient-documents-personal`, {
        headers: {
          'Content-Type': 'application/json',
          'Auth': `Bearer ${jwtToken}`
        }
      });
      console.log("API Response:", response.data); // Debug log
      // Ensure data is an array
      const data = Array.isArray(response.data) ? response.data : [response.data];
      const formattedData = data.map((item, index) => ({
        serialNo: index + 1,
        date: item.localDate,
        doctorName: item.doctor,
        documents: item.documents || [] // Ensure documents is an array
      }));

      setAppointments(formattedData);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };
  useEffect(() => {
    fetchAppointments();
  }, []);
  const rowHeight = 45;
  const numberOfDataRows = 10;
  const headerHeight = 100;
  const totalRows = appointments.length;
  const displayedRows = Math.max(totalRows, numberOfDataRows);
  const containerHeight = headerHeight + rowHeight * displayedRows;
  const openModal = (documents) => {
    console.log("documents url-------", documents)
    setCurrentDocuments(documents);
    setModalIsOpen(true);
  };
  const closeModal = () => {
    setModalIsOpen(false);
  };
  return (
    <div>
      <Patientheader />
      <div style={appointmentHistoryStyle}>
        User Dashboard
      </div>
      <div style={{ ...tableContainerStyle, height: containerHeight, position: 'relative' }}>
        <Header />
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <span style={{ marginLeft: '23px', marginRight: '10px', color: '#324E6A' }}>Show </span>
            <select value={range} onChange={handleRangeChange} style={{ backgroundColor: '#D9D9D9', border: 'none', outline: 'none' }}>
              <option value="1-2">1-2</option>
              <option value="3-4">3-4</option>
            </select>
            <span style={{ marginLeft: '15px', marginRight: '15px', color: '#324E6A' }}>entries </span>
          </div>
          <span style={{ marginLeft: 'auto', display: 'flex', alignItems: 'center', color: '#324E6A' }}>
            <DatePicker
              selected={startDate}
              onChange={(date) => setStartDate(date)}
              customInput={<CustomInput />}
            />
          </span>
        </div>
        <table style={tableStyle}>
          <thead>
            <tr>
              <th style={{ ...thStyle, ...columnStyles.serialNo }}>Serial No</th>
              <th style={{ ...thStyle, ...columnStyles.date }}>Date</th>
              <th style={{ ...thStyle, ...columnStyles.doctorName }}>Doctor Name</th>
              <th style={{ ...thStyle, ...columnStyles.document, ...lastColumnStyle }}>Document</th>
            </tr>
          </thead>
          <tbody>
            {appointments.map((data, index) => (
              <tr key={index} style={{ height: rowHeight, backgroundColor: index % 2 === 0 ? '#D9D9D9' : '#E6E6E6' }}>
                <td style={{ ...tdStyle, ...columnStyles.serialNo }}>{data.serialNo}</td>
                <td style={{ ...tdStyle, ...columnStyles.date }}>{data.date}</td>
                <td style={{ ...tdStyle, ...columnStyles.doctorName }}>{data.doctorName}</td>
                <td style={{ ...tdStyle, ...columnStyles.document, ...lastColumnStyle }}>
                  <button onClick={() => openModal(data.documents)}>View</button>
                </td>
              </tr>
            ))}
            {[...Array(displayedRows - appointments.length)].map((_, index) => (
              <tr key={index + appointments.length} style={{ height: rowHeight, backgroundColor: index % 2 === 0 ? '#D9D9D9' : '#E6E6E6' }}>
                <td style={{ ...tdStyle, ...columnStyles.serialNo }}></td>
                <td style={{ ...tdStyle, ...columnStyles.date }}></td>
                <td style={{ ...tdStyle, ...columnStyles.doctorName }}></td>
                <td style={{ ...tdStyle, ...columnStyles.document, ...lastColumnStyle }}></td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Documents Modal"
        ariaHideApp={false}
        style={{
          overlay: {
            backgroundColor: 'rgba(0, 0, 0, 0.7)' // Black background with 70% opacity
          },
          content: {
            maxWidth: '600px',
            margin: 'auto',
            height: '60vh',
            overflow: 'auto',
            position: 'relative',
            padding: '20px',
            backgroundColor: '#FFFFFF', // Background color of the modal content
            border: '1px solid #ccc', // Optional: border for the content
            borderRadius: '8px' // Optional: rounded corners for the content
          }
        }}
      >
        <button
          onClick={closeModal}
          style={{
            border: 'none',
            background: 'none',
            fontSize: '16px',
            cursor: 'pointer',
            position: 'absolute',
            top: '10px',
            right: '10px',
            color: '#000',
            padding: '5px',
            zIndex: 1000
          }}
        >
          <span
            style={{
              fontSize: '20px',
              color: '#000'
            }}
          >
            ✖
          </span>
        </button>
        <div>
          <h2>Documents</h2>
          {currentDocuments.length > 0 ? (
            <ul style={{ padding: '0', listStyleType: 'none' }}>
              {currentDocuments.map((doc, index) => (
                <li key={index} style={{ marginBottom: '15px', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                  <a href={doc.documentUrl} target="_blank" rel="noopener noreferrer" download style={{ textDecoration: 'none', color: '#007BFF', fontWeight: 'bold' }}>
                    Download {doc.documentName}
                  </a>
                  <button
                    onClick={() => window.open(doc.documentUrl, '_blank')}
                    style={{
                      backgroundColor: '#007BFF',
                      color: '#FFFFFF',
                      border: 'none',
                      borderRadius: '4px',
                      padding: '8px 12px',
                      fontSize: '14px',
                      cursor: 'pointer',
                      transition: 'background-color 0.3s',
                      marginLeft: '10px',
                    }}
                  >
                    Open Document
                  </button>
                </li>
              ))}
            </ul>
          ) : (
            <p>No document available.</p>
          )}
        </div>
      </Modal>
      <Footer />
    </div>
  );
}
export default AppointmentHistory;
