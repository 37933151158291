/**
 * Purpose:
 * This component is responsible for rendering the list of patients in the hospital dashboard.
 * It fetches the list of patients from the backend API using JWT authentication and displays them in a table.
 * If there are fewer than 9 patients, it generates empty rows to maintain a consistent table layout.
 * The component includes loading state and error handling to provide feedback during data fetching.
 *
 * Key Features:
 * - **JWT Authentication**: Uses the JWT token stored in sessionStorage to authenticate the request for fetching patient data.
 * - **Fetching Data**: Retrieves the list of patients from the backend API and displays them in a responsive table.
 * - **Loading State**: Displays a loading message while the data is being fetched.
 * - **Error Handling**: Displays an error message if the data fetching fails.
 * - **Empty Rows**: Adds empty rows to the table if there are fewer than 9 patients to maintain the table layout.
 * - **Responsive Table**: The table is scrollable if the number of rows exceeds 9.
 * - **Styled Components**: Utilizes `styled-components` to style the layout, table, and other elements for a consistent and customizable look.
 * - **Main Content Layout**: Includes sidebar and header layout components for a complete page structure.
 */
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Sidebar from './Sidebar';
import styled from 'styled-components';
import Header from './Header'; 
import { BASE_URL } from '../config/apiConfig';
import Redirect from "../../Components/Redirect";
// Styled Components
// Sidebar styling, fixed position on the left, height 100vh to cover the full viewport height
const SidebarStyled = styled.div`
  position: fixed; 
  top: 0;
  left: 0;
  height: 100vh; 
  width: 250px; 
  background-color: #f4f4f4;
  overflow-y: auto; 
  z-index: 1000; 
`;
const MainContent = styled.div`
  margin-left: 250px; 
  padding: 20px;
  font-family: Arial, sans-serif;
  background-color: #f8f9fa;
  min-height: 100vh;
`;
const TableContainer = styled.div`
  max-height: ${({ rowCount }) => (rowCount > 9 ? '400px' : 'auto')}; /* Set height based on row count */
  overflow-y: auto; /* Enable vertical scrolling */
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin-top: 20px; /* Margin to separate from heading */
`;
const Table = styled.table`
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
  background-color: #fff;
`;
const Th = styled.th`
  padding: 12px 15px;
  text-align: left;
  background-color: #287b9c;
  color: #fff;
  font-weight: bold;
  text-transform: uppercase;
  border-bottom: 1px solid #ddd;
`;
const Td = styled.td`
  padding: 12px 15px;
  border-bottom: 1px solid #ddd;
  text-align: left;
  color: #333;
  font-size: 14px;
  background-color: #f9f9f9;
`;
const Tr = styled.tr`
  &:nth-child(even) {
    background-color: #f1f1f1;
  }
  &:hover {
    background-color: #e0f7fa;
  }
`;
const PageHeading = styled.h1`
  text-align: center;
  color: #324e6a;
  font-size: 36px;
  font-weight: bold;
  margin-bottom: 30px;
`;
const LoadingMessage = styled.p`
  text-align: center;
  font-size: 20px;
  color: #007bff;
  margin-top: 20px;
`;
// PatientsList Component
const PatientsList = () => {
  const [patients, setPatients] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const jwtToken = sessionStorage.getItem('jwtToken');
 // useEffect hook to fetch patient data when the component mounts
  useEffect(() => {
    if (!jwtToken) {
         // If no JWT token is available, set error and stop loading
      setError('No JWT token available for authentication');
      setLoading(false);
      return;
    }
  // Function to fetch patient data from the server
    const fetchPatients = async () => {
      try {
        const response = await axios.get(`${BASE_URL}/user/patients`, {
          headers: {
            'Auth': `Bearer ${jwtToken}`
          }
        });
        setPatients(response.data);
      } catch (error) {
        setError('Error fetching patients');
      } finally {
        setLoading(false);
      }
    };
    fetchPatients();
  }, [jwtToken]);
  // If there's an error, display the error message
  if (error) {
    return <p>{error}</p>;
  }
  return (
    <div className="patients-list">
      <Redirect />
      <Header />
      <SidebarStyled>
        <Sidebar />
      </SidebarStyled>
      <MainContent>
        <PageHeading>Patients List</PageHeading>
        <TableContainer rowCount={patients.length}> {/* Pass rowCount to TableContainer */}
          {loading ? ( // Display loading message only in the table area
            <LoadingMessage>Loading...</LoadingMessage>
          ) : (
            <Table>
              <thead>
                <tr>
                  <Th>Patient ID</Th>
                  <Th>Name</Th>
                  <Th>Doctor</Th>
                  <Th>Age</Th>
                  <Th>Gender</Th>
                  <Th>Status</Th>
                  <Th>Health Officer</Th>
                  <Th>Aadhaar Card Number</Th>
                  <Th>Appointment Time</Th>
                </tr>
              </thead>
              <tbody>
                {patients.map((patient, index) => (
                  <Tr key={index}>
                    <Td>{patient.patientId}</Td>
                    <Td>{patient.patientName}</Td>
                    <Td>{patient.doctor}</Td>
                    <Td>{patient.age}</Td>
                    <Td>{patient.gender}</Td>
                    <Td>{patient.status ? 'Active' : 'Inactive'}</Td>
                    <Td>{patient.healthOfficer}</Td>
                    <Td>{patient.aadhaarCardNumber}</Td>
                    <Td>{new Date(patient.appointmentTime).toLocaleString()}</Td>
                  </Tr>
                ))}
              </tbody>
            </Table>
          )}
        </TableContainer>
      </MainContent>
    </div>
  );
};
export default PatientsList;
