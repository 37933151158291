
/**
 * Purpose:
 * This component facilitates the patient login process using OTP verification.
 * It allows the user to input their mobile number, send an OTP to that number, 
 * and verify the OTP to gain access. On successful OTP verification, 
 * the user is redirected to the dashboard with a JWT token stored in sessionStorage.
 *
 * Key Features:
 * - **Mobile Number Input**: Users can input their mobile number for OTP-based authentication.
 * - **OTP Sending**: Sends a request to the backend API to send an OTP to the provided mobile number.
 * - **OTP Verification**: Verifies the OTP entered by the user and authenticates them.
 * - **API Integration**: Interacts with the backend API using Axios for sending OTP and verifying it.
 * - **Error Handling**: Catches and logs any errors that occur during the OTP sending or verification process.
 * - **User Feedback**: Provides feedback to the user if the OTP is successfully sent or if there was an error.
 * - **Session Management**: Stores the JWT token in sessionStorage after successful verification.
 * - **Navigation**: Redirects the user to the `/userdashboard` page upon successful login.
 * - **Responsive Design**: The UI is styled using `styled-components` to ensure responsiveness across devices.
 * - **Real-time Updates**: Dynamically enables the OTP input and verification button after the OTP is sent.
 */
import React, { useState } from 'react';
import axios from 'axios';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { BASE_URL } from '../config/apiConfig';
// Now you can use BASE_URL in your code
console.log(BASE_URL);
// Styled-components for various parts of the UI
const Header = styled.div`
  background-color: #007bff;
  color: #fff;
  padding: 10px 0;
  text-align: center;
`;
const Container = styled.div`
  width: 100%;
  max-width: 400px;
  margin: 20px;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #f9f9f9;
`;
const FormGroup = styled.div`
  margin-bottom: 15px;
`;
const Label = styled.label`
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
  color: #000;
  text-align: center;
`;
const Input = styled.input`
  width: 100%;
  padding: 8px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 3px;
`;
const todayPatientListStyle = {
  textAlign: 'center',
  color: '#324E6A',
  fontSize: '50px',
  textShadow: '0px 0px 32.1px #BB1111',
};
const Button = styled.button`
  width: 100%;
  padding: 10px 20px;
  font-size: 16px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 3px;
  cursor: pointer;
  &:hover {
    background-color: #0056b3;
  }
`;
const OtpSentMessage = styled.div`
  margin-top: 10px;
  font-size: 14px;
  color: ${({ error }) => (error ? 'red' : 'green')};
`;
const AppContainer = styled.div`
  background: linear-gradient(to bottom, #7cb2d0, #d0e0e5);
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
const App = () => {
  // State hooks for mobile number, OTP, and related states
  const [mobileNo, setMobileNo] = useState('');
  const [otp, setOtp] = useState('');
  const [isOtpSent, setIsOtpSent] = useState(false);
  const [otpSentMessage, setOtpSentMessage] = useState('');
  const navigate = useNavigate();
  // Function to handle sending OTP
  const handleSendOtp = async () => {
    try {
      // Send OTP to the server using Axios
      const response = await axios.post(`${BASE_URL}/api/patients/send-otp?mobileNo=${mobileNo}`, {}, {
        headers: {
          'Content-Type': 'application/json'
        }
      });
      console.log('OTP sent successfully:', response.data);
      setIsOtpSent(true);
    } catch (error) {
      console.error('Error sending OTP:', error);
    }
  };
  // Function to handle verifying OTP
  const handleVerifyOtp = async () => {
    try {
      // Send OTP and mobile number to verify the OTP
      const response = await axios.post(
        `${BASE_URL}/api/patients/verify-otp?mobileNo=${mobileNo}&otp=${otp}`,
        {},
        {
          headers: {
            'Content-Type': 'application/json'
          }
        }
      );
      console.log('OTP verified successfully:', response.data);
      // Assuming your API response contains a JWT token in response.data.jwtToken
      const jwtToken = response.data.jwtToken;
      // Set JWT token in sessionStorage
      sessionStorage.setItem('jwtToken', jwtToken);
      // Redirect to /userdashboard
      navigate('/userdashboard');
    } catch (error) {
      console.error('Error verifying OTP:', error);
    }
  };
  return (
    <AppContainer>
      <div style={todayPatientListStyle}>
        Patient Login
      </div>
      <Container>
        <FormGroup>
          <Label htmlFor="mobileNo">Mobile No</Label>
          <Input
            type="text"
            id="mobileNo"
            value={mobileNo}
            onChange={(e) => setMobileNo(e.target.value)}
          />
        </FormGroup>
        <FormGroup>
          <Button onClick={handleSendOtp}>Send OTP</Button>
          {/* Display message after sending OTP */}
          <OtpSentMessage error={!otpSentMessage.startsWith('OTP sent')}>
            {otpSentMessage}
          </OtpSentMessage>
        </FormGroup>
        {/* Display OTP input and verification button after OTP is sent */}
        {isOtpSent && (
          <div>
            <FormGroup>
              <Label htmlFor="otp">Enter OTP:</Label>
              <Input
                type="text"
                id="otp"
                value={otp}
                onChange={(e) => setOtp(e.target.value)}
              />
            </FormGroup>
            <FormGroup>
              <Button onClick={handleVerifyOtp}>Verify OTP</Button>
            </FormGroup>
          </div>
        )}
      </Container>
    </AppContainer>
  );
};
export default App;

