/**
 * Purpose:
 * This component is responsible for rendering the list of patients in the hospital dashboard.
 * It fetches the list of patients from the backend API using JWT authentication and displays them in a table.
 * If there are fewer than 9 patients, it generates empty rows to maintain a consistent table layout.
 * The component includes loading state and error handling to provide feedback during data fetching.
 *
 * Key Features:
 * - **JWT Authentication**: Uses the JWT token stored in sessionStorage to authenticate the request for fetching patient data.
 * - **Fetching Data**: Retrieves the list of patients from the backend API and displays them in a responsive table.
 * - **Loading State**: Displays a loading message while the data is being fetched.
 * - **Error Handling**: Displays an error message if the data fetching fails.
 * - **Empty Rows**: Adds empty rows to the table if there are fewer than 9 patients to maintain the table layout.
 * - **Responsive Table**: The table is scrollable if the number of rows exceeds 9.
 * - **Styled Components**: Utilizes `styled-components` to style the layout, table, and other elements for a consistent and customizable look.
 * - **Main Content Layout**: Includes sidebar and header layout components for a complete page structure.
 */
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { BASE_URL } from '../config/apiConfig';
import Redirect from "../../Components/Redirect";
// PatientList component to display a list of patients
const PatientList = () => {
  const [patients, setPatients] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const jwtToken = sessionStorage.getItem('jwtToken');
  // State variables to manage patients data, loading state, and error handling
  useEffect(() => {
      // Retrieving the JWT token from session storage for authentication
    if (!jwtToken) {
      setError('No JWT token available for authentication');
      setLoading(false);
      return;
    }
 // Function to fetch patient data from the API
    const fetchPatients = async () => {
      try {
        const response = await axios.get(`${BASE_URL}/user/patients`, {
          headers: {
            'Auth': `Bearer ${jwtToken}`
          }
        });
        setPatients(response.data);
      } catch (error) {
        setError('Error fetching patients');
      } finally {
        setLoading(false);
      }
    };

    fetchPatients();
  }, [jwtToken]);
// Show a loading message while the data is being fetched
  if (loading) {
    return <p>Loading...</p>;
  }
  // If an error occurs, display the error message
  if (error) {
    return <p>{error}</p>;
  }
  return (
    <div className="patient-list">
      <Redirect />
          {/* Inline styles for the component */}
      <style>
        {`
          .patient-list {
            padding: 20px;
            max-width: 1000px;
            margin: auto;
            font-family: Arial, sans-serif;
          }

          h1 {
            text-align: center;
            margin-bottom: 20px;
          }

          table {
            width: 100%;
            border-collapse: collapse;
          }

          th, td {
            padding: 10px;
            border: 1px solid #ddd;
            text-align: left;
          }

          thead {
            background-color: #f4f4f4;
          }

          tr:nth-child(even) {
            background-color: #f9f9f9;
          }

          .table-container {
            max-height: 300px; /* Adjusted height for 10 rows */
            overflow-y: auto;
          }
        `}
      </style>
           {/* Container for the table */}
      <div className="table-container">
        <table>
          <thead>
            <tr>
              <th>ID</th>
              <th>Name</th>
              <th>Age</th>
              <th>Gender</th>
              <th>Health Officer</th>
              <th>Doctor</th>
              <th>Appointment Time</th>
              <th>Aadhaar Card Number</th>
            </tr>
          </thead>
                {/* Table Body - Displaying patient information */}
          <tbody>
                 {/* Mapping over the first 10 patients to display their data */}
            {patients.slice(0, 10).map(patient => (
              <tr key={patient.patientId}>
                <td>{patient.patientId}</td>
                <td>{patient.patientName}</td>
                <td>{patient.age}</td>
                <td>{patient.gender}</td>
                <td>{patient.healthOfficer}</td>
                <td>{patient.doctor}</td>
                <td>{new Date(patient.appointmentTime).toLocaleString()}</td>
                <td>{patient.aadhaarCardNumber}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};
export default PatientList;
