/**
 * Purpose:
 * This component is responsible for rendering the header of the hospital dashboard.
 * It includes navigation links for registering doctors/health officers and accessing the user profile.
 * The profile dropdown fetches and displays the profile information when clicked, such as the username, email, and mobile number.
 * It uses JWT authentication for secure access to the user's profile data from the backend API.
 *
 * Key Features:
 * - **JWT Authentication**: Uses the JWT token stored in sessionStorage to authenticate the request for fetching user profile details.
 * - **Navigation Links**: Provides navigation options to register doctors/health officers and to access the user profile.
 * - **Profile Dropdown**: Displays the user profile information such as username, email, and mobile number when the "Profile" link is clicked.
 * - **Loading State**: Shows a loading message while fetching profile data.
 * - **Error Handling**: Catches and displays an error message if there is an issue fetching the profile data.
 * - **Dropdown Visibility**: Allows toggling of the profile dropdown based on user interaction.
 * - **Styled Components**: Utilizes `styled-components` to apply custom styles and responsive design.
 */

import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { FaUserPlus, FaUserCircle } from 'react-icons/fa';
import axios from 'axios';
import { BASE_URL } from '../config/apiConfig';
// Styled container for the header, including the background color and layout properties
const HeaderContainer = styled.div`
  width: 100%;
  height: 60px;
  background-color: #7CB2D0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  position: relative; /* Make sure the dropdown is positioned relative to the header */
`;
const HeaderTitle = styled.h1`
  color: #ffffff;
  font-size: 24px;
`;
const NavLinks = styled.div`
  display: flex;
  align-items: center;
`;
const NavLink = styled(Link)`
  color: #ffffff;
  text-decoration: none;
  margin-left: 20px;
  display: flex;
  align-items: center;
  cursor: pointer;
  &:hover {
    color: #D0E0E5;
  }
  & > svg {
    margin-right: 5px;
  }
`;
const ProfileDropdown = styled.div`
  position: absolute;
  top: 60px; /* Adjust based on the header height */
  right: 20px; /* Adjust spacing from the right */
  background-color: #ffffff;
  border: 1px solid #ddd;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 10px;
  width: 200px;
  display: ${({ show }) => (show ? 'block' : 'none')};
  z-index: 1000; /* Ensure the dropdown is above other content */
`;
const ProfileItem = styled.div`
  margin-bottom: 10px;
`;
// Header component that contains the navigation links and profile dropdown
const Header = () => {
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);
  const [profile, setProfile] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const jwtToken = sessionStorage.getItem('jwtToken');
 // Fetch user profile data when dropdown is toggled
  useEffect(() => {
    const fetchProfile = async () => {
      if (!jwtToken) {
        console.error('No JWT token available for authentication');
        return;
      }
      try {
        const response = await axios.get(`${BASE_URL}/user/hospital-details`, {
          headers: {
            'Authorization': `Bearer ${jwtToken}`,
          },
        });
        setProfile(response.data);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    if (isDropdownVisible) {
      fetchProfile();
    }
  }, [isDropdownVisible, jwtToken]);
// Toggle the visibility of the profile dropdown
  const toggleDropdown = () => {
    setIsDropdownVisible(!isDropdownVisible);
  };
  return (
    <HeaderContainer>
      <HeaderTitle>Hospital Dashboard</HeaderTitle>
      <NavLinks>
        <NavLink to="/Register">
          <FaUserPlus />
          Register Doctor/Health Officer
        </NavLink>
        <NavLink onClick={toggleDropdown}>
          <FaUserCircle />
          Profile
        </NavLink>
        <ProfileDropdown show={isDropdownVisible}>
          {loading ? (
            <p>Loading...</p>
          ) : error ? (
            <p>Error: {error}</p>
          ) : profile ? (
            <div>
              <ProfileItem><strong>Username:</strong> {profile.userName}</ProfileItem>
              <ProfileItem><strong>Email:</strong> {profile.email}</ProfileItem>
              <ProfileItem><strong>Mobile No:</strong> {profile.mobileNo}</ProfileItem>
            </div>
          ) : (
            <p>No profile data available</p>
          )}
        </ProfileDropdown>
      </NavLinks>
    </HeaderContainer>
  );
};
export default Header;
