import React from "react";
import Footer from '../../Footer';
import Header from "../../Hoheader";
import Changepassword from "../../changepassword";
import Redirect from "../../../Components/Redirect";
function Appointment() {
  return (
    <div>
        <Redirect />
      <Header />
      <Changepassword />;
      <Footer />
    </div>
  );
}
export default Appointment;
