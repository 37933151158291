/**
 * Purpose:
 * This component is responsible for rendering the list of health officers in the hospital dashboard.
 * It fetches the list of health officers from the backend API using JWT authentication and displays them in a table.
 * If there are fewer than 9 health officers, it generates empty rows to maintain a consistent table layout.
 * The component includes loading state and error handling to provide feedback during data fetching.
 *
 * Key Features:
 * - **JWT Authentication**: Uses the JWT token stored in sessionStorage to authenticate the request for fetching health officer data.
 * - **Fetching Data**: Retrieves the list of health officers from the backend API and displays them in a responsive table.
 * - **Loading State**: Displays a loading message while the data is being fetched.
 * - **Error Handling**: Displays an error message if the data fetching fails.
 * - **Empty Rows**: Adds empty rows to the table if there are fewer than 9 health officers to maintain the table layout.
 * - **Responsive Table**: The table is scrollable if the number of rows exceeds 9.
 * - **Styled Components**: Utilizes `styled-components` to style the layout, table, and other elements for a consistent and customizable look.
 * - **Main Content Layout**: Includes sidebar and header layout components for a complete page structure.
 */

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Sidebar from './Sidebar';
import styled from 'styled-components';
import Header from './Header'; 
import { BASE_URL } from '../config/apiConfig';
import Redirect from "../../Components/Redirect";
// Styled Components for layout and styling
const SidebarStyled = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 250px;
  background-color: #f4f4f4;
  overflow-y: auto;
  z-index: 1000;
`;
const MainContent = styled.div`
  margin-left: 250px;
  padding: 20px;
  font-family: Arial, sans-serif;
  background-color: #f8f9fa; /* Optional: background color for the main content */
  min-height: 100vh; /* Ensures the content takes the full height */
`;
const TableContainer = styled.div`
  max-height: ${({ rowCount }) => (rowCount > 9 ? '400px' : 'auto')}; /* Set height based on row count */
  overflow-y: auto; /* Enable vertical scrolling */
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin-top: 20px; /* Margin to separate from heading */
`;
const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
`;
const PageHeading = styled.h1`
  text-align: center;
  color: #324e6a;
  font-size: 36px;
  font-weight: bold;
  margin-bottom: 30px;
`;
const Th = styled.th`
  padding: 10px;
  border: 1px solid #ddd;
  text-align: left;
  background-color: #287b9c; /* Primary color for header */
  color: white; /* Text color for header */
`;
const Td = styled.td`
  padding: 10px;
  border: 1px solid #ddd;
  text-align: left;
`;
const Tr = styled.tr`
  &:nth-child(even) {
    background-color: #f9f9f9; /* Light grey for even rows */
  }
`;
const EmptyRow = styled(Tr)`
  height: 50px; /* Set height to match filled rows */
  background-color: #f9f9f9; /* Light grey background for empty rows */
`;
const Loading = styled.div`
  text-align: center;
  padding: 20px;
  font-size: 18px;
`;
const HealthOfficersList = () => {
  const [healthOfficers, setHealthOfficers] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true); 
  const jwtToken = sessionStorage.getItem('jwtToken');
  useEffect(() => {
     // Check if JWT token is available, if not show an error
    if (!jwtToken) {
      console.error('No JWT token available for authentication');
      setError('No JWT token available for authentication');
      setLoading(false);
      return; 
    }
    const fetchHealthOfficers = async () => {
      setLoading(true);
      try {
         // Make API call to fetch health officers data
        const response = await axios.get(`${BASE_URL}/user/health-officer`, {
          headers: {
            'Auth': `Bearer ${jwtToken}`,
          },
        });
        setHealthOfficers(response.data);
      } catch (error) {
        console.error('Error fetching health officers:', error);
        setError('Error fetching health officers');
      } finally {
         // Set loading to false after data is fetched or error occurred
        setLoading(false);
      }
    };
    fetchHealthOfficers();
  }, [jwtToken]);
  // Generate empty rows if healthOfficers.length < 9
  const totalRows = 9;
  const emptyRows = totalRows - healthOfficers.length;
  // If there's an error, display the error message
  if (error) {
    return <p>{error}</p>;
  }
  return (
    <div className="health-officers-list">
      <Redirect />
      <Header />
      <SidebarStyled>
        <Sidebar />
      </SidebarStyled>
      <MainContent>
      <PageHeading>Health Officers List</PageHeading>
        <TableContainer rowCount={healthOfficers.length || emptyRows}>
          {loading ? (
            <Loading>Loading...</Loading>
          ) : (
            <Table>
              <thead>
                <tr>
                  <Th>Name</Th>
                  <Th>Email</Th>
                  <Th>Mobile No</Th>
                  <Th>Specialist</Th>
                </tr>
              </thead>
              <tbody>
              {/* // Render each health officer's details in a table row */}
                {healthOfficers.map((officer, index) => (
                  <Tr key={index}>
                    <Td>{officer.name}</Td>
                    <Td>{officer.email}</Td>
                    <Td>{officer.mobileNo}</Td>
                    <Td>{officer.specialist}</Td>
                  </Tr>
                ))}
                {/* Add empty rows */}
                        {/* Add empty rows if needed */}
                {emptyRows > 0 && [...Array(emptyRows)].map((_, index) => (
                  <EmptyRow key={`empty-${index}`}>
                    <Td></Td>
                    <Td></Td>
                    <Td></Td>
                    <Td></Td>
                  </EmptyRow>
                ))}
              </tbody>
            </Table>
          )}
        </TableContainer>
      </MainContent>
    </div>
  );
};

export default HealthOfficersList;
