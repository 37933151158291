
//=============================Casehistory Component===================================
// This component represents the layout for the 'Case History' page. It integrates different child components such as:
// 1. Redirect - Responsible for redirecting the user based on specific conditions (e.g., login state).
// 2. Header - Displays the main header for the page.
// 3. Videoheader - Displays the video consultation header.
// 4. Drpatienthistory - Displays patient history specific to the doctor.
// 5. Footer - Contains footer content that appears at the bottom of the page.

// The layout uses styled-components to define the styling for different sections such as the background, 
// header wrapper, and content wrapper. A gradient background is applied to give the page a clean and professional look.





import React from "react";
import styled from "styled-components";
import Videoheader from "../Components/Video/Videoheader";
import Drpatienthistory from "../Components/Drpatienthistory";
import Header from "../Components/Header";
import Footer from "../Components/Footer";
import Redirect from "../Components/Redirect"; 
const AppWrapper = styled.div`
  height: 100vh;
  overflow-y: auto;
`;
const Background = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to bottom, #DCE7EA, #C3E2EB);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  z-index: -1;
`;
const ContentWrapper = styled.div`
  padding: 2px;
`;
const HeaderWrapper = styled.div`
  margin: 0;
  padding: 0;
`;
function Casehistory() {
  return (
    <AppWrapper>
      <Background />
      <HeaderWrapper>
      <Redirect />
        <Header />
      </HeaderWrapper>
      <ContentWrapper>
        <Videoheader />
        <Drpatienthistory />
        <Footer />
      </ContentWrapper>
    </AppWrapper>
  );
}
export default Casehistory;
