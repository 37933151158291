//====================================WebRTCClient=========================================
/**
 * HomePage.js:
 * 
 * Purpose:
 * - This component is the main interface for managing video calls between patients and doctors.
 * - It allows users to initiate a video call with a doctor, accept or decline incoming calls, and manage video token data.
 * - The video call functionality is integrated with a backend API for generating and managing video tokens.
 * 
 * Key Features:
 * - **Fetching Doctors**: The component fetches a list of available doctors from the server and allows the user to select one to initiate a video call.
 * - **Initiating a Video Call**: Patients can initiate a video call with a selected doctor, which generates a video token and joins the call.
 * - **Incoming Call Management**: Doctors can receive incoming calls, accept or decline them, and join the call if accepted.
 * - **Persistent Video Call**: Once a video call is active, the interface switches to a persistent video call view where the video interaction takes place.
 * 
 * Key Functions:
 * - `fetchVideoTokenData`: Retrieves the video token data for the doctor, checking if there is an incoming call.
 * - `handleVideoCallClick`: Handles the video call initiation process and saves the video token for the call.
 * - `handleJoinMeeting`: Joins the video call based on the video token data and navigates to the meeting page.
 * - `handleDeclineCall`: Declines an incoming video call and cleans up any associated video token data.
 * - `renderVideoCall`: Renders the `PersistentVideoCall` component when a video call is active.
 * 
 * Additional Notes:
 * - **JWT Token**: The JWT token is required for authentication and is stored in sessionStorage. It is used for making API requests.
 * - **User Role**: The component distinguishes between the user roles (patient or doctor) and adjusts the behavior accordingly.
 * - **Polling**: The component polls every 10 seconds for incoming video calls when the user is a doctor and no video call is active.
 * - **Video Token Management**: Video token data is fetched and saved through API calls, allowing the system to track and manage video call sessions.
 * 
 * Dependencies:
 * - React (useState, useEffect, useLocation, useNavigate)
 * - Axios for API requests
 * - `VideoCallContext` for managing room ID
 * - `PersistentVideoCall` for rendering the video call interface
 * - MUI for icons and styling
 */

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useVideoCall } from './VideoCallContext';
import { BASE_URL } from '../config/apiConfig';
import PersistentVideoCall from './PersistentVideoCall';
import './Videocallcs.css';
import { useLocation, useNavigate } from 'react-router-dom';
import { Padding } from '@mui/icons-material';
const HomePage = () => {
  const [doctors, setDoctors] = useState([]);
  const [selectedDoctorId, setSelectedDoctorId] = useState('');
  const [patientId, setPatientId] = useState('');
  const [videoTokenData, setVideoTokenData] = useState(null);
  const [userRole, setUserRole] = useState('');
  const [userId, setUserId] = useState('');
  const [isVideoCallActive, setIsVideoCallActive] = useState(false);
  const [incomingCall, setIncomingCall] = useState(false);
  const { setRoomID } = useVideoCall();
  const location = useLocation();
  const navigate = useNavigate();
  useEffect(() => {
    const userRole = sessionStorage.getItem('role');
    const userId = sessionStorage.getItem('userId');
    setUserRole(userRole);
    setUserId(userId);
  }, []);
  useEffect(() => {
    const fetchDoctors = async () => {
      try {
        const response = await axios.get(`${BASE_URL}/registerDocHs/doctorss`);
        setDoctors(response.data);
      } catch (error) {
        console.error('Error fetching doctors:', error);
      }
    };
    fetchDoctors();
  }, []);
  useEffect(() => {
    const storedPatientId = sessionStorage.getItem('patientId');
    if (location.state && location.state.patientId) {
      const { patientId } = location.state;
      setPatientId(patientId);
      sessionStorage.setItem('patientId', patientId);
    } else if (storedPatientId) {
      setPatientId(storedPatientId);
    } else {
      console.error('No patient ID provided in location state or sessionStorage');
    }
  }, [location.state]);
  const fetchVideoTokenData = async () => {
    const key = sessionStorage.getItem("jwtToken");
    if (!key) {
      console.error("No JWT token found in sessionStorage");
      return;
    }
    try {
      const response = await axios.get(`${BASE_URL}/registerDocHs/getVideoToken`, {
        headers: {
          'Auth': `Bearer ${key}`,
          'Content-Type': 'application/json',
        },
      });
      if (response.data && response.data.videoToken) {
        setVideoTokenData(response.data);
        setIncomingCall(true);
      } else {
        setVideoTokenData(null);
        setIncomingCall(false);
      }
    } catch (error) {
      console.error('Error fetching video token data:', error);
      setVideoTokenData(null);
      setIncomingCall(false);
    }
  };
  // useEffect(() => {
  //   const intervalId = setInterval(() => {
  //     if (userRole === '[DOCTOR]' && !isVideoCallActive) {
  //       fetchVideoTokenData();
  //     }
  //   }, 100); // Check every 10 seconds

  //   return () => clearInterval(intervalId); // Cleanup on unmount or when video call is active
  // }, [userRole, isVideoCallActive]);
  useEffect(() => {
    const intervalId = setInterval(() => {
      if (userRole === '[DOCTOR]' && !isVideoCallActive) {
        fetchVideoTokenData();
      }
    }, 10000); // Check every 10 seconds
    return () => clearInterval(intervalId); 
  }, [userRole, isVideoCallActive]);
  const hangupVideoToken = async () => {
    const key = sessionStorage.getItem("jwtToken");
    if (!key) {
      console.error("No JWT token found in sessionStorage");
      return;
    }
    try {
      await axios.post(
        `${BASE_URL}/registerDocHs/hangup`,
        {},
        {
          headers: {
            'Auth': `Bearer ${key}`,
            'Content-Type': 'application/json',
          },
        }
      );
      console.log("Video token data deleted successfully");
    } catch (error) {
      console.error('Error deleting video token data:', error);
    }
  };
  const handleVideoCallClick = async () => {
    const roomID = userRole === '[DOCTOR]' ? userId : selectedDoctorId;
    if (roomID) {
      const videoTokenData = {
        refId: roomID,
        patientId: patientId,
        videoToken: `${window.location.protocol}//${window.location.host}/legal?roomID=${roomID}`,
      };
      await saveVideoToken(videoTokenData);
      setRoomID(roomID);
      setIsVideoCallActive(true);
    } else {
      console.error('Room ID is not available');
    }
  };
  const saveVideoToken = async (videoTokenData) => {
    const key = sessionStorage.getItem("jwtToken");
    if (!key) {
      console.error("No JWT token found in sessionStorage");
      return;
    }
    try {
      const response = await axios.post(
        `${BASE_URL}/registerDocHs/saveVideoToken`,
        videoTokenData,
        {
          headers: {
            'Auth': `Bearer ${key}`,
            'Content-Type': 'application/json',
          },
        }
      );
      console.log('Response from saveVideoToken:', response.data);
    } catch (error) {
      console.error('Error saving video token data:', error.response ? error.response.data : error.message);
    }
  };
  const handleJoinMeeting = async () => {
    await hangupVideoToken(); 
    if (videoTokenData && videoTokenData.videoToken) {
      setRoomID(videoTokenData.refId); 
      setIsVideoCallActive(true);
      navigate('/legal', {
        state: {
          patientId: videoTokenData.patientId,
          refId: videoTokenData.refId,
          videoToken: videoTokenData.videoToken
        }
      });
    } else {
      console.error("Video Token data is missing. Cannot join meeting.");
    }
  };
  const handleDeclineCall = async () => {
    await hangupVideoToken(); 
    setIncomingCall(false);   
  };
  const renderVideoCall = () => {
    return <PersistentVideoCall />;
  };
  return (
    <div className="homepage-container">
      {isVideoCallActive ? (
        renderVideoCall()
      ) : (
        <div className="content-container">
          {userRole === '[DOCTOR]' ? (
            <div>
              {incomingCall && (
                <div className="incoming-call-box">
                  <h3>Incoming Video Call</h3>
                  <button className="call-button" onClick={handleJoinMeeting}>Accept</button>
                  <button className="call-button decline" onClick={handleDeclineCall}>Decline</button>
                </div>
              )}
            </div>
          ) : (
            <div className="initiate-call-container">
              
              {doctors.length > 0 && (
                <div className="doctor-selection">
                  <select
                    value={selectedDoctorId}
                    onChange={(e) => setSelectedDoctorId(e.target.value)}
                  >
                    <option value="">Select a doctor</option>
                    {doctors.map((doctor) => (
                      <option key={doctor.id} value={doctor.id}>
                        {doctor.name}
                      </option>
                    ))}
                  </select>
                  <button className="initiate-call-button" onClick={handleVideoCallClick}>
                    Initiate Call
                  </button>
                </div>
              )}
            </div>
          )}
        </div>
      )}
    </div>
  );
};
export default HomePage;


