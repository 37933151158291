
/**
 * VideoHome.js:
 * 
 * Purpose:
 * - This component is responsible for initializing and managing the video call experience for users.
 * - It allows users to join a specific video call room using a dynamic `roomID` parameter, which is extracted
 *   from the URL via `useParams`. The component leverages the ZegoUIKitPrebuilt SDK to enable the video calling functionality.
 * 
 * Key Features:
 * - **Dynamic RoomID**: The `roomID` is fetched from the URL parameters using the `useParams` hook, allowing users
 *   to join a specific video call room based on the provided `id`.
 * - **ZegoUIKit Integration**: The component uses the ZegoUIKitPrebuilt SDK to generate a Kit Token and join a room.
 *   The SDK is configured for group calls by default, but it can be adjusted for 1-on-1 calls if needed.
 * - **Shared Links**: A personal shared link is generated, allowing users to easily share the room URL with others.
 * - **Call Initialization**: When the component is rendered, it initializes the video call by calling the `zp.joinRoom`
 *   method and passing the appropriate configuration, including the container for the video call interface and the
 *   scenario type (e.g., group call or 1-on-1 call).
 * 
 * Additional Notes:
 * - **Token Generation**: The Kit Token is generated using the appID, serverSecret, and roomID, ensuring secure access
 *   to the video call room.
 * - **Ref Usage**: The component uses a `ref` (`myMeeting`) to reference the DOM element where the video call interface
 *   will be rendered.
 * - **Customization**: The component can be customized further by modifying the `scenario` property to implement different
 *   call modes (e.g., one-on-one calls).
 */
import React from 'react';
import { useParams } from 'react-router-dom';
import { ZegoUIKitPrebuilt } from '@zegocloud/zego-uikit-prebuilt';
const VideoHome = () => {
  const { id } = useParams(); // Correctly using useParams to get the 'id' parameter
  const roomID =  id;
      let myMeeting = async (element) => {
     // generate Kit Token
      const appID = 709268713;
      const serverSecret = "5a24fd89403d07d27d7640c1c28be33d";
    //   const kitToken =  ZegoUIKitPrebuilt.generateKitTokenForTest(appID, serverSecret, roomID,  Date.now().toString(),   "o2i");
const kitToken = ZegoUIKitPrebuilt.generateKitTokenForTest(
  appID,
  serverSecret,
  roomID,
  Date.now().toString(), // Convert timestamp to string using toString()
  "o2i"
);
     // Create instance object from Kit Token.
      const zp = ZegoUIKitPrebuilt.create(kitToken);
      // start the call
      zp.joinRoom({
        container: element,
        sharedLinks: [
          {
            name: 'Personal link',
            url:
             window.location.protocol + '//' + 
             window.location.host + window.location.pathname +
              '?roomID=' +
              roomID,
          },
        ],
        scenario: {
          mode: ZegoUIKitPrebuilt.GroupCall, // To implement 1-on-1 calls, modify the parameter here to [ZegoUIKitPrebuilt.OneONoneCall].
        },
      });
    }
  return (
    <div ref={myMeeting}>
    </div>
  )
}
export default VideoHome
