//=============================Redirect Component===================================
// This component is responsible for redirecting the user to the homepage ('/') if the JWT token is not found in the session storage.
// It is used as a way to ensure that only authenticated users can access certain pages, based on the presence of the JWT token in sessionStorage.
// If the token is not present, it will automatically redirect the user to the home page.
// The component uses the `useNavigate` hook from React Router for programmatic navigation and the `useEffect` hook to perform the redirect logic when the component mounts.

import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
const RedirectComponent = () => {
  const history = useNavigate();
  useEffect(() => {
    const key = sessionStorage.getItem('jwtToken');
    if (!key) {
      history('/');
    }
  }, [history]);
  return null;
};
export default RedirectComponent;