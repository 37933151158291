//============================================Videoheader=======================================================
/**
 * Header.js:
 * 
 * Purpose:
 * - This component represents the header section of the application. It displays a navigation bar with links
 *   to various pages such as "Video Consultation," "Today's Patient List," and "Appointment History."
 * - It dynamically updates the active tab based on the current URL path, providing users with visual feedback
 *   on which page they are currently viewing.
 * 
 * Key Features:
 * - **Dynamic Active Tab**: The active navigation tab is highlighted based on the current route, ensuring
 *   that users can easily identify the active section of the app.
 * - **Responsive Navigation**: The header is built using the `Link` component from `react-router-dom` for 
 *   navigation, allowing users to switch between pages without reloading the application.
 * - **State Management**: The component uses `useState` to manage the active tab, and `useEffect` to update the
 *   active tab whenever the `location.pathname` changes, ensuring the navigation always reflects the current page.
 * 
 * Additional Notes:
 * - **Styling**: The component uses inline styles to manage the layout and appearance of the navigation tabs, 
 *   including styles for active and inactive states.
 * - **Modular Design**: This component can be easily extended with more links or sections as the application 
 *   grows, ensuring a scalable and modular navigation system.
 */

import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
function Header() {
    const location = useLocation();
    const getActiveTab = () => {
        switch (location.pathname) {
            case '/legal':
                return "Video Consultation";
            case '/drpatientlist':
                return "Today's Patient List";
            case '/drappoinment':
                return "Appointment History";
            case '/drpatientlist':
                return "Patient List";
            default:
                return "Video Consultation";
        }
    };
    const [activeTab, setActiveTab] = useState(getActiveTab);
    useEffect(() => {
        setActiveTab(getActiveTab);
    }, [location]);
    return (
        <div>
            <header style={styles.header}>  
                <Link
                    to="/legal"
                    style={activeTab === "Video Consultation" ? {...styles.tab, ...styles.active} : styles.tab}
                    onClick={() => setActiveTab("Video Consultation")}
                >
                    Video Consultation
                </Link>
                <Link
                    to="/drpatientlist"
                    style={activeTab === "Today's Patient List" ? {...styles.tab, ...styles.active} : styles.tab}
                    onClick={() => setActiveTab("Today's Patient List")}
                >
                    Today's Patient List
                </Link>
                <Link
                    to="/drappoinment"
                    style={activeTab === "Appointment History" ? {...styles.tab, ...styles.active} : styles.tab}
                    onClick={() => setActiveTab("Appointment History")}
                >
                    Appointment History
                </Link>
            </header>
        </div>
    );
}
const styles = {
    header: {
        display: 'flex',
        justifyContent: 'space-around',
        alignItems: 'center',
        backgroundColor: '#C3C3C3',
        padding: '5px 0',
    },
    tab: {
        backgroundColor: 'transparent',
        border: 'none',
        cursor: 'pointer',
        fontSize: '16px',
        padding: '10px 20px',
        transition: 'background-color 0.3s ease',
        color: 'white',
        textDecoration: 'none',
    },
    active: {
        backgroundColor: '#007bff',
        borderRadius: '5px',
    },
};
export default Header;
