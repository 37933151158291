/**
 * Purpose:
 * This component is responsible for displaying the Health Officer's patient list and appointment history.
 * It shows a list of patients along with their details such as name, email, UID, mobile, and case history.
 * The component also allows the Health Officer to view and search for specific patients and navigate to case consultation.
 * 
 * Key Features:
 * - **Patient List**: Displays a list of patients with their full name, email, UID, mobile number, and case history.
 * - **Range Selector**: Provides a dropdown to select the number of entries to display in the list.
 * - **Search Functionality**: Includes a search bar for filtering patient information.
 * - **Dynamic Data**: The patient data is currently temporary but should be fetched from an API in a real-world scenario.
 * - **Navigation**: Clicking on a patient's "Case History" takes the user to a consultation page.
 * - **Responsive Design**: The layout adapts to different screen sizes, ensuring proper display across devices.
 * - **Styled Components**: Utilizes custom CSS for visual styling, including table layouts, colors, and responsive design features.
 * 
 * Additional Notes:
 * - **Temporary Data**: The patient data is static for now, but can be replaced with dynamic data from an API.
 * - **Responsive Table**: The table includes column styles with media queries for better display on smaller screens.
 * - **Footer and Header**: Includes a consistent footer and header for layout consistency.
 */

//=======================================patientlist=======================================================
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Footer from "../../Footer";
import Header from "../../Hoheader";
const todayPatientListStyle = {
  textAlign: 'center',
  color: '#324E6A',
  fontSize: '50px',
  textShadow: '0px 0px 32.1px #BB1111', // Adding shadow with specified color and blur radius
};
const mainContainerStyle = {
  background: 'linear-gradient(180deg, #7CB2D0, #D0E0E5)', // Linear gradient background
};
const tableContainerStyle = {
  maxWidth: '1200px',
  margin: '0 auto', // Centering the table horizontally
  padding: '20px',
  backgroundColor: '#DCE7EB',
  border: '2px solid #104E8D', // Adding border with specified color
  borderBottom: '2px solid #104E8D', // Adjusted to match border color
  overflowY: 'auto', // Add vertical scrollbar if needed
  marginBottom: '10px',
  height: 'calc(100vh - 250px)', // Adjusted for scrollbar height and footer/header heights
};
const tableStyle = {
  width: '100%',
  borderCollapse: 'separate', // Change border collapse to separate
  border: '2px solid #D9D9D9',
};
const thStyle = {
  backgroundColor: '#0F60B3',
  color: 'white',
  padding: '12px 8px',
  fontWeight: 'bold',
  textAlign: 'left',
  borderBottom: '1px solid #fff', // Adding bottom border for separation
  borderRight: '2px solid #fff', // Adding vertical line to separate columns
};
const tdStyle = {
  padding: '8px',
  borderBottom: '1px solid #ddd',
  textAlign: 'left',
  borderRight: '2px solid #ddd', // Adding vertical line to separate columns
};
const lastColumnStyle = {
  borderRight: '2px solid #fff', // Adding right border to the last column
};
const columnStyles = {
  fullName: { maxWidth: '150px' }, // Adjust as needed
  email: { maxWidth: '150px' }, // Adjust as needed for smaller screens
  uid: { maxWidth: '100px' }, // Adjust as needed
  mobile: { maxWidth: '100px' }, // Adjust as needed
  caseHistory: { maxWidth: '200px' }, // Adjust as needed
};
// Define media query style
const mediaQueryStyle = `
@media screen and (max-width: 768px) {
  .email-column {
    max-width: 100px; /* Adjust as needed for smaller screens */
    white-space: nowrap; /* Prevent text wrapping */
    overflow: hidden; /* Hide overflow text */
    text-overflow: ellipsis; /* Show ellipsis for overflow text */
  }
}
`;
function AppointmentHistory() {
  const [range, setRange] = useState("1-2"); // State variable for range selection
  const navigate = useNavigate(); // Hook for navigation
  const handleRangeChange = (event) => {
    setRange(event.target.value);
  };
  const handleCaseHistoryClick = (uid) => {
    navigate(`/consultation`);
  };
  const rowHeight = 45; // Assuming each row height is 45px
  const numberOfDataRows = 10; // Number of data rows to display
  const headerHeight = 100; // Assuming the header height is 100px
  const totalRows = 2; // Total number of data rows (temporary data)
  const displayedRows = Math.max(totalRows, numberOfDataRows); // Choose maximum of totalRows and numberOfDataRows
  const containerHeight = headerHeight + rowHeight * displayedRows;
  // Temporary data
  const temporaryData = [
    { fullName: "John Doe", email: "john.doe@example.com", uid: "123452320101", mobile: "1234567890", caseHistory: "View", date: "2024-05-13" },
    { fullName: "Jane Smith", email: "jane.smith@example.com", uid: "678902586324", mobile: "9876543210", caseHistory: "View", date: "2024-05-13" },
    // Add more temporary data here
  ];
  return (
    <div style={mainContainerStyle}>
      <Header />
      <div style={todayPatientListStyle}>
        Patient List
      </div>
      <div style={{ ...tableContainerStyle, height: containerHeight, position: 'relative' }}>
        {/* Show box displaying the range of entries and search bar */}
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <span style={{ marginLeft: '23px', marginRight: '10px', color: '#324E6A' }}>Show </span>
            <select value={range} onChange={handleRangeChange} style={{ backgroundColor: '#D9D9D9', border: 'none', outline: 'none' }}>
              <option value="1-2">1-2</option>
              <option value="3-4">3-4</option>
              {/* Add more range options as needed */}
            </select>
            <span style={{ marginLeft: '15px', marginRight: '15px', color: '#324E6A' }}>entries </span>
          </div>
          {/* Search bar */}
          <span style={{ marginLeft: 'auto', marginRight: '23px', color: '#324E6A' }}>Search: </span>
          <input type="text" style={{ backgroundColor: '#D9D9D9', border: 'none', outline: 'none', borderRadius: '5px', padding: '5px' }} />
        </div>
        {/* Inject the media query style */}
        <style>{mediaQueryStyle}</style>
        <table style={tableStyle}>
          <thead>
            <tr>
              <th style={{ ...thStyle, ...columnStyles.fullName }}>Full Name</th>
              <th style={{ ...thStyle, ...columnStyles.email, maxWidth: '150px' }} className="email-column">Email</th>
              <th style={{ ...thStyle, ...columnStyles.uid }}>UID</th>
              <th style={{ ...thStyle, ...columnStyles.mobile }}>Mobile</th>
              <th style={{ ...thStyle, ...columnStyles.caseHistory, ...lastColumnStyle }}>Case History</th>
            </tr>
          </thead>
          <tbody>
            {temporaryData.map((data, index) => (
              <tr key={index} style={{ height: rowHeight, backgroundColor: index % 2 === 0 ? '#D9D9D9' : '#E6E6E6' }}>
                <td style={{ ...tdStyle, ...columnStyles.fullName }}>{data.fullName}</td>
                <td style={{ ...tdStyle, ...columnStyles.email, maxWidth: '150px' }} className="email-column">{data.email}</td>
                <td style={{ ...tdStyle, ...columnStyles.uid }}>{data.uid}</td>
                <td style={{ ...tdStyle, ...columnStyles.mobile }}>{data.mobile}</td>
                <td
                  style={{ ...tdStyle, ...columnStyles.caseHistory, ...lastColumnStyle, cursor: 'pointer', color: 'blue', textDecoration: 'underline' }}
                  onClick={() => handleCaseHistoryClick(data.uid)}
                >
                  {data.caseHistory}
                </td>
              </tr>
            ))}
            {/* Empty rows for display */}
            {[...Array(displayedRows - totalRows)].map((_, index) => (
              <tr key={index + totalRows} style={{ height: rowHeight, backgroundColor: index % 2 === 0 ? '#D9D9D9' : '#E6E6E6' }}>
                <td style={{ ...tdStyle, ...columnStyles.fullName }}></td>
                <td style={{ ...tdStyle, ...columnStyles.email }}></td>
                <td style={{ ...tdStyle, ...columnStyles.uid }}></td>
                <td style={{ ...tdStyle, ...columnStyles.mobile }}></td>
                <td style={{ ...tdStyle, ...columnStyles.caseHistory, ...lastColumnStyle }}></td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <Footer />
    </div>
  );
}
export default AppointmentHistory;
