
//=============================VideoCallAndPatientDetails Component===================================
// This component displays a video consultation interface along with patient details, case history, and appointment history.
// The user can toggle between video consultation, patient case history, and appointment history by clicking on the respective sections.
// The video consultation section includes buttons to mute/unmute the patient's microphone and turn the patient's video on/off.
// Patient details are displayed in a structured format (Name, Number, Consultation Type, etc.), and can be updated dynamically based on real data.
// The component uses React state hooks to manage the current section and control the patient's video and microphone status.

import "../Styles/Vitalsdoc.css";
import React, { useState } from 'react';
import { FaMicrophone, FaMicrophoneSlash, FaVideo, FaVideoSlash } from 'react-icons/fa';
const VideoCallAndPatientDetails = () => {
  const [patientMuted, setPatientMuted] = useState(false);
  const [patientVideoOff, setPatientVideoOff] = useState(false);
  const [activeSection, setActiveSection] = useState('videoConsultation');
  const handleSectionClick = (section) => {
    setActiveSection(section);
  };
  const patientCaseHistory = 'This is a patient case history';
  const appointmentHistory = 'This is an appointment history';
  const patientDetails = {
    name: 'John Doe',
    number: '1234567890',
    consultationType: 'General Checkup',
    date: '2024-05-05',
    caseNo: '001',
    age: '35',
    gender: 'Male',
    bloodGroup: 'O+',
  };
  return (
    <div className="container">
      <div className="row">
        <div className="col">
          <h2 onClick={() => handleSectionClick('videoConsultation')}>Video Consultation</h2>
        </div>
        <div className="col">
          <h2 onClick={() => handleSectionClick('patientCaseHistory')}>Patient Case History</h2>
        </div>
        <div className="col">
          <h2 onClick={() => handleSectionClick('appointmentHistory')}>Appointment History</h2>
        </div>
      </div>
      <div className="row">
        <div className="col">
          <button className="btn">Today Patient List</button>
          <button className="btn">Search Patient</button>
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <p><strong>Name:</strong> {patientDetails.name}</p>
          <p><strong>Number:</strong> {patientDetails.number}</p>
          <p><strong>Consultation Type:</strong> {patientDetails.consultationType}</p>
          <p><strong>Date:</strong> {patientDetails.date}</p>
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <p><strong>Case No:</strong> {patientDetails.caseNo}</p>
          <p><strong>Age:</strong> {patientDetails.age}</p>
          <p><strong>Gender:</strong> {patientDetails.gender}</p>
          <p><strong>Blood Group:</strong> {patientDetails.bloodGroup}</p>
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          {activeSection === 'videoConsultation' && (
            <div>
              <div className="video-call-interface">
                <div className="video-container">
                  {!patientVideoOff && <video className="remote-video" autoPlay muted={patientMuted}></video>}
                  <video className="local-video" autoPlay muted={patientMuted}></video>
                </div>
                <div className="call-controls">
                  <button className="btn" onClick={() => setPatientMuted(!patientMuted)}>
                    {patientMuted ? <FaMicrophoneSlash /> : <FaMicrophone />}
                  </button>
                  <button className="btn" onClick={() => setPatientVideoOff(!patientVideoOff)}>
                    {patientVideoOff ? <FaVideo /> : <FaVideoSlash />}  {patientVideoOff ? 'On' : 'Off'} Video
                  </button>
                </div>
              </div>
            </div>
          )}
          {activeSection === 'patientCaseHistory' && <div>{patientCaseHistory}</div>}
          {activeSection === 'appointmentHistory' && <div>{appointmentHistory}</div>}
        </div>
      </div>
    </div>
  );
};
export default VideoCallAndPatientDetails;
