// import React, { useEffect, useState } from 'react';
// import { useNavigate } from 'react-router-dom';
// import "../Styles/Dashdoc.css";
// import Footer from "./Footer"; 
// import doctorImage from "../Assets/docimage.png";
// import Videomessage from "../Assets/Video Message.png";
// import Timesheet from "../Assets/Timesheet.png";
// import TodayPatientList from "../Assets/TodayPatientList.png";
// import Header from "./Header";
// import { BASE_URL } from './config/apiConfig';
// import Redirect from "../Components/Redirect";
// const Dashdoc = () => {
//   const [doctorInfo, setDoctorInfo] = useState(null);
//   const navigate = useNavigate();
//   useEffect(() => {
//     fetchDoctorDetails();
//   }, []);
//  const fetchDoctorDetails = async () => {
//   try {
//     const key = sessionStorage.getItem('jwtToken');
//     if (!key) {
//       console.error('No JWT token found in sessionStorage');
//       return;
//     }
//     const response = await fetch(`${BASE_URL}/registerDocHs/get-doctor-and-healthOfficer`, {
//       headers: {
//         'Auth': `Bearer ${key}`,
//         'Content-Type': 'application/json'
//       }
//     });
//     if (!response.ok) {
//       throw new Error('Failed to fetch data');
//     }
//     const data = await response.json();
//     const capitalizedDoctorName = data.name ? data.name.charAt(0).toUpperCase() + data.name.slice(1) : '';
//     const capitalizedSpecialist = data.specialist ? data.specialist.charAt(0).toUpperCase() + data.specialist.slice(1) : '';
//     setDoctorInfo({
//       name: capitalizedDoctorName,
//       specialist: capitalizedSpecialist,
//       role: data.role
//     }); 
//   } catch (error) {
//     console.error('Error fetching doctor info:', error);
//   }
// };

//   const handleVideoConsultationClick = () => {
//     navigate('/legal');
//   };

//   const handleAppointmentHistoryClick = () => {
//     navigate('/drappoinment');
//   };
 
//   const handleCaseHistoryClick = () => {
//     navigate('/casehistory');
//   };

//   const handleTodayPatientListClick = () => {
//     navigate('/drpatientlist');
//   };
//   return (
//     <div className="dashdoc-container">
//         <Redirect />
//       <Header />
//       <div className="background-image"></div>
//       <div className="doctor-info-box">
//   <h2>Welcome Back,</h2>
//   {doctorInfo && (
//     <>
//       <h3>Dr. {doctorInfo.name || 'Name not available'}</h3> {/* Fallback text */}
//       <p>{doctorInfo.specialist || 'Specialist not available'}</p> {/* Fallback text */}
//     </>
//   )}
// </div>


//       <div className="doctor-image-container">
//         <img src={doctorImage} alt="Doctor" className="doctor-image" />
//       </div>

//       <div className="cart-container">
//         <div className="cart dark-blue-bg" onClick={handleVideoConsultationClick}>
//           <div className="icon red-icon">
//             <img src={Videomessage} alt="Video Message Icon" className="history-icon" />
//           </div>
//           <div className="content">
//             <p>Video Consultation</p>
//           </div>
//         </div>

//         <div className="cart dark-blue-bg" onClick={handleAppointmentHistoryClick}>
//           <div className="icon red-icon">
//             <img src={Timesheet} alt="Timesheet Icon" className="history-icon" />
//           </div>
//           <div className="content">
//             <p>Appointment History</p>
//           </div>
//         </div>

//         <div className="cart dark-blue-bg" onClick={handleTodayPatientListClick}>
//           <div className="icon red-icon">
//             <img src={TodayPatientList} alt="Today’s Patient List Icon" className="history-icon" />
//           </div>
//           <div className="content">
//             <p>Today’s Patient List</p>
//           </div>
//         </div>
//       </div>

//       <Footer />
//     </div>
//   );
// };

// export default Dashdoc;


//---------waris code 21 march 2025-------

import React, { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import doctorImage from "../Assets/doc_homeimg.png";
import appIcon from "../Assets/App_icon.png";
import vcIcon from "../Assets/vc_icon.png";
import listIcon from "../Assets/list_icon.png";
import Header from "./Header";
import { BASE_URL } from './config/apiConfig';
import Footer from "./Footer";
import "../Styles/Dashdoc.css";

const Dashboard = () => {
  const [doctorInfo, setDoctorInfo] = useState(null);
  const navigate = useNavigate();
  useEffect(() => {
    fetchDoctorDetails();
  }, []);
 const fetchDoctorDetails = async () => {
  try {
    const key = sessionStorage.getItem('jwtToken');
    if (!key) {
      console.error('No JWT token found in sessionStorage');
      return;
    }
    const response = await fetch(`${BASE_URL}/registerDocHs/get-doctor-and-healthOfficer`, {
      headers: {
        'Auth': `Bearer ${key}`,
        'Content-Type': 'application/json'
      }
    });
    if (!response.ok) {
      throw new Error('Failed to fetch data');
    }
    const data = await response.json();
    const capitalizedDoctorName = data.name ? data.name.charAt(0).toUpperCase() + data.name.slice(1) : '';
    const capitalizedSpecialist = data.specialist ? data.specialist.charAt(0).toUpperCase() + data.specialist.slice(1) : '';
    setDoctorInfo({
      name: capitalizedDoctorName,
      specialist: capitalizedSpecialist,
      role: data.role
    }); 
  } catch (error) {
    console.error('Error fetching doctor info:', error);
  }
 }; 

  const handleVideoConsultationClick = () => {
    navigate("/legal");
  };

  const handleAppointmentHistoryClick = () => {
    navigate("/drappoinment");
  };

  const handleTodayPatientListClick = () => {
    navigate("/drpatientlist");
  };

  return (
    <>
      <Header />
      <div className="dashboard-container">
        <div className="header-section">
          <div className="doctor-info">
            <h2 className="welcome-text">
              Welcome Back | Doctor <br />
              <span className="doctor-name">
              {doctorInfo ? `${doctorInfo.name}` : "Loading..."}
              </span>
            </h2>
            <p className="tagline">Your smile, our priority</p>
          </div>

          <div className="doctor-image-container">
            <img className="doctor-image" src={doctorImage} alt="Doctor" />
          </div>

          <div className="appointment-card">
            <h3>Appointment</h3>
            <p>Today's Number Of Patients</p>
            <h1 className="appointment-number">20</h1>
          </div>
        </div>

        {/* Action Buttons with Icons on Top */}
        <div className="action-buttons">
          <div className="action-button" onClick={handleVideoConsultationClick}>
            <img className="iconhomedr" src={vcIcon} alt="Video Icon" />
            VIDEO CONSULTATION
          </div>
          <div className="action-button" onClick={handleAppointmentHistoryClick}>
            <img className="iconhomedr" src={appIcon} alt="Appointment Icon" />
            APPOINTMENT HISTORY
          </div>
          <div className="action-button" onClick={handleTodayPatientListClick}>
            <img className="iconhomedr" src={listIcon} alt="List Icon" />
            TODAY’S PATIENT LIST
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Dashboard;
