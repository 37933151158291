/**
 * Purpose:
 * This component visualizes the patient registration data through charts. 
 * It fetches the registration data from the backend API, processes it, and renders it in a graphical format 
 * using Chart.js. The chart provides insights into the distribution of patient registrations over time or by categories.
 *
 * Key Features:
 * - **Data Fetching**: Retrieves patient registration data from the backend API for chart visualization.
 * - **Chart Rendering**: Displays the data using interactive, responsive charts via the `react-chartjs-2` library.
 * - **Data Processing**: Processes the raw registration data into a suitable format for the chart display.
 * - **Error Handling**: Handles potential errors in fetching data and provides feedback to the user using `react-toastify`.
 * - **Real-time Updates**: Updates the chart dynamically when new data is received or changes occur.
 * - **User Interaction**: Supports interactions like tooltips and hover effects for detailed data insights.
 * - **Responsive Design**: Ensures the chart is fully responsive, adapting to different screen sizes and devices.
 * - **Navigation**: Includes easy navigation options to switch between different chart views or data categories.
 */

import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserMd, faUser, faUserPlus, faUserShield } from '@fortawesome/free-solid-svg-icons';
import { Line, Pie } from 'react-chartjs-2';
import { Chart as ChartJS, Title as ChartTitle, Tooltip, Legend, LineElement, CategoryScale, LinearScale, ArcElement } from 'chart.js';
import Sidebar from './Sidebar'; 
import Header from './Header'; 
import Patientlist from './patientlist';
import { BASE_URL } from '../config/apiConfig';
import Redirect from "../../Components/Redirect"; 
// Registering chart.js components
ChartJS.register(ChartTitle, Tooltip, Legend, LineElement, CategoryScale, LinearScale, ArcElement);
//=============================== CSS Styled Components =============================
const DaMwtr = styled.div`
 margin-top: 10px;
`;
const Card = styled.div`
  max-width: 100%;
  margin: 20px 0;
  padding: 20px;
  height: 400px; 
  overflow-y: auto; 
  background-color: #fff;
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.3);
`;
const SidebarStyled = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 250px;
  overflow-y: auto;
  z-index: 1000;
`;
const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
`;
const ChartWrapper = styled.div`
  height: 250px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
`;
const ChartContainer = styled.div`
  flex: 1 1 calc(50% - 20px);
  // max-width: 500px;
  padding: 20px;
  background-color: #fff;
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.3);
  margin-top: 20px;
  height: 350px;
  @media (max-width: 768px) {
    flex: 1 1 100%;
  }
`;
const BaseItem = styled.div`
  position: relative;
  flex: 1 1 200px;
  display: flex;
  align-items: center;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  height: 100px;
  background-color: #f9f9f9;
`;
const CountWrapper = styled.div`
  position: absolute;
  bottom: 10px;
  left: 10px;
  font-size: 24px;
  font-weight: bold;
  color: #333;
`;
const IconWrapper = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
  display: flex;
  align-items: center;
`;
const Text = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
  margin-right: 50px;
  text-align: left;
`;
const Layout = styled.div`
  display: flex;
  background-color: #E0FFFF;
  min-height: 100vh;
  margin-left: 250px;
  padding: 20px;
  overflow-y: auto;
`;
const ChartTitleStyled = styled.div`
  margin-bottom: 20px;
  font-size: 16px;
`;
const Count = styled.span`
  font-size: 24px;
  font-weight: bold;
  margin-left: 10px;
`;
const YellowItem = styled(BaseItem)`
  background-color: rgba(218, 165, 32, 0.2);
`;
const GreenItem = styled(BaseItem)`
  background-color: #C8E6C9;
`;
const RedItem = styled(BaseItem)`
  background-color: #FFCDD2;
`;

const BlueItem = styled(BaseItem)`
  background-color: #E3F2FD;
`;
const ResponsiveContainer = styled(Container)`
  @media (max-width: 1200px) {
    justify-content: space-around;
  }
  @media (max-width: 800px) {
    justify-content: center;
  }
`;
const Content = styled.div`
  flex: 1;
  padding: 0;
`;
const HeaderContainer = styled.div`
  margin: 0;
`;
const Icon = styled(FontAwesomeIcon)`
  font-size: 24px;
`;
const PatientRegistrationChart = ({ period }) => {
  const [data, setData] = useState({
    months: [],
    days: [],
    monthlyMaleCounts: [],
    monthlyFemaleCounts: [],
    dailyMaleCounts: [],
    dailyFemaleCounts: [],
  });
  const [jwtToken, setJwtToken] = useState(sessionStorage.getItem('jwtToken'));
   // Fetch data from API for patient registrations based on the selected period (months/days)
  useEffect(() => {
    const fetchData = async () => {
      if (!jwtToken) {
        console.error('No JWT token available for authentication');
        return;
      }
      try {
        const response = await fetch(`${BASE_URL}/user/patient/registrations?type=${period}`, {
          method: 'GET',
          headers: {
            'Auth': `Bearer ${jwtToken}`,
          },
        });
        const result = await response.json();
        if (period === 'months') {
          const labels = Object.keys(result);
          const maleCounts = labels.map(month => result[month].Male);
          const femaleCounts = labels.map(month => result[month].Female);
          setData({
            months: labels,
            days: [], 
            monthlyMaleCounts: maleCounts,
            monthlyFemaleCounts: femaleCounts,
            dailyMaleCounts: [], 
            dailyFemaleCounts: [], 
          });
        } else if (period === 'days') {
          const labels = Object.keys(result);
          const maleCounts = labels.map(day => result[day].Male);
          const femaleCounts = labels.map(day => result[day].Female);
          setData({
            months: [], 
            days: labels,
            monthlyMaleCounts: [], 
            monthlyFemaleCounts: [], 
            dailyMaleCounts: maleCounts,
            dailyFemaleCounts: femaleCounts,
          });
        }
      } catch (error) {
        console.error('Error fetching patient registrations:', error);
      }
    };
    fetchData();
  }, [period, jwtToken]);
   // Chart data configuration based on fetched data
  const chartData = {
    labels: period === 'months' ? data.months : data.days,
    datasets: [
      {
        label: 'Male',
        data: period === 'months' ? data.monthlyMaleCounts : data.dailyMaleCounts,
        borderColor: 'rgba(75, 192, 192, 1)',
        backgroundColor: 'rgba(75, 192, 192, 0.2)',
        borderWidth: 1,
      },
      {
        label: 'Female',
        data: period === 'months' ? data.monthlyFemaleCounts : data.dailyFemaleCounts,
        borderColor: 'rgba(255, 99, 132, 1)',
        backgroundColor: 'rgba(255, 99, 132, 0.2)',
        borderWidth: 1,
      },
    ],
  };
  const options = {
    responsive: true,
    scales: {
      x: {
        title: {
          display: true,
          //=========================== graph text===================================
          // text: period === 'months' ? 'Month' : 'Day',
        },
      },
      y: {
        title: {
          display: true,
          text: 'Number of Patients',
        },
      },
    },
  };
  return <ChartWrapper><Line data={chartData} options={options} /></ChartWrapper>;
};
// GenderPieChart Component to display pie chart of gender-wise appointments
const GenderPieChart = ({ data }) => {
  const chartData = {
    labels: ['Male', 'Female'],
    datasets: [
      {
        data: [data.totalMaleAppointments, data.totalFemaleAppointments],
        backgroundColor: ['rgba(75, 192, 192, 0.6)', 'rgba(255, 99, 132, 0.6)'],
        borderColor: ['rgba(75, 192, 192, 1)', 'rgba(255, 99, 132, 1)'],
        borderWidth: 1,
      },
    ],
  };
  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      tooltip: {
        callbacks: {
          label: function (context) {
            let label = context.label || '';
            if (label) {
              label += ': ';
            }
            if (context.parsed !== null) {
              label += context.parsed + ' appointments';
            }
            return label;
          },
        },
      },
    },
  };
  return (
    <ChartWrapper>
      <Pie data={chartData} options={options} />
    </ChartWrapper>
  );
};
// TotalCounts Component for displaying the total counts of doctors, patients, health officers, etc.
const TotalCounts = () => {
  const [doctorCount, setDoctorCount] = useState(2);
  const [patientCount, setPatientCount] = useState(5);
  const [newPatientCount, setNewPatientCount] = useState(5);
  const [healthOfficerCount, setHealthOfficerCount] = useState(1);
  const [data, setData] = useState({
    totalMaleAppointments: 0,
    totalFemaleAppointments: 0,
  });
  const [period, setPeriod] = useState('months');
  const jwtToken = sessionStorage.getItem('jwtToken');
 // Fetch counts data from backend
  useEffect(() => {
    if (jwtToken) {
      const fetchGenderCounts = async () => {
        try {
          const response = await fetch(`${BASE_URL}/user/genderwise-count`, {
            method: 'GET',
            headers: {
              'Auth': `Bearer ${jwtToken}`
            },
          });
          const result = await response.json();
          setData({
            totalMaleAppointments: result.maleCount,
            totalFemaleAppointments: result.femaleCount,
          });
        } catch (error) {
          console.error('Error fetching gender-wise counts:', error);
        }
      };
      const fetchCounts = async () => {
        try {
          const response = await fetch(`${BASE_URL}/user/count`, {
            method: 'GET',
            headers: {
              'Auth': `Bearer ${jwtToken}`
            },
          });
          const result = await response.json();
          setDoctorCount(result.doctorCount);
          setPatientCount(result.patientCount);
          setNewPatientCount(result.newPatientCount);
          setHealthOfficerCount(result.healthOfficerCount);
        } catch (error) {
          console.error('Error fetching counts:', error);
        }
      };
      fetchGenderCounts();
      fetchCounts();
    } else {
      console.error('No JWT token available for authentication');
    }
  }, [jwtToken]);
  return (
    <Layout>
        <Redirect />
      <SidebarStyled>
        <Sidebar />
      </SidebarStyled>
      <Content>
        <HeaderContainer>
          <Header />
        </HeaderContainer>
        <DaMwtr>
        <ResponsiveContainer>
          <BlueItem>
            <Text>Total Doctors</Text>
            <IconWrapper>
              <Icon icon={faUserMd} />
            </IconWrapper>
            <CountWrapper>
              <Count>{doctorCount}</Count>
            </CountWrapper>
          </BlueItem>
          <YellowItem>
            <Text>Total Patients</Text>
            <IconWrapper>
              <Icon icon={faUser} />
            </IconWrapper>
            <CountWrapper>
              <Count>{patientCount}</Count>
            </CountWrapper>
          </YellowItem>
          <GreenItem>
            <Text>New Patients</Text>
            <IconWrapper>
              <Icon icon={faUserPlus} />
            </IconWrapper>
            <CountWrapper>
              <Count>{newPatientCount}</Count>
            </CountWrapper>
          </GreenItem>
          <RedItem>
            <Text>Total Health Officers</Text>
            <IconWrapper>
              <Icon icon={faUserShield} />
            </IconWrapper>
            <CountWrapper>
              <Count>{healthOfficerCount}</Count>
            </CountWrapper>
          </RedItem>
        </ResponsiveContainer>
        </DaMwtr>
        <Container>
          <ChartContainer>
            <ChartTitleStyled>Patient Registration Counts</ChartTitleStyled>
            <div>
              <button onClick={() => setPeriod('days')}>Days</button>
              <button onClick={() => setPeriod('months')}>Monthly</button>
            </div>
            <PatientRegistrationChart period={period} />
          </ChartContainer>
          <ChartContainer>
            <ChartTitleStyled>Gender-wise Appointment Distribution</ChartTitleStyled>
            <GenderPieChart data={data} />
          </ChartContainer>
        </Container>
        <Card>
          <Patientlist />
        </Card>
      </Content>
    </Layout>
  );
};
export default TotalCounts;