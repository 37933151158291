
// import React, { useEffect, useState } from 'react';
// import { useNavigate } from 'react-router-dom';
// import Footer from '../../Footer';
// import doctorImage from '../../../Assets/docimage.png';
// import Consultation from "../../../Assets/Consultation.png";
// import Videomessage from "../../../Assets/Video Message.png";
// import Timesheet from "../../../Assets/Timesheet.png";
// import TodayPatientList from "../../../Assets/TodayPatientList.png";
// import Header from "../../Hoheader";
// import { BASE_URL } from '../../config/apiConfig';
// import Redirect from "../../../Components/Redirect";
// const Dashdoc = () => {
//   const [doctorName, setDoctorName] = useState("");
//   const [doctorSpecialist, setDoctorSpecialist] = useState("");
//   const [doctorRole, setDoctorRole] = useState("");
//   const [totalAppointmentsToday, setTotalAppointmentsToday] = useState(0);
//   const [doctorInfo, setDoctorInfo] = useState(null);
//   const navigate = useNavigate();
//   useEffect(() => {
//     const fetchDoctorDetails = async () => {
//       try {
//         const key = sessionStorage.getItem('jwtToken');
//         if (!key) {
//           console.error('No JWT token found in sessionStorage');
//           return;
//         }
//         const response = await fetch(`${BASE_URL}/registerDocHs/get-doctor-and-healthOfficer`, {
//           headers: {
//             'Auth': `Bearer ${key}`,
//             'Content-Type': 'application/json'
//           }
//         });
//         if (!response.ok) {
//           throw new Error('Failed to fetch data');
//         }
//         const data = await response.json();
//         const capitalizedDoctorName = data.name ? data.name.charAt(0).toUpperCase() + data.name.slice(1) : '';
//         const capitalizedSpecialist = data.specialist ? data.specialist.charAt(0).toUpperCase() + data.specialist.slice(1) : '';
//         setDoctorInfo({
//           name: capitalizedDoctorName,
//           specialist: capitalizedSpecialist,
//           role: data.role
//         });
//       } catch (error) {
//         console.error('Error fetching doctor info:', error);
//       }
//     };
//     fetchDoctorDetails();
//   }, []);
//   const handleVideoConsultationClick = () => {
//     navigate('/hovideo');
//   };
//   const handleAppointmentHistoryClick = () => {
//     navigate('/appointment');
//   };
//   const handleCaseHistoryClick = () => {
//     navigate('/casehistory');
//   };
//   const handlePreviousConsultationClick = () => {
//     navigate('/preconsulting');
//   };
//   const handleTodayPatientListClick = () => {
//     navigate('/list');
//   };
//   return (
//     <div className="dashdoc-container">
//       <Redirect />
//       <Header />
//       <div className="background-image"></div>
//       <div className="doctor-info-box">
//         <h2>Welcome Back,</h2>
//         {doctorInfo && (
//           <>
//             <h3>HealthOfficer. {doctorInfo.name || 'Name not available'}</h3> {}
//             <p>{doctorInfo.specialist || 'Specialist not available'}</p> {}
//           </>
//         )}
//         {}
//         <p>You have assigned a total of {totalAppointmentsToday} appointments today!</p>
//       </div>
//       <div className="doctor-image-container">
//         <img src={doctorImage} alt="Doctor" className="doctor-image" />
//       </div>
//       <div className="cart-container">
//         <div className="cart dark-blue-bg" onClick={handlePreviousConsultationClick}>
//           <div className="icon red-icon">
//             <img src={Consultation} alt="Video Message Icon" className="history-icon" />
//           </div>
//           <div className="content">
//             <p>Pre-Consultation</p>
//           </div>
//         </div>
//         <div className="cart dark-blue-bg" onClick={handleTodayPatientListClick}>
//           <div className="icon red-icon">
//             <img src={TodayPatientList} alt="History Icon" className="history-icon" />
//           </div>
//           <div className="content">
//             <p>Today’s Patient List</p>
//           </div>
//         </div>
//         <div className="cart dark-blue-bg" onClick={handleVideoConsultationClick}>
//           <div className="icon red-icon">
//             <img src={Videomessage} alt="Video Message Icon" className="history-icon" />
//           </div>
//           <div className="content">
//             <p>Video Consultation</p>
//           </div>
//         </div>
//         <div className="cart dark-blue-bg" onClick={handleAppointmentHistoryClick}>
//           <div className="icon red-icon">
//             <img src={Timesheet} alt="Timesheet Icon" className="history-icon" />
//           </div>
//           <div className="content">
//             <p>Appointment History</p>
//           </div>
//         </div>
//       </div>
//       <Footer />
//     </div>
//   );
// };
// export default Dashdoc;


// --------- waris 22 mar 2025------------
import React, { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import hoImage from "../../../Assets/ho_homeimg.png";
import appIcon from "../../../Assets/App_icon.png";
import vcIcon from "../../../Assets/vc_icon.png";
import listIcon from "../../../Assets/list_icon.png";
import preIcon from "../../../Assets/pre_icon.png";
import Footer from '../../Footer';
import { BASE_URL } from '../../config/apiConfig';
import Header from "../../Hoheader";


const Dashboard = () => {
  const [doctorInfo, setDoctorInfo] = useState(null);
  const navigate = useNavigate();
  useEffect(() => {
    fetchDoctorDetails();
  }, []);
 const fetchDoctorDetails = async () => {
  try {
    const key = sessionStorage.getItem('jwtToken');
    if (!key) {
      console.error('No JWT token found in sessionStorage');
      return;
    }
    const response = await fetch(`${BASE_URL}/registerDocHs/get-doctor-and-healthOfficer`, {
      headers: {
        'Auth': `Bearer ${key}`,
        'Content-Type': 'application/json'
      }
    });
    if (!response.ok) {
      throw new Error('Failed to fetch data');
    }
    const data = await response.json();
    const capitalizedDoctorName = data.name ? data.name.charAt(0).toUpperCase() + data.name.slice(1) : '';
    const capitalizedSpecialist = data.specialist ? data.specialist.charAt(0).toUpperCase() + data.specialist.slice(1) : '';
    setDoctorInfo({
      name: capitalizedDoctorName,
      specialist: capitalizedSpecialist,
      role: data.role
    }); 
  } catch (error) {
    console.error('Error fetching health officer:', error);
  }
 }; 

  const handleVideoConsultationClick = () => {
    navigate("/hovideo");
  };

  const handleAppointmentHistoryClick = () => {
    navigate("/appointment");
  };

  const handleTodayPatientListClick = () => {
    navigate("/list");
  };

  const handleToPreConsultationClick = () => {
    navigate("/preconsulting");
  };

  return (
    <>
      <Header />
      <div className="dashboard-container">
        <div className="header-section">
          <div className="doctor-info">
            <h2 className="welcome-text">
              Welcome Back | HealthOfficer <br />
              <span className="healthofficer-name">
              {doctorInfo ? `${doctorInfo.name}` : "Loading..."}
              </span>
            </h2>
            <p className="tagline">Your smile, our priority</p>
          </div>

          <div className="doctor-image-container">
            <img className="doctor-image" src={hoImage} alt="Health officer" />
          </div>

          <div className="appointment-card">
            <h3>Appointment</h3>
            <p>Today's Number Of Patients</p>
            <h1 className="appointment-number">20</h1>
          </div>
        </div>

        {/* Action Buttons with Icons on Top */}
        <div className="action-buttons">
          <div className="action-button" onClick={handleVideoConsultationClick}>
            <img className="iconhomedr" src={vcIcon} alt="Video Icon" />
            VIDEO CONSULTATION
          </div>
          <div className="action-button" onClick={handleAppointmentHistoryClick}>
            <img className="iconhomedr" src={appIcon} alt="Appointment Icon" />
            APPOINTMENT HISTORY
          </div>
          <div className="action-button" onClick={handleTodayPatientListClick}>
            <img className="iconhomedr" src={listIcon} alt="List Icon" />
            TODAY’S PATIENT LIST
          </div>
          <div className="action-button" onClick={handleToPreConsultationClick}>
            <img className="iconhomedr" src={preIcon} alt="pre Icon" />
            PRE-CONSULTATION
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Dashboard;
