/**
 * Purpose:
 * This component renders the login page for admins, allowing users to log in as patients, doctors, health officers, 
 * or admins. It manages the login flow, including handling OTP-based authentication for patients and standard 
 * email/password login for other users. The component also manages form submission, error handling, and user session 
 * management using sessionStorage.
 * 
 * Key Features:
 * - **Dynamic User Type Selection**: Allows users to choose their role (patient, doctor, health officer, admin) 
 *   before logging in, with specific fields displayed based on the selected role.
 * - **OTP Flow for Patients**: Supports OTP-based login for patients, where users can request an OTP to be sent to 
 *   their mobile number, and then verify the OTP to complete the login.
 * - **Email/Password Flow for Other Roles**: Users can log in with email and password for roles other than patient.
 * - **Session Storage**: Login credentials and JWT tokens are stored in `sessionStorage`, and users can choose to 
 *   remember their login details for future sessions.
 * - **Navigation Based on User Role**: After a successful login, the component redirects users to appropriate dashboards 
 *   based on their role (doctor, health officer, admin).
 * 
 * Additional Notes:
 * - **Error Handling**: The component handles errors such as invalid OTP and failed login attempts, providing feedback 
 *   to the user in case of issues.
 * - **Responsive Design**: The page has a flexible layout with a gradient background and styled login form, ensuring 
 *   that it adapts well to various screen sizes.
 */

//============================= Login Page ===================================================



// import React, { useState, useEffect } from 'react';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faUser, faLock, faChevronDown, faMobileAlt } from '@fortawesome/free-solid-svg-icons';
// import axios from 'axios';
// import { useNavigate } from 'react-router-dom';
// import logo from "../../../Assets/O2I_LOGO.png";
// import { BASE_URL } from '../../config/apiConfig';
// import { Link } from 'react-router-dom';
// import Footer from '../../Visitorpage/Visitorfooter.js';

// const styles = `
// .admin-login-container {
//   background: linear-gradient(#7CB2D0, #D0E0E5);
//   min-height: 100vh;
//   display: flex;
//   flex-direction: column;
//   align-items: center;
//   justify-content: center;
//   position: relative; /* Ensures that footer can be positioned absolutely within this container */
// }

// .login-form-container {
//   width: 80%;
//   max-width: 500px;
//   padding: 60px;
//   border-radius: 5px;
//   border: 2px solid #0F60B3;
//   background-color: transparent;
//   box-shadow: 0 2px 5px rgba(255, 0, 0, 0.3);
//   margin-bottom: 80px; /* Ensure there's enough space for the footer */
// }

// .login-form h2 {
//   font-size: 24px;
//   text-align: center;
//   margin-bottom: 20px;
// }

// .form-group {
//   display: flex;
//   align-items: center;
//   margin-bottom: 15px;
// }

// .form-group label {
//   font-weight: bold;
//   margin-right: 10px;
// }

// .form-group input, .form-group select {
//   padding: 8px;
//   border: none;
//   border-bottom: 2px solid black;
//   width: calc(100% - 30px);
//   background: rgba(255, 255, 255, 0.3);
//   color: gray;
// }

// .icon {
//   margin-right: 10px;
//   font-size: 22px;
// }

// .error-message {
//   color: darkblue;
//   margin-top: 10px;
// }

// button {
//   padding: 10px 20px;
//   background-color: #007bff;
//   color: white;
//   border: none;
//   border-radius: 3px;
//   cursor: pointer;
//   margin-top: 20px;
//   margin-left: auto;
//   margin-right: 5px;
// }

// button:hover {
//   background-color: #0056b3;
// }

// .heading-container {
//   margin-bottom: 20px;
//   text-shadow: 2px 2px 4px rgba(255, 0, 0, 0.5);
// }

// .footer {
//   position: absolute;
//   bottom: 0;
//   width: 100%;
//   background: #007bff;
//   color: white;
//   text-align: center;
//   padding: 10px 0;
// }

// `;
// const styleTag = document.createElement('style');
// styleTag.type = 'text/css';
// styleTag.appendChild(document.createTextNode(styles));
// const AdminLogin = () => {
//   const [email, setEmail] = useState(sessionStorage.getItem('savedEmail') || '');
//   const [password, setPassword] = useState(sessionStorage.getItem('savedPassword') || '');
//   const [mobileNumber, setMobileNumber] = useState('');
//   const [otp, setOtp] = useState('');
//   const [rememberMe, setRememberMe] = useState(sessionStorage.getItem('rememberMe') === 'true');
//   const [error, setError] = useState('');
//   const [selectedUserType, setSelectedUserType] = useState('patient'); // Default to patient
//   const [isOtpSent, setIsOtpSent] = useState(false);
//   const navigate = useNavigate();
//   useEffect(() => {
//     const rememberMeValue = sessionStorage.getItem('rememberMe') === 'true';
//     setRememberMe(rememberMeValue);
//   }, []);
//   const getJwtToken = () => {
//     const tokenCookie = document.cookie.split('; ').find(cookie => cookie.startsWith('jwt='));
//     return tokenCookie ? tokenCookie.split('=')[1] : null;
//   };
//   const handleInputChange = (event) => {
//     const { id, value } = event.target;
//     if (id === 'email') {
//       setEmail(value);
//     } else if (id === 'password') {
//       setPassword(value);
//     } else if (id === 'mobileNumber') {
//       setMobileNumber(value);
//     } else if (id === 'otp') {
//       setOtp(value);
//     }
//   };
//   const handleUserTypeChange = (event) => {
//     setSelectedUserType(event.target.value);
//   };
//   const handleSendOtp = async (event) => {
//     event.preventDefault();
//     try {
//       const response = await axios.post(`${BASE_URL}/api/patients/send-otp?mobileNo=${mobileNumber}`, {}, {
//         headers: {
//           'Content-Type': 'application/json'
//         }
//       });
//       console.log('OTP sent successfully:', response.data);
//       setIsOtpSent(true);
//     } catch (error) {
//       console.error('Error sending OTP:', error);
//       setError('Error sending OTP. Please try again.');
//     }
//   };
//   const handleVerifyOtp = async () => {
//     try {
//       const response = await axios.post(
//         `${BASE_URL}/api/patients/verify-otp?mobileNo=${mobileNumber}&otp=${otp}`,
//         {},
//         {
//           headers: {
//             'Content-Type': 'application/json'
//           }
//         }
//       );
//       console.log('OTP verified successfully:', response.data);
//       const { jwtToken, role } = response.data;
//       sessionStorage.setItem('jwtToken', jwtToken);
//       sessionStorage.setItem('role', role);
//       navigate('/userdashboard');
//     } catch (error) {
//       console.error('Error verifying OTP:', error);
//       setError('OTP verification failed. Please try again.');
//     }
//   };
//   const handleSubmit = async (event) => {
//     event.preventDefault();
//     try {
//       let requestBody = {
//         userType: selectedUserType,
//       };
//       if (selectedUserType === 'patient') {
//         requestBody = {
//           ...requestBody,
//           mobile: mobileNumber,
//           otp: otp,
//         };
//         await handleVerifyOtp();
//       } else {
//         requestBody = {
//           ...requestBody,
//           email: email,
//           password: password,
//         };
//         const response = await fetch(`${BASE_URL}/user/login`, {
//           method: 'POST',
//           headers: {
//             'Content-Type': 'application/json',
//           },
//           body: JSON.stringify(requestBody),
//         });

//         if (!response.ok) {
//           throw new Error('Invalid credentials');
//         }
//         const data = await response.json();
//         console.log('Response Data:', data);
//         sessionStorage.setItem('jwtToken', data.jwtToken);
//         sessionStorage.setItem('role', data.role);
//         sessionStorage.setItem('userId', data.userId); 
//         const jwtToken = getJwtToken();
//         console.log('JWT Token:', jwtToken);
//         sessionStorage.setItem('rememberMe', rememberMe.toString());
//         if (rememberMe) {
//           sessionStorage.setItem('savedEmail', email);
//           sessionStorage.setItem('savedPassword', password);
//         } else {
//           sessionStorage.removeItem('savedEmail');
//           sessionStorage.removeItem('savedPassword');
//         }
//         const userRole = data.role;
//         if (userRole.includes('DOCTOR')) {
//           navigate('/dashdoc');
//         } else if (userRole.includes('HEALTH_OFFICER')) {
//           navigate('/dashboard');
//         } else if (userRole.includes('SUB_ADMIN')) {
//           navigate('/admindashboard');
//         } else {
//           navigate('/admindashboard');
//         }
//       }
//     } catch (error) {
//       console.error('Error:', error.message);
//       setError('Login failed. Please check your credentials and try again.');
//     }
//   };
//   useEffect(() => {
//     document.head.appendChild(styleTag);
//     return () => {
//       document.head.removeChild(styleTag);
//     };
//   }, []);
//   return (
//     <div>
//       <div className="header">
//         <div className="header-text">
//           <img src={logo} alt="Oxygen 2 Innovation" className="logo-image" />
//         </div>
//         <div className="buttons-container">
//           <Link to="/registerhospital" className="login-btn">Login/Register</Link>
//         </div>
//       </div>
//       <div className="admin-login-container">
//         <div className="heading-container">
//           <h1>Admin Login</h1>
//         </div>
//         <div className="login-form-container">
//           <div className="form-group">
//             <label htmlFor="userType"><FontAwesomeIcon icon={faChevronDown} className="icon" /></label>
//             <select
//               id="userType"
//               value={selectedUserType}
//               onChange={handleUserTypeChange}
//               required
//             >
//               <option value="patient">Patient</option>
//               <option value="doctor">Doctor</option>
//               <option value="health_officer">Health Officer</option>
//               <option value="admin">Hospital</option>
//             </select>
//           </div>
//           <form onSubmit={handleSubmit}>
//             {selectedUserType === 'patient' ? (
//               <>
//                 <div className="form-group">
//                   <label htmlFor="mobileNumber"><FontAwesomeIcon icon={faMobileAlt} className="icon" /></label>
//                   <input
//                     type="text"
//                     id="mobileNumber"
//                     value={mobileNumber}
//                     onChange={handleInputChange}
//                     placeholder="Mobile Number"
//                     required
//                   />
//                 </div>
//                 {isOtpSent ? (
//                   <div className="form-group">
//                     <label htmlFor="otp"><FontAwesomeIcon icon={faLock} className="icon" /></label>
//                     <input
//                       type="password"
//                       id="otp"
//                       value={otp}
//                       onChange={handleInputChange}
//                       placeholder="OTP"
//                       required
//                     />
//                   </div>
//                 ) : null}
//                 <button type="button" onClick={handleSendOtp}>
//                   Send OTP
//                 </button>
//                 {isOtpSent && (
//                   <button type="submit">
//                     Verify OTP and Login
//                   </button>
//                 )}
//               </>
//             ) : (
//               <>
//                 <div className="form-group">
//                   <label htmlFor="email"><FontAwesomeIcon icon={faUser} className="icon" /></label>
//                   <input
//                     type="text"
//                     id="email"
//                     value={email}
//                     onChange={handleInputChange}
//                     placeholder="Email"
//                     required
//                   />
//                 </div>
//                 <div className="form-group">
//                   <label htmlFor="password"><FontAwesomeIcon icon={faLock} className="icon" /></label>
//                   <input
//                     type="password"
//                     id="password"
//                     value={password}
//                     onChange={handleInputChange}
//                     placeholder="Password"
//                     required
//                   />
//                 </div>
            
//                 <button type="submit">
//                   Login
//                 </button>
//               </>
//             )}
//           </form>
//           {error && <p className="error-message">{error}</p>}
//         </div>
//       </div>
//       <Footer />
//     </div>
//   );
// };
// export default AdminLogin;


// -----------25 march waris code----------

import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faLock, faChevronDown, faMobileAlt } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import logo from "../../../Assets/O2I_LOGO.png";
import { BASE_URL } from '../../config/apiConfig';
import { Link } from 'react-router-dom';
import Footer from '../../Visitorpage/Visitorfooter.js';
import bgImage from "../../../Assets/loginbg.png";

const styles = `
.admin-login-container {
  background: url(${bgImage}) no-repeat center center fixed;
  background-size: cover;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
}

.login-form-container {
  width: 80%;
  max-width: 500px;
  padding: 60px;
  border-radius: 5px;
  border: 2px solid rgb(52, 138, 209);
  background-color: rgb(52, 138, 209);
  margin-bottom: 20px;
}

.login-form {
  width: 100%;
}

.login-form h2 {
  font-size: 24px;
  text-align: center;
  margin-bottom: 20px;
}

.form-group {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

.form-group label {
  font-weight: bold;
  margin-right: 10px;
}

.form-group input, .form-group select {
  padding: 8px;
  border: none;
  border-bottom: 2px solid black;
  width: calc(100% - 30px);
  background: rgba(255, 255, 255);
  color: gray;
}

.icon {
  margin-right: 10px;
  font-size: 22px;
}

.error-message {
  color: darkblue;
  margin-top: 10px;
}

.action-button-container {
  display: flex;
  justify-content: center;
  margin-top: 20px;
  width: 80%;
  max-width: 500px;
  gap: 10px;
}

.action-button {
  padding: 10px 20px;
  background-color: rgb(0, 86, 179);
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  width: 50%;
  height: max-content;
}

.action-button:hover {
  background-color: rgb(52, 138, 209);
}

.heading-container {
  margin-bottom: 0px;
  color: rgb(0, 86, 179);
}

.footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  background: #007bff;
  color: white;
  text-align: center;
  padding: 10px 0;
}

`;
const styleTag = document.createElement('style');
styleTag.type = 'text/css';
styleTag.appendChild(document.createTextNode(styles));

const AdminLogin = () => {
  const [email, setEmail] = useState(sessionStorage.getItem('savedEmail') || '');
  const [password, setPassword] = useState(sessionStorage.getItem('savedPassword') || '');
  const [mobileNumber, setMobileNumber] = useState('');
  const [otp, setOtp] = useState('');
  const [rememberMe, setRememberMe] = useState(sessionStorage.getItem('rememberMe') === 'true');
  const [error, setError] = useState('');
  const [selectedUserType, setSelectedUserType] = useState('patient');
  const [isOtpSent, setIsOtpSent] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const rememberMeValue = sessionStorage.getItem('rememberMe') === 'true';
    setRememberMe(rememberMeValue);
  }, []);

  const getJwtToken = () => {
    const tokenCookie = document.cookie.split('; ').find(cookie => cookie.startsWith('jwt='));
    return tokenCookie ? tokenCookie.split('=')[1] : null;
  };

  const handleInputChange = (event) => {
    const { id, value } = event.target;
    if (id === 'email') {
      setEmail(value);
    } else if (id === 'password') {
      setPassword(value);
    } else if (id === 'mobileNumber') {
      setMobileNumber(value);
    } else if (id === 'otp') {
      setOtp(value);
    }
  };

  const handleUserTypeChange = (event) => {
    setSelectedUserType(event.target.value);
  };

  const handleSendOtp = async (event) => {
    event.preventDefault();
    try {
      const response = await axios.post(`${BASE_URL}/api/patients/send-otp?mobileNo=${mobileNumber}`, {}, {
        headers: {
          'Content-Type': 'application/json'
        }
      });
      console.log('OTP sent successfully:', response.data);
      setIsOtpSent(true);
    } catch (error) {
      console.error('Error sending OTP:', error);
      setError('Error sending OTP. Please try again.');
    }
  };

  const handleVerifyOtp = async () => {
    try {
      const response = await axios.post(
        `${BASE_URL}/api/patients/verify-otp?mobileNo=${mobileNumber}&otp=${otp}`,
        {},
        {
          headers: {
            'Content-Type': 'application/json'
          }
        }
      );
      console.log('OTP verified successfully:', response.data);
      const { jwtToken, role } = response.data;
      sessionStorage.setItem('jwtToken', jwtToken);
      sessionStorage.setItem('role', role);
      navigate('/userdashboard');
    } catch (error) {
      console.error('Error verifying OTP:', error);
      setError('OTP verification failed. Please try again.');
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      let requestBody = {
        userType: selectedUserType,
      };
      if (selectedUserType === 'patient') {
        requestBody = {
          ...requestBody,
          mobile: mobileNumber,
          otp: otp,
        };
        await handleVerifyOtp();
      } else {
        requestBody = {
          ...requestBody,
          email: email,
          password: password,
        };
        const response = await fetch(`${BASE_URL}/user/login`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(requestBody),
        });

        if (!response.ok) {
          throw new Error('Invalid credentials');
        }
        const data = await response.json();
        console.log('Response Data:', data);
        sessionStorage.setItem('jwtToken', data.jwtToken);
        sessionStorage.setItem('role', data.role);
        sessionStorage.setItem('userId', data.userId); 
        const jwtToken = getJwtToken();
        console.log('JWT Token:', jwtToken);
        sessionStorage.setItem('rememberMe', rememberMe.toString());
        if (rememberMe) {
          sessionStorage.setItem('savedEmail', email);
          sessionStorage.setItem('savedPassword', password);
        } else {
          sessionStorage.removeItem('savedEmail');
          sessionStorage.removeItem('savedPassword');
        }
        const userRole = data.role;
        if (userRole.includes('DOCTOR')) {
          navigate('/dashdoc');
        } else if (userRole.includes('HEALTH_OFFICER')) {
          navigate('/dashboard');
        } else if (userRole.includes('SUB_ADMIN')) {
          navigate('/admindashboard');
        } else {
          navigate('/admindashboard');
        }
      }
    } catch (error) {
      console.error('Error:', error.message);
      setError('Login failed. Please check your credentials and try again.');
    }
  };

  useEffect(() => {
    document.head.appendChild(styleTag);
    return () => {
      document.head.removeChild(styleTag);
    };
  }, []);

  return (
    <div>
      <div className="header">
        <div className="header-text">
          <img src={logo} alt="Oxygen 2 Innovation" className="logo-image" />
        </div>
        <div className="buttons-container">
          <Link to="/registerhospital" className="login-btn">Login/Register</Link>
        </div>
      </div>
      <div className="admin-login-container">
        <div className="heading-container">
          <h1>Admin Login</h1>
        </div>
        <div className="login-form-container">
          <form className="login-form">
            <div className="form-group">
              <label htmlFor="userType"><FontAwesomeIcon icon={faChevronDown} className="icon" /></label>
              <select
                id="userType"
                value={selectedUserType}
                onChange={handleUserTypeChange}
                required
              >
                <option value="patient">Patient</option>
                <option value="doctor">Doctor</option>
                <option value="health_officer">Health Officer</option>
                <option value="admin">Hospital</option>
              </select>
            </div>
            {selectedUserType === 'patient' ? (
              <>
                <div className="form-group">
                  <label htmlFor="mobileNumber"><FontAwesomeIcon icon={faMobileAlt} className="icon" /></label>
                  <input
                    type="text"
                    id="mobileNumber"
                    value={mobileNumber}
                    onChange={handleInputChange}
                    placeholder="Mobile Number"
                    required
                  />
                </div>
                {isOtpSent && (
                  <div className="form-group">
                    <label htmlFor="otp"><FontAwesomeIcon icon={faLock} className="icon" /></label>
                    <input
                      type="password"
                      id="otp"
                      value={otp}
                      onChange={handleInputChange}
                      placeholder="OTP"
                      required
                    />
                  </div>
                )}
              </>
            ) : (
              <>
                <div className="form-group">
                  <label htmlFor="email"><FontAwesomeIcon icon={faUser} className="icon" /></label>
                  <input
                    type="text"
                    id="email"
                    value={email}
                    onChange={handleInputChange}
                    placeholder="Email"
                    required
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="password"><FontAwesomeIcon icon={faLock} className="icon" /></label>
                  <input
                    type="password"
                    id="password"
                    value={password}
                    onChange={handleInputChange}
                    placeholder="Password"
                    required
                  />
                </div>
              </>
            )}
            {error && <p className="error-message">{error}</p>}
          </form>
        </div>
        <div className="action-button-container">
          {selectedUserType === 'patient' ? (
            <>
              <button className="action-button" onClick={handleSendOtp}>
                Send OTP
              </button>
              {isOtpSent && (
                <button className="action-button" onClick={handleVerifyOtp}>
                  Verify OTP
                </button>
              )}
            </>
          ) : (
            <button className="action-button" onClick={handleSubmit}>
              Login
            </button>
          )}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default AdminLogin;