//=============================Patient Header===================================
// This component represents the navigation bar for the Patient's section of the application.
// It includes a logo, home button, and logout functionality.
// The navbar also has a notification system (currently not being used but can be extended) and handles user logout by clearing the session storage.
// It uses React Router for navigation and the useState hook for managing state like showing the notification popup and handling user logout.

import React, { useState } from "react";
import Logout from "../Assets/Logout.png"; 
import Homeicon from "../Assets/Home icon.png"; 
import Testaccount from "../Assets/Test Account.png"; 
import logo from "../Assets/O2I_LOGO.png"; 
import { Link, useNavigate } from "react-router-dom";
function Navbar() {
  const [showNotification, setShowNotification] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
 const navigate = useNavigate();
  const toggleNotification = () => {
    setShowNotification(!showNotification); 
  };
  const togglePopup = () => {
    setShowPopup(!showPopup); 
  };
   const logout = () => {
    sessionStorage.removeItem("token"); 
    navigate("/"); 
  };
  return (
    <div className="navbar-section">
<div className="navbar-title" style={{ marginLeft: '10px' }}>
    <img src={logo} alt="Oxygen 2 Innovation" className="logo-image" />
</div>
      <ul className="navbar-items">
        <li>
          <Link to="/userdashboard"> 
            <img src={Homeicon} alt="Home Icon" className="history-icon" />
          </Link>
        </li>
        <li>
          <img 
            src={Logout} 
            alt="Setting Icon" 
            className="history-icon" 
            onClick={logout}
          />
          {showPopup && (
            <div className="popup show">
            </div>
          )}
        </li>
      </ul>
    </div>
  );
}
export default Navbar;

