//===============================patient dashborad============================================
/**
 * Purpose:
 * This component renders the patient dashboard page, displaying key information about the patient's health records, 
 * past appointments, and associated documents. It allows users to manage their appointments, view health data, 
 * and interact with important notifications and links. The component provides an overview of the patient's health status 
 * and facilitates easy access to related records and actions.
 * 
 * Key Features:
 * - **Appointment History**: Displays a list of past appointments with details such as appointment date, doctor, and 
 *   related documents, allowing users to view and download them.
 * - **Health Overview**: Provides a snapshot of the patient's recent health metrics and records, including graphs and 
 *   charts to monitor various vital statistics.
 * - **Quick Access Links**: Provides links for easy navigation to sections like health records, medication history, 
 *   and appointment scheduling.
 * - **Notifications**: Shows important notifications or alerts about upcoming appointments, health reports, and other 
 *   relevant updates.
 * - **Responsive Design**: The layout is optimized for both desktop and mobile devices, providing a seamless user 
 *   experience across different screen sizes.
 * 
 * Additional Notes:
 * - **Authentication**: The component retrieves the JWT token from `sessionStorage` to authenticate the user 
 *   and fetch personalized data.
 * - **Error Handling**: The component logs any errors during data fetching and renders appropriate fallback messages 
 *   to the user.
 * - **Styling**: The component uses a modern and clean design with a focus on usability. It includes well-organized 
 *   sections for health data, documents, and appointments.
 * - **Calendar Integration**: The date picker for appointments is integrated into the dashboard, providing users 
 *   with an easy-to-use interface for selecting dates.
 */

import React, { useState, useEffect, forwardRef } from "react";
import axios from "axios";
import Patientheader from "../../Patientheader";
import Footer from "../../Footer";
import Header from "../Header/Header";
import { BASE_URL } from '../../config/apiConfig';
import 'jspdf-autotable';
import jsPDF from "jspdf";
import logo from "../../../Assets/logo.png";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Redirect from "../../../Components/Redirect";
import Calendar from "../../../Assets/Calendar.png";
const pageStyle = {
  background: 'linear-gradient(to bottom, #7CB2D0, #D0E0E5)',
};
const appointmentHistoryStyle = {
  textAlign: 'center',
  color: '#324E6A',
  fontSize: '50px',
  textShadow: '0px 0px 32.1px #BB1111',
};
const tableContainerStyle = {
  maxWidth: '1200px',
  margin: '0 auto',
  padding: '20px',
  backgroundColor: '#DCE7EB',
  overflowY: 'auto',
  marginBottom: '10px',
  maxHeight: '600px',
};
const tableStyle = {
  width: '100%',
  borderCollapse: 'separate',
  border: '2px solid #D9D9D9',
};
const thStyle = {
  backgroundColor: '#0F60B3',
  color: 'white',
  padding: '12px 8px',
  fontWeight: 'bold',
  textAlign: 'left',
  borderBottom: '1px solid #fff',
  borderRight: '2px solid #fff',
};

const tdStyle = {
  padding: '8px',
  borderBottom: '1px solid #ddd',
  textAlign: 'left',
  borderRight: '2px solid #ddd',
  cursor: 'pointer',
};

const lastColumnStyle = {
  borderRight: '2px solid #fff',
};
const styles = {
  appointmentHistory: {
    textAlign: 'center',
    color: '#324E6A',
    fontSize: '50px',
    textShadow: '0px 0px 32.1px #BB1111',
  },
  tableContainer: {
    maxWidth: '1200px',
    margin: '0 auto',
    padding: '20px',
    backgroundColor: '#DCE7EB',
    border: '2px solid #104E8D',
    borderBottom: '2px solid #104E8D',
    overflowY: 'auto',
    marginBottom: '10px',
    height: 'auto',
    position: 'relative',
  },
  table: {
    width: '100%',
    borderCollapse: 'separate',
    border: '2px solid #D9D9D9',
  },
  th: {
    backgroundColor: '#0F60B3',
    color: 'white',
    padding: '12px 8px',
    fontWeight: 'bold',
    textAlign: 'left',
    borderBottom: '1px solid #fff',
    borderRight: '2px solid #fff',
  },
  td: {
    padding: '8px',
    borderBottom: '1px solid #ddd',
    textAlign: 'left',
    borderRight: '2px solid #ddd',
  },
  lastColumn: {
    borderRight: '2px solid #fff',
  },
  closeButton: {
    marginTop: '10px',
    padding: '10px',
    backgroundColor: '#007BFF',
    color: 'white',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
  },
  datePickerContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  customInput: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
  },
  calendarIcon: {
    width: '40px',
    height: '40px',
    marginRight: '10px',
  },
  input: {
    backgroundColor: '#D9D9D9',
    border: 'none',
    outline: 'none',
    borderRadius: '5px',
    padding: '5px',
    marginBottom: '5px',
  },
};
const columnStyles = {
  patient: { maxWidth: '100px' },
  doctor: { maxWidth: '80px' },
  complaint: { maxWidth: '150px' },
  appointmentTime: { maxWidth: '100px' },
  status: { maxWidth: '100px' },
  action: { maxWidth: '100px' },
};
const popupStyle = {
  position: 'fixed',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  backgroundColor: '#fff',
  padding: '20px',
  boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
  zIndex: 1000,
  width: '700px',
  borderRadius: '10px',
};
const popupOverlayStyle = {
  position: 'fixed',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  backgroundColor: 'rgba(0, 0, 0, 0.5)',
  zIndex: 999,
};
const closeButtonStyle = {
  position: 'absolute',
  top: '10px',
  right: '10px',
  cursor: 'pointer',
  background: 'none',
  border: 'none',
  fontSize: '20px',
};
//    generatePDF    
const generatePDF = (data) => {
  const doc = new jsPDF();
  doc.setFontSize(12);
  let y = 10;
  const img = new Image();
  img.src = logo;
  img.onload = function () {
    const personalInfoX = 10;
    const logoX = 90;
    const hospitalInfoX = 150;
    doc.setFontSize(12);
    doc.text(`ID: ${data.patientId} - ${data.patientName} (${data.gender} / ${data.age} Y)`, personalInfoX, y + 5);
    doc.text(`Mob. No.: ${data.mobileNumber}`, personalInfoX, y + 10);
    doc.text(`Address: ${data.patientAddress}`, personalInfoX, y + 15);
    doc.text(`Weight: ${data.weight} Kg, Height: ${data.height} Cm`, personalInfoX, y + 20);
    doc.text(`Blood Group: ${data.bloodGroup}, BP: ${data.diastolicBP} mmHg`, personalInfoX, y + 25);
    doc.addImage(img, 'PNG', logoX, y, 30, 30);
    doc.setFontSize(14);
    doc.text(`Dr. ${data.doctorName}`, hospitalInfoX, y + 5);
    doc.setFontSize(10);
    doc.text(`${data.hospitalName}`, hospitalInfoX, y + 10);
    doc.text(`${data.hospitalAddress}`, hospitalInfoX, y + 15);
    doc.text(`Ph: ${data.hospitalMobileNumber}`, hospitalInfoX, y + 20);
    doc.text(`Timing: ${data.hospitalTiming}`, hospitalInfoX, y + 25);
    doc.text(`Closed: ${data.hospitalClosedDays}`, hospitalInfoX, y + 30);
    y += 40;
    doc.line(10, y, 200, y);
    y += 10;
    doc.setFontSize(12);
    doc.text('Chief Complaints:', 10, y);
    y += 5;
    doc.text(`- ${data.prescriptions[0].chiefComplaints}`, 15, y);
    y += 10;
    doc.text('Diagnosis:', 10, y);
    y += 5;
    doc.text(`- ${data.prescriptions[0].provisionalDiagnosis}`, 15, y);
    y += 10;
    doc.line(10, y, 200, y);
    y += 10;
    doc.text('Medications:', 10, y);
    y += 5;
    doc.autoTable({
      startY: y,
      head: [['#', 'Medicine Name', 'Dosage', 'Duration']],
      body: data.prescriptions[0].medications.map((med, index) => [
        index + 1,
        med.medication,
        med.dosage,
        `${med.days} Days`,
      ]),
      theme: 'plain',
      headStyles: { fillColor: [255, 255, 255], textColor: [0, 0, 0] },
      bodyStyles: { textColor: [0, 0, 0] },
    });
    y = doc.autoTable.previous.finalY + 10;
    doc.line(10, y, 200, y);
    y += 10;
    doc.text('Laboratory Tests:', 10, y);
    y += 5;
    doc.autoTable({
      startY: y,
      head: [['#', 'Test Name']],
      body: data.prescriptions[0].laboratory.map((lab, index) => [
        index + 1,
        lab.laboratory,
      ]),
      theme: 'plain',
      headStyles: { fillColor: [255, 255, 255], textColor: [0, 0, 0] },
      bodyStyles: { textColor: [0, 0, 0] },
    });
    y = doc.autoTable.previous.finalY + 10;
    doc.line(10, y, 200, y);
    y += 10;
    doc.text('Advice:', 10, y);
    y += 5;
    doc.text(`- ${data.prescriptions[0].advice}`, 15, y);
    y += 10;
    doc.text(`Follow-up Date: ${data.prescriptions[0].followUpDate}`, 10, y);
    doc.save('prescription.pdf');
  };
};
const CustomInput = forwardRef(({ value, onClick }, ref) => (
  <div onClick={onClick} ref={ref} style={styles.customInput}>
    <img src={Calendar} alt="Calendar" style={styles.calendarIcon} />
    <input
      type="text"
      value={value}
      readOnly
      style={styles.input}
    />
  </div>
));
function AppointmentHistory() {
  const [range, setRange] = useState("1-2");
  const [showPopup, setShowPopup] = useState(false);
  const [selectedData, setSelectedData] = useState(null);
  const [appointmentHistory, setAppointmentHistory] = useState([]);
    const [startDate, setStartDate] = useState(null);
  const [data, setData] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      const jwtToken = sessionStorage.getItem('jwtToken');
      if (!jwtToken) {
        console.error('No JWT token available for authentication');
        return;
      }
      try {
        const response = await axios.get(`${BASE_URL}/api/patients/patient-all-pre-consult`, {
          headers: {
            'Content-Type': 'application/json',
            'Auth': `Bearer ${jwtToken}`,
          }
        });
        setAppointmentHistory(response.data);
      } catch (error) {
        console.error('Error fetching appointment history:', error);
      }
    };
    fetchData();
  }, []);
  const handleRangeChange = (event) => {
    setRange(event.target.value);
  };
  const handlePrescriptionClick = (data) => {
    setSelectedData(data);
    setShowPopup(true);
  };
  const closePopup = () => {
    setShowPopup(false);
    setSelectedData(null);
  };
  const rowHeight = 45;
  const numberOfDataRows = 10;
  const headerHeight = 100;
  const totalRows = appointmentHistory.length;
  const displayedRows = Math.max(totalRows, numberOfDataRows);
  const containerHeight = headerHeight + rowHeight * displayedRows;
  return (
    <div style={pageStyle}>
        <Redirect />
            <Patientheader />
      <div style={styles.appointmentHistory}>
        User Dashboard
      </div>
      <div style={{ ...styles.tableContainer, height: containerHeight }}>
        <Header />
        <div style={styles.datePickerContainer}>
          <div style={styles.datePickerContainer}>
            <span style={{ marginLeft: '23px', marginRight: '10px', color: '#324E6A' }}>Show </span>
            <select value={range} onChange={handleRangeChange} style={{ backgroundColor: '#D9D9D9', border: 'none', outline: 'none' }}>
              <option value="1-2">1-2</option>
              <option value="3-4">3-4</option>
            </select>
            <span style={{ marginLeft: '15px', marginRight: '15px', color: '#324E6A' }}>entries </span>
          </div>
          <span style={{ marginLeft: 'auto', display: 'flex', alignItems: 'center', color: '#324E6A' }}>
            <DatePicker
              selected={startDate}
              onChange={(date) => setStartDate(date)}
              customInput={<CustomInput />}
            />
          </span>
        </div>
        <div style={{ ...tableContainerStyle, height: `${containerHeight}px` }}>
          <table style={tableStyle}>
            <thead>
              <tr>
                <th style={{ ...thStyle, ...columnStyles.patient }}>Patient ID</th>
                <th style={{ ...thStyle, ...columnStyles.doctor }}>Doctor Name</th>
                <th style={{ ...thStyle, ...columnStyles.complaint }}>Complaint</th>
                <th style={{ ...thStyle, ...columnStyles.appointmentTime }}>Appointment Time</th>
                <th style={{ ...thStyle, ...columnStyles.status }}>Status</th>
                <th style={{ ...thStyle, ...lastColumnStyle }}>Prescription</th>
              </tr>
            </thead>
          <tbody style={{ overflowX: 'auto' }}>
            {appointmentHistory.map((data, index) => (
              <tr key={index} style={{ height: rowHeight, backgroundColor: index % 2 === 0 ? '#D9D9D9' : '#E6E6E6' }}>
                  <td style={{ ...tdStyle, ...columnStyles.patient }}>{data.id}</td>
                  <td style={{ ...tdStyle, ...columnStyles.doctor }}>{data.doctor}</td>
                  <td style={{ ...tdStyle, ...columnStyles.complaint }}>{data.complaints}</td>
                  <td style={{ ...tdStyle, ...columnStyles.appointmentTime }}>{data.localDate}</td>
                  <td style={{ ...tdStyle, ...columnStyles.status }}>{data.status}</td>
                  <td style={{ ...tdStyle, ...lastColumnStyle }}>
                    <button
  onClick={() => handlePrescriptionClick(data)}
  style={{
    color: '#FFFFFF',       // White text color
    backgroundColor: '#0000FF', // Blue background color
    border: 'none',          // Optional: removes the default border
    padding: '8px 16px',     // Optional: adds some padding
    borderRadius: '4px',     // Optional: rounds the corners
    cursor: 'pointer'        // Optional: changes the cursor to a pointer
  }}
>
  View
</button>
 </td>
                </tr>
              ))}
 {[...Array(displayedRows - totalRows)].map((_, index) => (
              <tr key={index + totalRows} style={{ height: rowHeight, backgroundColor: index % 2 === 0 ? '#D9D9D9' : '#E6E6E6' }}>
                <td style={{ ...tdStyle, ...columnStyles.patient }}></td>
                <td style={{ ...tdStyle, ...columnStyles.doctor }}></td>
                <td style={{ ...tdStyle, ...columnStyles.complaint }}></td>
                <td style={{ ...tdStyle, ...columnStyles.appointmentTime }}></td>
                <td style={{ ...tdStyle, ...columnStyles.status }}></td>
                <td style={{ ...tdStyle, ...columnStyles.action, ...lastColumnStyle }}></td>
              </tr>
            ))}
            </tbody>
          </table>
        </div>
      </div>
      <Footer />
     {showPopup && (
  <div style={popupOverlayStyle}>
    <div style={{ ...popupStyle, maxWidth: '800px', maxHeight: '80vh', overflow: 'auto' }}>
      <button onClick={closePopup} style={closeButtonStyle}>×</button>
      {/* Flex container to align Doctor Info on the left and Patient Info on the right */}
      <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '20px' }}>
        {/* Patient Information */}
        <div style={{ flex: '1', textAlign: 'left', marginRight: '20px' }}>
          <div><strong>ID:</strong> {selectedData.patientId} - {selectedData.patientName} ({selectedData.gender} / {selectedData.age} Y)</div>
          <div><strong>Mob. No.:</strong> {selectedData.mobileNumber}</div>
          <div><strong>Address:</strong> {selectedData.patientAddress}</div>
          <div><strong>Weight:</strong> {selectedData.weight} Kg, <strong>Height:</strong> {selectedData.height} Cm</div>
          <div><strong>Blood Group:</strong> {selectedData.bloodGroup}, <strong>BP:</strong> {selectedData.diastolicBP} mmHg</div>
        </div>
        {/* Logo */}
        <div style={{ flex: '0', textAlign: 'center' }}>
          <img src={logo} alt="Logo" style={{ height: '80px', margin: '0 20px' }} />
        </div>
        {/* Header with Doctor and Hospital Info */}
        <div style={{ flex: '1', textAlign: 'right' }}>
          <div style={{ fontWeight: 'bold', fontSize: '18px' }}>Dr. {selectedData.doctorName}</div>
          <div style={{ fontSize: '14px' }}>{selectedData.hospitalName}</div>
          <div style={{ fontSize: '12px' }}>{selectedData.hospitalAddress}</div>
          <div style={{ fontSize: '12px', marginTop: '5px' }}>Ph: {selectedData.hospitalMobileNumber}</div>
          <div style={{ fontSize: '12px' }}>Timing: {selectedData.hospitalTiming}</div>
          <div style={{ fontSize: '12px' }}>Closed: {selectedData.hospitalClosedDays}</div>
        </div>
      </div>
      {/* Clinical Information */}
      <div style={{ borderTop: '1px solid black', marginTop: '10px', paddingTop: '10px', textAlign: 'left' }}>
        <div style={{ marginBottom: '10px' }}>
          <strong>Chief Complaints:</strong>
          <ul>
            <li>{selectedData.prescriptions[0].chiefComplaints}</li>
          </ul>
          <strong>Diagnosis:</strong>
          <ul>
            <li>{selectedData.prescriptions[0].provisionalDiagnosis}</li>
          </ul>
        </div>
      </div>
      {/* Medication List */}
      <div style={{ borderTop: '1px solid black', marginTop: '10px', paddingTop: '10px', textAlign: 'left' }}>
        <div style={{ marginBottom: '15px' }}>
          <strong>Medications:</strong>
          <table style={{ width: '100%', borderCollapse: 'collapse' }}>
            <thead>
              <tr>
                <th style={{ border: '1px solid black', padding: '5px' }}>#</th>
                <th style={{ border: '1px solid black', padding: '5px' }}>Medicine Name</th>
                <th style={{ border: '1px solid black', padding: '5px' }}>Dosage</th>
                <th style={{ border: '1px solid black', padding: '5px' }}>Duration</th>
              </tr>
            </thead>
            <tbody>
              {selectedData.prescriptions[0].medications.map((med, index) => (
                <tr key={index}>
                  <td style={{ border: '1px solid black', padding: '5px', textAlign: 'center' }}>{index + 1}</td>
                  <td style={{ border: '1px solid black', padding: '5px' }}>{med.medication}</td>
                  <td style={{ border: '1px solid black', padding: '5px', textAlign: 'center' }}>{med.dosage}</td>
                  <td style={{ border: '1px solid black', padding: '5px', textAlign: 'center' }}>{med.days} Days</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      {/* Laboratory List */}
      <div style={{ borderTop: '1px solid black', marginTop: '10px', paddingTop: '10px', textAlign: 'left' }}>
        <div style={{ marginBottom: '15px' }}>
          <strong>Laboratory Tests:</strong>
          <table style={{ width: '100%', borderCollapse: 'collapse' }}>
            <thead>
              <tr>
                <th style={{ border: '1px solid black', padding: '5px' }}>#</th>
                <th style={{ border: '1px solid black', padding: '5px' }}>Test Name</th>
              </tr>
            </thead>
            <tbody>
              {selectedData.prescriptions[0].laboratory.map((lab, index) => (
                <tr key={index}>
                  <td style={{ border: '1px solid black', padding: '5px', textAlign: 'center' }}>{index + 1}</td>
                  <td style={{ border: '1px solid black', padding: '5px' }}>{lab.laboratory}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      {/* Advice and Follow-up */}
      <div style={{ borderTop: '1px solid black', marginTop: '10px', paddingTop: '10px', textAlign: 'left' }}>
        <strong>Advice:</strong>
        <ul>
          <li>{selectedData.prescriptions[0].advice}</li>
        </ul>
        <strong>Follow-up:</strong> {selectedData.prescriptions[0].followUpDate}
      </div>
      {/* Download Button */}
      <button onClick={() => generatePDF(selectedData)} style={{ marginTop: '20px', display: 'block', marginLeft: 'auto', marginRight: 'auto' }}>
        Download PDF
      </button>
    </div>
  </div>
)}
    </div>
  );
}

export default AppointmentHistory;
