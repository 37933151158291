
/**
 * Purpose:
 * This module is responsible for defining and exporting the base URL of the backend server.
 * The base URL is used for making API requests throughout the application.
 * The URL can be modified based on the deployment environment (local development, staging, production, etc.).
 *
 * Key Features:
 * - **Base URL Configuration**: The `BASE_URL` is set to the backend server's URL. This can be updated according to the environment in which the application is deployed.
 * - **Environment Flexibility**: The URL can be easily modified for different environments such as local, staging, or production without affecting the rest of the code.
 * - **Export**: The `BASE_URL` is exported for use in other parts of the application, where it will be used to construct API requests.
 *
 * Usage Example:
 * - When making API requests, import this module and append the desired endpoint to the `BASE_URL`.
 * - Example: `fetch(`${BASE_URL}/api/endpoint`)`
 */

// apiConfig.js

     //const BASE_URL = 'https://142.93.221.34:7075'; // Modify this according to your server configuration
//  const BASE_URL = 'http://localhost:7075'; // Modify this according to your server configuration
 const BASE_URL =  'https://apis.telemed.fun';// Modify this according to your server configuration
export default BASE_URL;

export { BASE_URL };












