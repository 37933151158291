


//=============================Home1 Component===================================
// This component represents the layout for the Home page of the Health Officer's section.
// It integrates several child components to provide the following functionalities:
// 1. Redirect - Handles the redirection logic based on user conditions (e.g., login state).
// 2. Header - Displays the main header content for the page.
// 3. Videoheader - Displays the header for video consultation if applicable.
// 4. Videocon - Handles the video conference UI and logic for the Health Officer.
// 5. Videocall - Displays the video call interface.
// 6. Footer - Contains the footer content that appears at the bottom of the page.
// 7. Background - Displays a background image for the page.
// This component ensures that all these sections are well structured, and the layout fits the 100vh (viewport height) for the application.








// import backgroundImage from "../Assets/backgroud.png"; // Import the image
// import React from "react";
// import styled from "styled-components";
// // import Videoheader from "../Components/Video/Videoheader";
// import Videoheader from "../Components/Healthofficer/Videoheader/Videoheader";
// import Videocon from "../Components/Healthofficer/Videocon/Videocon";
// import Videocall from "../Components//Video/Videocall";
// import Header from "../Components/Hoheader";
// import Footer from "../Components/Footer";


// const AppWrapper = styled.div`
//   height: 100vh;
//   overflow-y: auto;
// `;

// const Background = styled.div`
//   position: absolute;
//   top: 0;
//   left: 0;
//   width: 100%;
//   height: 100%;
//   background-image: url(${backgroundImage});
//   background-size: cover;
//   background-position: center;
//   background-repeat: no-repeat;
//   z-index: -1;
// `;

// const ContentWrapper = styled.div`
//   padding: 2px;
// `;

// const HeaderWrapper = styled.div`
//   margin: 0;
//   padding: 0;
// `;

// function Home1() {
//   return (
//     <AppWrapper>
  
//       <Background />
//       <ContentWrapper>
//         <HeaderWrapper>
//           <Header />
//         </HeaderWrapper>
//         <Videoheader />
//         <Videocon/>
//         <Videocall />
//         <Footer />
//       </ContentWrapper>
//     </AppWrapper>
//   );
// }



// export default Home1;
import backgroundImage from "../Assets/backgroud.png"; // Import the image
import React from "react";
import styled from "styled-components";
import Videoheader from "../Components/Healthofficer/Videoheader/Videoheader";
import Videocon from "../Components/Healthofficer/Videocon/Videocon";
import Videocall from "../Components/Video/Videocall";
import Header from "../Components/Hoheader";
import Footer from "../Components/Footer";
import Redirect from "../Components/Redirect"; // Import Redirect.js
const AppWrapper = styled.div`
  height: 100vh;
  overflow-y: auto;
`;
const Background = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url(${backgroundImage});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  z-index: -1;
`;
const ContentWrapper = styled.div`
  padding: 2px;
`;
const HeaderWrapper = styled.div`
  margin: 0;
  padding: 0;
`;
function Home1() {
  return (
    <AppWrapper>
      <Background />
      <ContentWrapper>
        <Redirect />
        <HeaderWrapper>
          <Header />
        </HeaderWrapper>
        <Videoheader />
        <Videocon />
        <Videocall />
        <Footer />
      </ContentWrapper>
    </AppWrapper>
  );
}
export default Home1;
