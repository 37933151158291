
// import React, { useState } from 'react';
// import { Link } from 'react-router-dom';
// import Modal from 'react-modal';
// import { BASE_URL } from '../config/apiConfig';
// Modal.setAppElement('#root');
// const RegisterForm = () => {
//   const [hospitalName, setHospitalName] = useState('');
//   const [email, setEmail] = useState('');
//   const [password, setPassword] = useState('');
//   const [mobile, setMobile] = useState('');
//   const [address, setAddress] = useState('');
//   const [registrationTermCondition, setRegistrationTermCondition] = useState(false);
//   const [isModalOpen, setIsModalOpen] = useState(false);
//   const [modalMessage, setModalMessage] = useState('');
//   const [errors, setErrors] = useState({});

//   const validatePassword = (password) => {
//     const passwordRegex = /^.{8,}$/;
//     return passwordRegex.test(password);
//   };
//   const handleHospitalNameChange = (event) => {
//     setHospitalName(event.target.value);
//     setErrors({ ...errors, hospitalName: '' });
//   };
//   const handleEmailChange = (event) => {
//     setEmail(event.target.value);
//     setErrors({ ...errors, email: '' });
//   };
//   const handlePasswordChange = (event) => {
//     setPassword(event.target.value);
//     setErrors({ ...errors, password: '' });
//   };
//   const handleMobileChange = (event) => {
//     setMobile(event.target.value);
//     setErrors({ ...errors, mobile: '' });
//   };
//   const handleAddressChange = (event) => {
//     setAddress(event.target.value);
//     setErrors({ ...errors, address: '' });
//   };
//   const handleTermsChange = () => {
//     setRegistrationTermCondition(!registrationTermCondition);
//   };
//   const validateEmail = (email) => {
//     const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
//     return emailRegex.test(email);
//   };
//   const validateMobileNumber = (mobile) => {
//     const mobileRegex = /^[0-9]{10}$/;
//     return mobileRegex.test(mobile);
//   };
//   const validateHospitalName = (hospitalName) => {
//     return hospitalName.trim() !== '';
//   };
//   const validateForm = () => {
//     let formErrors = {};
//     if (!validateHospitalName(hospitalName)) {
//       formErrors.hospitalName = 'Please enter a hospital name.';
//     }
//     if (!validateEmail(email)) {
//       formErrors.email = 'Please enter a valid email address.';
//     }
//     if (!validatePassword(password)) {
//       errors.password = 'Password must be at least 8 characters long.';
//     }
//     if (!validateMobileNumber(mobile)) {
//       formErrors.mobile = 'Please enter a valid 10-digit mobile number.';
//     }
//     setErrors(formErrors);
//     return Object.keys(formErrors).length === 0;
//   };
//   const handleSubmit = async (event) => {
//     event.preventDefault();
//     if (!registrationTermCondition) {
//       alert('Please accept the terms and conditions.');
//       return;
//     }
//     if (!validateForm()) {
//       return;
//     }
//     const requestBody = {
//       userName: hospitalName,
//       email,
//       password,
//       mobileNo: mobile,
//       registrationTermCondition: true,
//     };
//     try {
//       const response = await fetch(`${BASE_URL}/user/register`, {
//         method: 'POST',
//         headers: {
//           'Content-Type': 'application/json',
//         },
//         body: JSON.stringify(requestBody),
//       });
//       const contentType = response.headers.get('Content-Type');
//       let result;
//       if (contentType && contentType.includes('application/json')) {
//         result = await response.json();
//       } else {
//         const text = await response.text();
//         result = { message: text };
//       }
//       if (!response.ok) {
//         if (result.message.includes('mobile number')) {
//           setModalMessage('The mobile number already exists.');
//         } else if (result.message.includes('email')) {
//           setModalMessage('The email address already exists.');
//         } else {
//           setModalMessage(result.message || 'Registration failed');
//         }
//         setIsModalOpen(true);
//         return;
//       }
//       setHospitalName('');
//       setEmail('');
//       setPassword('');
//       setMobile('');
//       setAddress('');
//       setRegistrationTermCondition(false);
//       setModalMessage(result.message || 'Registration successful');
//       setIsModalOpen(true);
//     } catch (error) {
//       console.error('Error submitting form:', error);
//       setModalMessage('Registration failed');
//       setIsModalOpen(true);
//     }
//   };
//   const closeModal = () => {
//     setIsModalOpen(false);
//   };
//   return (
//     <div style={styles.container}>
//       <h2 style={styles.heading}>Hospital Registration</h2>
//       <div className="register-form-container" style={styles.formContainer}>
//         <form onSubmit={handleSubmit} className="register-form">
//           <div className="form-group">
//             <input
//               type="text"
//               id="hospitalName"
//               value={hospitalName}
//               onChange={handleHospitalNameChange}
//               placeholder="Hospital Name"
//               style={styles.input}
//               required
//             />
//             {errors.hospitalName && <p style={styles.errorText}>{errors.hospitalName}</p>}
//           </div>
//           <div className="form-group">
//             <input
//               type="email"
//               id="email"
//               value={email}
//               onChange={handleEmailChange}
//               placeholder="Email"
//               style={styles.input}
//               required
//             />
//             {errors.email && <p style={styles.errorText}>{errors.email}</p>}
//           </div>
//           <div className="form-group">
//             <input
//               type="password"
//               id="password"
//               value={password}
//               onChange={handlePasswordChange}
//               placeholder="Password"
//               style={styles.input}
//               required
//             />
//             {errors.password && <p style={styles.errorText}>{errors.password}</p>}
//           </div>
//           <div className="form-group">
//             <input
//               type="text"
//               id="mobile"
//               value={mobile}
//               onChange={handleMobileChange}
//               placeholder="Mobile Number"
//               style={styles.input}
//               inputMode="numeric"
//               pattern="[0-9]*"
//               required
//             />
//             {errors.mobile && <p style={styles.errorText}>{errors.mobile}</p>}
//           </div>
//           <div className="form-group">
//             <input
//               type="text"
//               id="address"
//               value={address}
//               onChange={handleAddressChange}
//               placeholder="Address"
//               style={styles.input}
//             />
//             {errors.address && <p style={styles.errorText}>{errors.address}</p>}
//           </div>
//           <div className="form-group">
//             <label>
//               <input
//                 type="checkbox"
//                 checked={registrationTermCondition}
//                 onChange={handleTermsChange}
//                 style={{ marginRight: '10px' }}
//                 required
//               />
//               I accept the terms and conditions
//             </label>
//           </div>
//           <div className="form-group">
//             <button type="submit" style={styles.button}>Submit</button>
//           </div>
//         </form>
//         <p style={styles.linkText}>
//           Already have an account? <Link to="/login" style={styles.link}>Login</Link>
//         </p>
//       </div>
//       <Modal
//         isOpen={isModalOpen}
//         onRequestClose={closeModal}
//         style={modalStyles}
//         contentLabel="Registration Status Modal"
//       >
//         <h2 style={styles.modalHeading}>{modalMessage}</h2>
//         <button onClick={closeModal} style={styles.modalButton}>Close</button>
//       </Modal>
//     </div>
//   );
// };
// // Styles for the component
// const styles = {
//   container: {
//     background: 'linear-gradient(#7CB2D0, #D0E0E5)',
//     minHeight: '100vh',
//     display: 'flex',
//     flexDirection: 'column',
//     justifyContent: 'center',
//     alignItems: 'center',
//   },
//   heading: {
//     marginTop: '20px',
//     color: '#333',
//     fontSize: '29px',
//     textShadow: '2px 2px 4px rgba(255, 0, 0, 0.5)',
//     marginBottom: '20px',
//   },
//   formContainer: {
//     width: '90%',
//     maxWidth: '400px',
//     padding: '40px',
//     borderRadius: '8px',
//     boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
//     background: 'none',
//     border: '2px solid #007bff',
//   },
//   input: {
//     width: '100%',
//     padding: '10px',
//     borderRadius: '5px',
//     background: 'rgba(255, 255, 255, 0.3)',
//     fontSize: '16px',
//     border: 'none',
//     marginBottom: '10px',

//   },
//   button: {
//     width: '100%',
//     padding: '10px',
//     backgroundColor: '#007bff',
//     color: '#fff',
//     border: 'none',
//     borderRadius: '5px',
//     cursor: 'pointer',
//   },
//   linkText: {
//     textAlign: 'center',
//     marginTop: '20px',
//     color: '#333',
//   },
//   link: {
//     color: '#007bff',
//     textDecoration: 'none',
//   },
//   errorText: {
//     color: 'red',
//     fontSize: '14px',
//     marginTop: '-10px',
//     marginBottom: '10px',
//   },
//   modalHeading: {
//     color: '#333',
//     fontSize: '18px',
//     textAlign: 'center',
//     marginBottom: '20px',
//   },
//   modalButton: {
//     display: 'block',
//     margin: '0 auto',
//     padding: '10px 20px',
//     backgroundColor: '#007bff',
//     color: '#fff',
//     border: 'none',
//     borderRadius: '5px',
//     cursor: 'pointer',
//   },
// };
// // Modal styles
// const modalStyles = {
//   content: {
//     top: '50%',
//     left: '50%',
//     right: 'auto',
//     bottom: 'auto',
//     marginRight: '-50%',
//     transform: 'translate(-50%, -50%)',
//     padding: '40px',
//     borderRadius: '8px',
//     boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
//     border: '2px solid #007bff',
//   },
// };
// export default RegisterForm;

// ----------- 24 mar2025 waris code-------------

// import React, { useState } from "react";
// import bgImage from "../../Assets/registration.png";

// const HospitalRegistration = () => {
//   const [formData, setFormData] = useState({
//     hospitalName: "",
//     email: "",
//     password: "",
//     mobile: "",
//     address: "",
//     termsAccepted: false,
//   });

//   const handleChange = (e) => {
//     const { name, value, type, checked } = e.target;
//     setFormData((prev) => ({
//       ...prev,
//       [name]: type === "checkbox" ? checked : value,
//     }));
//   };

//   const handleSubmit = (e) => {
//     e.preventDefault();
//     if (!formData.termsAccepted) {
//       alert("Please accept the terms and conditions.");
//       return;
//     }
//     console.log("Form Submitted", formData);
    
//   };

//   return (
//     <div style={styles.container}>
//       <h1 style={styles.heading}>Telemedicine Hospital Registration</h1>
//       <div style={styles.formContainer}>
//         <form onSubmit={handleSubmit} style={styles.form}>
//           <input
//             type="text"
//             name="hospitalName"
//             placeholder="Hospital Name"
//             value={formData.hospitalName}
//             onChange={handleChange}
//             style={styles.input}
//             required
//           />
//           <input
//             type="email"
//             name="email"
//             placeholder="Email"
//             value={formData.email}
//             onChange={handleChange}
//             style={styles.input}
//             required
//           />
//           <input
//             type="password"
//             name="password"
//             placeholder="Password"
//             value={formData.password}
//             onChange={handleChange}
//             style={styles.input}
//             required
//           />
//           <input
//             type="tel"
//             name="mobile"
//             placeholder="Mobile Number"
//             value={formData.mobile}
//             onChange={handleChange}
//             style={styles.input}
//             required
//           />
//           <input
//             type="text"
//             name="address"
//             placeholder="Address"
//             value={formData.address}
//             onChange={handleChange}
//             style={styles.input}
//             required
//           />
//           <div style={styles.checkboxContainer}>
//             <input
//               type="checkbox"
//               name="termsAccepted"
//               checked={formData.termsAccepted}
//               onChange={handleChange}
//               style={styles.checkbox}
//             />
//             <label style={styles.checkboxLabel}>
//               I accept the terms and conditions
//             </label>
//           </div>
//           <button type="submit" style={styles.submitButton}>
//             Submit
//           </button>
//         </form>
//         <p style={styles.loginText}>
//           Already have an account?{" "}
//           <a href="/login" style={styles.loginLink}>
//             Login
//           </a>
//         </p>
//       </div>
//     </div>
//   );
// };

// const styles = {
//   container: {
//     minHeight: "100vh",
//     display: "flex",
//     flexDirection: "column",
//     alignItems: "center",
//     justifyContent: "center",
//     backgroundImage: `url(${bgImage})`,     
//     backgroundSize: "cover",
//     backgroundPosition: "center",
//     backgroundRepeat: "no-repeat",
//     marginTop: "50px",   
//     marginBottom: "50px",
//     paddingBottom: "50px", 
//   },

//   heading: {
//     fontSize: "28px",
//     fontWeight: "bold",
//     color: "#0056b3",
//     marginBottom: "20px",
//   },
//   formContainer: {
//     backgroundColor: "#0077cc",
//     padding: "30px",
//     borderRadius: "10px",
//     boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
//     width: "350px",
//     textAlign: "center",
//   },
//   form: {
//     display: "flex",
//     flexDirection: "column",
//     gap: "10px",
//   },
//   input: {
//     width: "100%",
//     padding: "10px",
//     borderRadius: "5px",
//     border: "none",
//     backgroundColor: "#f0f0f0",
//     fontSize: "16px",
//   },
//   checkboxContainer: {
//     display: "flex",
//     alignItems: "center",
//     gap: "8px",
//     justifyContent: "start",
//   },
//   checkbox: {
//     width: "16px",
//     height: "16px",
//   },
//   checkboxLabel: {
//     fontSize: "14px",
//     color: "#fff",
//   },
//   submitButton: {
//     width: "100%",
//     backgroundColor: "#0056b3",
//     color: "#fff",
//     padding: "10px",
//     borderRadius: "5px",
//     fontSize: "16px",
//     cursor: "pointer",
//     border: "none",
//     marginTop: "10px",
//   },
//   loginText: {
//     color: "#fff",
//     marginTop: "10px",
//   },
//   loginLink: {
//     color: "#cce5ff",
//     textDecoration: "none",
//   },
// };

// export default HospitalRegistration;





// import React, { useState } from "react";
// import { BASE_URL } from "../config/apiConfig";

// const HospitalRegistration = () => {
//   const [formData, setFormData] = useState({
//     hospitalName: "",
//     email: "",
//     password: "",
//     mobile: "",
//     address: "",
//     termsAccepted: false,
//   });
//   const [message, setMessage] = useState("");

//   const handleChange = (e) => {
//     const { name, value, type, checked } = e.target;
//     setFormData((prev) => ({
//       ...prev,
//       [name]: type === "checkbox" ? checked : value,
//     }));
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     if (!formData.termsAccepted) {
//       alert("Please accept the terms and conditions.");
//       return;
//     }

//     const requestBody = {
//       userName: formData.hospitalName,
//       email: formData.email,
//       password: formData.password,
//       mobileNo: formData.mobile,
//       address: formData.address,
//       registrationTermCondition: true,
//     };

//     try {
//       const response = await fetch(`${BASE_URL}/user/register`, {
//         method: "POST",
//         headers: { "Content-Type": "application/json" },
//         body: JSON.stringify(requestBody),
//       });

//       const responseText = await response.text(); // Read as text first
//       console.log("Response Text:", responseText); // Log raw response

//       try {
//         const result = JSON.parse(responseText); // Try parsing JSON
//         if (!response.ok) {
//           setMessage(result?.message || "Registration failed. Please try again.");
//           return;
//         }
//         setMessage("Registration successful! Redirecting to login...");
//         setTimeout(() => (window.location.href = "/login"), 2000);
//       } catch (jsonError) {
//         console.error("Invalid JSON Response:", responseText);
//         setMessage("Unexpected response from server. Please try again later.");
//       }
//     } catch (error) {
//       console.error("Error submitting form:", error);
//       setMessage("An error occurred. Please try again later.");
//     }
//   };

//   return (
//     <div style={styles.container}>
//       <h1 style={styles.heading}>Hospital Registration</h1>
//       <div style={styles.formContainer}>
//         <form onSubmit={handleSubmit} style={styles.form}>
//           <input type="text" name="hospitalName" placeholder="Hospital Name" value={formData.hospitalName} onChange={handleChange} style={styles.input} required />
//           <input type="email" name="email" placeholder="Email" value={formData.email} onChange={handleChange} style={styles.input} required />
//           <input type="password" name="password" placeholder="Password" value={formData.password} onChange={handleChange} style={styles.input} required />
//           <input type="tel" name="mobile" placeholder="Mobile Number" value={formData.mobile} onChange={handleChange} style={styles.input} required />
//           <input type="text" name="address" placeholder="Address" value={formData.address} onChange={handleChange} style={styles.input} required />
//           <div style={styles.checkboxContainer}>
//             <input type="checkbox" name="termsAccepted" checked={formData.termsAccepted} onChange={handleChange} style={styles.checkbox} />
//             <label style={styles.checkboxLabel}>I accept the terms and conditions</label>
//           </div>
//           <button type="submit" style={styles.submitButton}>Submit</button>
//         </form>
//         {message && <p style={styles.message}>{message}</p>}
//         <p style={styles.loginText}>Already have an account? <a href="/login" style={styles.loginLink}>Login</a></p>
//       </div>
//     </div>
//   );
// };

// const styles = {
//   container: {
//     minHeight: "100vh",
//     display: "flex",
//     flexDirection: "column",
//     alignItems: "center",
//     justifyContent: "center",
//     background: "#f4f4f4",
//     padding: "20px",
//   },
//   heading: {
//     fontSize: "24px",
//     color: "#0056b3",
//     marginBottom: "20px",
//   },
//   formContainer: {
//     backgroundColor: "#fff",
//     padding: "20px",
//     borderRadius: "8px",
//     boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
//     width: "350px",
//     textAlign: "center",
//   },
//   form: {
//     display: "flex",
//     flexDirection: "column",
//     gap: "10px",
//   },
//   input: {
//     width: "100%",
//     padding: "10px",
//     borderRadius: "5px",
//     border: "1px solid #ddd",
//     fontSize: "16px",
//   },
//   checkboxContainer: {
//     display: "flex",
//     alignItems: "center",
//     justifyContent: "start",
//   },
//   checkbox: {
//     width: "16px",
//     height: "16px",
//   },
//   checkboxLabel: {
//     fontSize: "14px",
//     marginLeft: "8px",
//     color: "#333",
//   },
//   submitButton: {
//     width: "100%",
//     backgroundColor: "#0056b3",
//     color: "#fff",
//     padding: "10px",
//     borderRadius: "5px",
//     fontSize: "16px",
//     cursor: "pointer",
//     border: "none",
//     marginTop: "10px",
//   },
//   message: {
//     color: "#ff0000",
//     marginTop: "10px",
//   },
//   loginText: {
//     marginTop: "10px",
//   },
//   loginLink: {
//     color: "#0056b3",
//     textDecoration: "none",
//   },
// };

// export default HospitalRegistration;

// import React, { useState } from "react";
// import { BASE_URL } from "../config/apiConfig";
// import bgImage from "../../Assets/registration.png";

// const HospitalRegistration = () => {
//   const [formData, setFormData] = useState({
//     hospitalName: "",
//     email: "",
//     password: "",
//     mobile: "",
//     address: "",
//     termsAccepted: false,
//   });
//   const [message, setMessage] = useState("");

//   const handleChange = (e) => {
//     const { name, value, type, checked } = e.target;
//     setFormData((prev) => ({
//       ...prev,
//       [name]: type === "checkbox" ? checked : value,
//     }));
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     if (!formData.termsAccepted) {
//       alert("Please accept the terms and conditions.");
//       return;
//     }

//     const requestBody = {
//       userName: formData.hospitalName,
//       email: formData.email,
//       password: formData.password,
//       mobileNo: formData.mobile,
//       address: formData.address,
//       registrationTermCondition: true,
//     };

//     try {
//       const response = await fetch(`${BASE_URL}/user/register`, {
//         method: "POST",
//         headers: { "Content-Type": "application/json" },
//         body: JSON.stringify(requestBody),
//       });

//       const responseText = await response.text();
//       console.log("Response Text:", responseText);

//       try {
//         const result = JSON.parse(responseText);
//         if (!response.ok) {
//           setMessage(result?.message || "Registration failed. Please try again.");
//           return;
//         }
//         setMessage("Registration successful! Redirecting to login...");
//         setTimeout(() => (window.location.href = "/login"), 2000);
//       } catch (jsonError) {
//         console.error("Invalid JSON Response:", responseText);
//         setMessage("Unexpected response from server. Please try again later.");
//       }
//     } catch (error) {
//       console.error("Error submitting form:", error);
//       setMessage("An error occurred. Please try again later.");
//     }
//   };

//   return (
//     <div style={styles.container}>
//       <h1 style={styles.heading}>Hospital Registration</h1>
//       <div style={styles.formContainer}>
//         <form onSubmit={handleSubmit} style={styles.form}>
//           <input type="text" name="hospitalName" placeholder="Hospital Name" value={formData.hospitalName} onChange={handleChange} style={styles.input} required />
//           <input type="email" name="email" placeholder="Email" value={formData.email} onChange={handleChange} style={styles.input} required />
//           <input type="password" name="password" placeholder="Password" value={formData.password} onChange={handleChange} style={styles.input} required />
//           <input type="tel" name="mobile" placeholder="Mobile Number" value={formData.mobile} onChange={handleChange} style={styles.input} required />
//           <input type="text" name="address" placeholder="Address" value={formData.address} onChange={handleChange} style={styles.input} required />
//           <div style={styles.checkboxContainer}>
//             <input type="checkbox" name="termsAccepted" checked={formData.termsAccepted} onChange={handleChange} style={styles.checkbox} />
//             <label style={styles.checkboxLabel}>I accept the terms and conditions</label>
//           </div>
//           <button type="submit" style={styles.submitButton}>Submit</button>
//         </form>
//         {message && <p style={styles.message}>{message}</p>}
//         <p style={styles.loginText}>Already have an account? <a href="/login" style={styles.loginLink}>Login</a></p>
//       </div>
//     </div>
//   );
// };

// const styles = {
//   container: {
//     minHeight: "100vh",
//     display: "flex",
//     flexDirection: "column",
//     alignItems: "center",
//     justifyContent: "center",
//     backgroundImage: `url(${bgImage})`,
//     backgroundSize: "cover",
//     backgroundPosition: "center",
//     backgroundRepeat: "no-repeat",
//     marginTop: "10px",
//     marginBottom: "50px",
//     paddingBottom: "50px",
//     padding: "20px",
//   },
//   heading: {
//     fontSize: "42px",
//     color: "#0056b3",
//     marginBottom: "20px",
//   },
//   formContainer: {
//     backgroundColor: "#348AD1",
//     padding: "20px",
//     borderRadius: "8px",
//     boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
//     width: "350px",
//     textAlign: "center",
//   },
//   form: {
//     display: "flex",
//     flexDirection: "column",
//     gap: "10px",
//   },
//   input: {
//     width: "100%",
//     padding: "10px",
//     borderRadius: "5px",
//     border: "1px solid #ddd",
//     fontSize: "16px",
//   },
//   checkboxContainer: {
//     display: "flex",
//     alignItems: "center",
//     justifyContent: "start",
//   },
//   checkbox: {
//     width: "16px",
//     height: "16px",
//   },
//   checkboxLabel: {
//     fontSize: "14px",
//     marginLeft: "8px",
//     color: "#333",
//   },
//   submitButton: {
//     width: "100%",
//     backgroundColor: "#0056b3",
//     color: "#fff",
//     padding: "10px",
//     borderRadius: "5px",
//     fontSize: "16px",
//     cursor: "pointer",
//     border: "none",
//     marginTop: "10px",
//   },
//   message: {
//     color: "#ff0000",
//     marginTop: "10px",
//   },
//   loginText: {
//     marginTop: "10px",
//   },
//   loginLink: {
//     color: "#0056b3",
//     textDecoration: "none",
//   },
// };

// export default HospitalRegistration;

// all fun working except exeption-----------


// import React, { useState } from "react";
// import { BASE_URL } from "../config/apiConfig";
// import bgImage from "../../Assets/registration.png";

// const HospitalRegistration = () => {
//   const [formData, setFormData] = useState({
//     hospitalName: "",
//     email: "",
//     password: "",
//     mobile: "",
//     address: "",
//     termsAccepted: false,
//   });
//   const [message, setMessage] = useState("");

//   const handleChange = (e) => {
//     const { name, value, type, checked } = e.target;
//     setFormData((prev) => ({
//       ...prev,
//       [name]: type === "checkbox" ? checked : value,
//     }));
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     if (!formData.termsAccepted) {
//       alert("Please accept the terms and conditions.");
//       return;
//     }

//     const requestBody = {
//       userName: formData.hospitalName,
//       email: formData.email,
//       password: formData.password,
//       mobileNo: formData.mobile,
//       address: formData.address,
//       registrationTermCondition: true,
//     };

//     try {
//       const response = await fetch(`${BASE_URL}/user/register`, {
//         method: "POST",
//         headers: { "Content-Type": "application/json" },
//         body: JSON.stringify(requestBody),
//       });

//       const responseText = await response.text();
//       console.log("Response Text:", responseText);

//       try {
//         const result = JSON.parse(responseText);
//         if (!response.ok) {
//           setMessage(result?.message || "Registration failed. Please try again.");
//           return;
//         }
//         setMessage("Registration successful! Redirecting to login...");
//         setTimeout(() => (window.location.href = "/login"), 2000);
//       } catch (jsonError) {
//         console.error("Invalid JSON Response:", responseText);
//         setMessage("Unexpected response from server. Please try again later.");
//       }
//     } catch (error) {
//       console.error("Error submitting form:", error);
//       setMessage("An error occurred. Please try again later.");
//     }
//   };

//   return (
//     <div style={styles.container}>
//       <h1 style={styles.heading}>Hospital Registration</h1>
//       <div style={styles.formContainer}>
//         <form onSubmit={handleSubmit} style={styles.form}>
//           <input type="text" name="hospitalName" placeholder="Hospital Name" value={formData.hospitalName} onChange={handleChange} style={styles.input} required />
//           <input type="email" name="email" placeholder="Email" value={formData.email} onChange={handleChange} style={styles.input} required />
//           <input type="password" name="password" placeholder="Password" value={formData.password} onChange={handleChange} style={styles.input} required />
//           <input type="tel" name="mobile" placeholder="Mobile Number" value={formData.mobile} onChange={handleChange} style={styles.input} required />
//           <input type="text" name="address" placeholder="Address" value={formData.address} onChange={handleChange} style={styles.input} required />
//         </form>
//       </div>
//       <div style={styles.extraContainer}>
//         <div style={styles.checkboxContainer}>
//           <input type="checkbox" name="termsAccepted" checked={formData.termsAccepted} onChange={handleChange} style={styles.checkbox} />
//           <label style={styles.checkboxLabel}>I accept the terms and conditions</label>
//         </div>
//         <button type="submit" onClick={handleSubmit} style={styles.submitButton}>Submit</button>
//         {message && <p style={styles.message}>{message}</p>}
//         <p style={styles.loginText}>Already have an account? <a href="/login" style={styles.loginLink}>Login</a></p>
//       </div>
//     </div>
//   );
// };

// const styles = {
//   container: {
//     minHeight: "100vh",
//     display: "flex",
//     flexDirection: "column",
//     alignItems: "center",
//     justifyContent: "center",
//     backgroundImage: `url(${bgImage})`,
//     backgroundSize: "cover",
//     backgroundPosition: "center",
//     backgroundRepeat: "no-repeat",
//     padding: "20px",
//   },
//   heading: {
//     fontSize: "42px",
//     color: "#0056b3",
//     marginBottom: "20px",
//   },
//   formContainer: {
//     backgroundColor: "#348AD1",
//     padding: "20px",
//     borderRadius: "8px",
//     boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
//     width: "350px",
//     textAlign: "center",
//   },
//   form: {
//     display: "flex",
//     flexDirection: "column",
//     gap: "10px",
//   },
//   input: {
//     width: "100%",
//     padding: "10px",
//     borderRadius: "5px",
//     border: "1px solid #ddd",
//     fontSize: "16px",
//   },
//   extraContainer: {
//     marginTop: "20px",
//     textAlign: "center",
//   },
//   checkboxContainer: {
//     display: "flex",
//     alignItems: "center",
//     justifyContent: "center",
//     marginBottom: "10px",
//   },
//   checkbox: {
//     width: "16px",
//     height: "16px",
//   },
//   checkboxLabel: {
//     fontSize: "14px",
//     marginLeft: "8px",
//     color: "#333",
//     fontWeight: "bold",
//   },
//   submitButton: {
//     width: "200px",
//     backgroundColor: "#0056b3",
//     color: "#fff",
//     padding: "10px",
//     borderRadius: "5px",
//     fontSize: "16px",
//     cursor: "pointer",
//     border: "none",
//     marginTop: "10px",
//   },
//   message: {
//     color: "#ff0000",
//     marginTop: "10px",
//   },
//   loginText: {
//     marginTop: "10px",
//   },
//   loginLink: {
//     color: "#0056b3",
//     textDecoration: "none",
//   },
// };

// export default HospitalRegistration;

// ------final after tested------
// ----------- 24 mar2025 waris code-------------


import React, { useState } from "react";
import { BASE_URL } from "../config/apiConfig";
import bgImage from "../../Assets/registration.png";

const HospitalRegistration = () => {
  const [formData, setFormData] = useState({
    hospitalName: "",
    email: "",
    password: "",
    mobile: "",
    address: "",
    termsAccepted: false,
  });

  const [message, setMessage] = useState("");
  const [error, setError] = useState({});

  const validateInput = () => {
    let errors = {};

    if (!formData.hospitalName.trim()) {
      errors.hospitalName = "Please enter a hospital name.";
    }

    if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formData.email)) {
      errors.email = "Please enter a valid email address.";
    }

    if (formData.password.length < 8) {
      errors.password = "Password must be at least 8 characters long.";
    }

    if (!/^\d{10}$/.test(formData.mobile)) {
      errors.mobile = "Mobile number should be exactly 10 digits.";
    }

    return errors;
  };

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: type === "checkbox" ? checked : value,
    }));
    setError({ ...error, [name]: "" });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const validationErrors = validateInput();
    if (Object.keys(validationErrors).length > 0) {
      setError(validationErrors);
      return;
    }

    if (!formData.termsAccepted) {
      alert("Please accept the terms and conditions.");
      return;
    }

    const requestBody = {
      userName: formData.hospitalName,
      email: formData.email,
      password: formData.password,
      mobileNo: formData.mobile,
      address: formData.address,
      registrationTermCondition: true,
    };

    try {
      const response = await fetch(`${BASE_URL}/user/register`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(requestBody),
      });

      const contentType = response.headers.get("Content-Type");
      let result;

      if (contentType && contentType.includes("application/json")) {
        result = await response.json();
      } else {
        const text = await response.text();
        result = { message: text };
      }

      if (!response.ok) {
        setMessage(result.message || "Registration failed. Please try again.");
        return;
      }

      setMessage("Registration successful! Redirecting to login...");
      setTimeout(() => (window.location.href = "/login"), 2000);
    } catch (error) {
      setMessage("An error occurred. Please try again later.");
    }
  };

  return (
    <div style={styles.container}>
      <h1 style={styles.heading}>Hospital Registration</h1>
      <div style={styles.formContainer}>
        <form onSubmit={handleSubmit} style={styles.form}>
          <input type="text" name="hospitalName" placeholder="Hospital Name" value={formData.hospitalName} onChange={handleChange} style={styles.input} />
          {error.hospitalName && <p style={styles.errorText}>{error.hospitalName}</p>}

          <input type="email" name="email" placeholder="Email" value={formData.email} onChange={handleChange} style={styles.input} />
          {error.email && <p style={styles.errorText}>{error.email}</p>}

          <input type="password" name="password" placeholder="Password" value={formData.password} onChange={handleChange} style={styles.input} />
          {error.password && <p style={styles.errorText}>{error.password}</p>}

          <input type="tel" name="mobile" placeholder="Mobile Number" value={formData.mobile} onChange={handleChange} style={styles.input} />
          {error.mobile && <p style={styles.errorText}>{error.mobile}</p>}

          <input type="text" name="address" placeholder="Address" value={formData.address} onChange={handleChange} style={styles.input} />
        </form>
      </div>

      <div style={styles.checkboxContainer}>
        <input type="checkbox" name="termsAccepted" checked={formData.termsAccepted} onChange={handleChange} style={styles.checkbox} />
        <label style={styles.checkboxLabel}>I accept the terms and conditions</label>
      </div>

      <button type="submit" onClick={handleSubmit} style={styles.submitButton}>Submit</button>
      {message && <p style={styles.message}>{message}</p>}
      <p style={styles.loginText}>Already have an account? <a href="/login" style={styles.loginLink}>Login</a></p>
    </div>
  );
};

const styles = {
  container: {
    minHeight: "100vh",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    backgroundImage: `url(${bgImage})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    padding: "20px",
  },
  heading: {
    fontSize: "42px",
    color: "#0056b3",
    marginBottom: "20px",
  },
  formContainer: {
    backgroundColor: "#348AD1",
    padding: "20px",
    borderRadius: "8px",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
    width: "350px",
    textAlign: "center",
  },
  form: {
    display: "flex",
    flexDirection: "column",
    gap: "10px",
  },
  input: {
    width: "100%",
    padding: "10px",
    borderRadius: "5px",
    border: "1px solid #ddd",
    fontSize: "16px",
  },
  errorText: {
    color: "#fff",
    fontSize: "14px",
    marginBottom: "5px",
  },
  checkboxContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginBottom: "10px",
  },
  checkbox: {
    width: "16px",
    height: "16px",
  },
  checkboxLabel: {
    fontSize: "14px",
    marginLeft: "8px",
    color: "#333",
    fontWeight: "bold",
  },
  submitButton: {
    width: "200px",
    backgroundColor: "#0056b3",
    color: "#fff",
    padding: "10px",
    borderRadius: "5px",
    fontSize: "16px",
    cursor: "pointer",
    border: "none",
    marginTop: "10px",
  },
  message: {
    color: "#ff0000",
    marginTop: "10px",
  },
  loginText: {
    marginTop: "10px",
  },
  loginLink: {
    color: "#0056b3",
    textDecoration: "none",
  },
};

export default HospitalRegistration;
