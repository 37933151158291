//================================================consultation===========================================
// ------------------------------
// This is the Appointment History Page
// This page displays the patient's consultation history.
// The user can filter by date range and search for specific appointments.
// The table displays details like case number, patient name, doctor name, and date/time of consultation.
// -------------------------------------------------------------------------------

import React, { useState } from "react";
import Footer from '../../Footer';
import Header from "../../Hoheader";
const pageStyle = {
  background: 'linear-gradient(to bottom, #7CB2D0, #D0E0E5)',
};
const appointmentHistoryStyle = {
  textAlign: 'center',
  color: '#324E6A',
  fontSize: '50px',
  textShadow: '0px 0px 32.1px #BB1111',
};
const tableContainerStyle = {
  maxWidth: '1200px',
  margin: '0 auto',
  padding: '20px',
  backgroundColor: '#DCE7EB',
  border: '2px solid #104E8D',
  borderBottom: '2px solid #104E8D',
  overflowY: 'auto',
  marginBottom: '10px',
  maxHeight: '600px',
};
const tableStyle = {
  width: '100%',
  borderCollapse: 'separate',
  border: '2px solid #D9D9D9',
};
const thStyle = {
  backgroundColor: '#0F60B3',
  color: 'white',
  padding: '12px 8px',
  fontWeight: 'bold',
  textAlign: 'left',
  borderBottom: '1px solid #fff',
  borderRight: '2px solid #fff',
};
const tdStyle = {
  padding: '8px',
  borderBottom: '1px solid #ddd',
  textAlign: 'left',
  borderRight: '2px solid #ddd',
};
const lastColumnStyle = {
  borderRight: '2px solid #fff',
};
const columnStyles = {
  caseNumber: { maxWidth: '80px' },
  patient: { maxWidth: '150px' },
  doctor: { maxWidth: '150px' },
  dateTime: { maxWidth: '200px' },
};
function AppointmentHistory() {
  const [range, setRange] = useState("1-2");

  const handleRangeChange = (event) => {
    setRange(event.target.value);
  };
  const rowHeight = 45;
  const numberOfDataRows = 10;
  const headerHeight = 100;
  const totalRows = 2;
  const displayedRows = Math.max(totalRows, numberOfDataRows);
  const containerHeight = headerHeight + rowHeight * displayedRows;
  const temporaryData = [
    { caseNumber: "1", patient: "John Doe", doctor: "Dr. Smith", dateTime: "2024-05-18 10:00 AM" },
    { caseNumber: "2", patient: "Jane Smith", doctor: "Dr. Johnson", dateTime: "2024-05-18 11:30 AM" },
    // Add more temporary data here
  ];
  return (
    <div style={pageStyle}>
      <Header />
      <div style={appointmentHistoryStyle}>
      Consultation History
      </div>
      <div style={tableContainerStyle}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <span style={{ marginLeft: '23px', marginRight: '10px', color: '#324E6A' }}>Show </span>
            <select value={range} onChange={handleRangeChange} style={{ backgroundColor: '#D9D9D9', border: 'none', outline: 'none' }}>
              <option value="1-2">1-2</option>
              <option value="3-4">3-4</option>
            </select>
            <span style={{ marginLeft: '15px', marginRight: '15px', color: '#324E6A' }}>entries </span>
          </div>
          <span style={{ marginLeft: 'auto', marginRight: '23px', color: '#324E6A' }}>Search: </span>
          <input type="text" style={{ backgroundColor: '#D9D9D9', border: 'none', outline: 'none', borderRadius: '5px', padding: '5px' }} />
        </div>
        <table style={tableStyle}>
          <thead>
            <tr>
              <th style={{ ...thStyle, ...columnStyles.caseNumber }}>Case Number</th>
              <th style={{ ...thStyle, ...columnStyles.patient }}>Patient Name</th>
              <th style={{ ...thStyle, ...columnStyles.doctor }}>Doctor Name</th>
              <th style={{ ...thStyle, ...columnStyles.dateTime }}>Date/Time</th>
            </tr>
          </thead>
          <tbody style={{ overflowX: 'auto' }}>
            {temporaryData.map((data, index) => (
              <tr key={index} style={{ height: rowHeight, backgroundColor: index % 2 === 0 ? '#D9D9D9' : '#E6E6E6' }}>
                <td style={{ ...tdStyle, ...columnStyles.caseNumber }}>{data.caseNumber}</td>
                <td style={{ ...tdStyle, ...columnStyles.patient }}>{data.patient}</td>
                <td style={{ ...tdStyle, ...columnStyles.doctor }}>{data.doctor}</td>
                <td style={{ ...tdStyle, ...columnStyles.dateTime }}>{data.dateTime}</td>
              </tr>
            ))}
            {[...Array(displayedRows - totalRows)].map((_, index) => (
              <tr key={index + totalRows} style={{ height: rowHeight, backgroundColor: index % 2 === 0 ? '#D9D9D9' : '#E6E6E6' }}>
                <td style={{ ...tdStyle, ...columnStyles.caseNumber }}></td>
                <td style={{ ...tdStyle, ...columnStyles.patient }}></td>
                <td style={{ ...tdStyle, ...columnStyles.doctor }}></td>
                <td style={{ ...tdStyle, ...columnStyles.dateTime }}></td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <Footer />
    </div>
  );
}
export default AppointmentHistory;
