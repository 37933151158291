/**
 * Footer Component
 * This component represents the footer of the webpage. It contains navigation links 
 * to sections like About Us, Contact, FAQ, and legal pages like Privacy Policy 
 * and Terms of Service. Additionally, it is styled to stick to the bottom of the 
 * page for both desktop and mobile views.
 */
import React from 'react';
function Footer() {
  const footerStyle = {
    backgroundColor: '#4c9fc5',
    color: '#fff',
    padding: '20px',
    width: '100%',
    position: 'relative',
    bottom: 0,
    zIndex: 3,
  };
  const navStyle = {
    listStyle: 'none',
    padding: 0,
    margin: 0,
  };
  const linkStyle = {
    color: '#fff',
    textDecoration: 'none',
  };
  return (
    <footer style={footerStyle}>
      <nav>
        <ul style={navStyle}>
          <li style={{ display: 'inline-block', marginRight: '20px' }}>
            <a href="/about" style={linkStyle}>About Us</a>
          </li>
          <li style={{ display: 'inline-block', marginRight: '20px' }}>
            <a href="/contact" style={linkStyle}>Contact</a>
          </li>
          <li style={{ display: 'inline-block' }}>
            <a href="/faq" style={linkStyle}>FAQ</a>
          </li>
        </ul>
      </nav>
      <div>
        <p>&copy; {new Date().getFullYear()} Your Company Name |
          <a href="/privacy-policy" style={linkStyle}>Privacy Policy</a> |
          <a href="/terms-of-service" style={linkStyle}>Terms of Service</a></p>
      </div>
      {/* Additional mobile styles */}
      <style>
        {`
          @media (max-width: 576px) {
            footer {
              position: fixed; /* Stick to the bottom on small screens */
              bottom: 0;
              width: 100%;
            }
          }
        `}
      </style>
    </footer>
  );
}
export default Footer;