//==================================videocon info=========================================
/**
 * Purpose:
 * This component fetches and displays the personal details of a patient, such as name, email, and case number, based on the patient ID passed via state or sessionStorage. 
 * It handles the fetching of patient details from an API and stores them in sessionStorage for later use. This information is then displayed in a structured format for the user.
 * 
 * Key Features:
 * - **Dynamic Patient Details Fetching**: The component fetches patient details based on a patient ID, either passed from the previous page's state or from sessionStorage.
 * - **Session Storage**: Uses sessionStorage to store and retrieve the patient ID and name across different components/pages for easy access.
 * - **Error Handling**: The component includes basic error handling for API fetch failures.
 * - **Flexible Styling**: The component uses inline styles for a clean, simple layout displaying patient details in separate fields.

 * Additional Notes:
 * - **Location Hook**: The component uses `useLocation` from `react-router-dom` to get the patient ID passed from another component via the router state.
 * - **API Request**: It makes an authenticated API request using Axios to fetch the patient details based on the ID and updates the UI.
 * - **Session Storage Usage**: The patient ID and other details are stored in sessionStorage to ensure the data persists across page reloads.
 * 
 * Future Enhancements:
 * - **User Feedback**: Add loading spinners or visual indicators while waiting for the API response.
 * - **Error Display**: Implement UI feedback for users when the patient details cannot be fetched.
 * - **Redirect Handling**: Add functionality to handle cases where the patient ID is missing or invalid (e.g., show a user-friendly error message).
 */

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import BASE_URL from '../../config/apiConfig';
import { useNavigate, useLocation } from 'react-router-dom'; // Import useLocation
function MainContent() {
    const [patientDetails, setPatientDetails] = useState({
        name: '',
        email: '',
        caseNo: ''
    });
    const location = useLocation(); // Get location from the hook
    useEffect(() => {
        const { patientId } = location.state || {};
        if (patientId) {
            sessionStorage.setItem('patientId', patientId); // Save patientId to sessionStorage
            fetchPatientDetails(patientId);
        } else {
            const storedPatientId = sessionStorage.getItem('patientId'); // Get from sessionStorage
            if (storedPatientId) {
                fetchPatientDetails(storedPatientId);
            } else {
                console.error('No patient ID available');
            }
        }
    }, [location]);
    const fetchPatientDetails = async (patientId) => {
        const jwtToken = sessionStorage.getItem('jwtToken');
        try {
            const response = await axios.get(`${BASE_URL}/registerDocHs/get-patient-details-personal?patientId=${patientId}`, {
                headers: {
                    'Auth': `Bearer ${jwtToken}`
                }
            });
            const data = response.data;
            // Save name to sessionStorage
            sessionStorage.setItem('Name', data.patientName);
            setPatientDetails({
                name: data.patientName,
                email: data.email,
                caseNo: data.caseNo
            });
        } catch (error) {
            console.error('Error fetching patient details:', error);
        }
    };
    // css 
    const detailFieldStyle = {
        flex: '1',
        marginRight: '20px',
        padding: '10px',
        color: '#434343',
        background: '#D9D9D9',
        borderRadius: '5px',
        whiteSpace: 'nowrap',
        textAlign: 'center',
        border: '1px solid #434343'
    };
    const mainContentStyle = {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '10px',
    };
    return (
        <div className="main-content">
            <div style={mainContentStyle}>
                <div className="patient-details" style={{ display: 'flex', alignItems: 'center', marginLeft: '20px' }}>
                    <div className="detail-field" style={detailFieldStyle}>
                        <span>Name: </span> <span>{patientDetails.name}</span>
                    </div>
                    <div className="detail-field" style={detailFieldStyle}>
                        <span>Email: </span> <span>{patientDetails.email}</span>
                    </div>
                    <div className="detail-field" style={detailFieldStyle}>
                        <span>Case No: </span> <span>{patientDetails.caseNo}</span>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default MainContent;
